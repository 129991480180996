import { loan } from './../../constants/loan';
import { SurveyStatus } from './../../constants/surveyStatus';
import { deleteUserDocumentsBySubmissionId, deleteUserResponseByResponseIndex, deleteUserResponseBySubmissionId, updateUserResponse, updateUserSubmission } from './../../graphql/mutations';
import { LoanMaster, Users } from './../../redux/stores/appStore';
import { QuestionTypes } from './../../constants/questionTypes';
import { UserResponse } from './../../redux/stores/questionStore';
import { API, graphqlOperation } from "aws-amplify";
import { createUserResponse, createUserSubmission, updateLoanStatusInVertica } from "../../graphql/mutations";
import { getUserResponse, getUserSubmission } from '../../graphql/queries';
import { getApi, postApi } from '../../api/base';

export const createUserResponseAsync = async (data: any) => {
    try {
        let column="user_submission_id='"+data.user_submission_id+"' and question_id='"+data.question_id+"' ";
        const existingUserResponse: any =await getApi("api/Hoa/Hoa/listUserResponsesBySubmissionId?column="+column) 
        // await API.graphql(graphqlOperation(getUserResponse, { user_submission_id: data.user_submission_id, question_id: data.question_id }));
        if (existingUserResponse && existingUserResponse.data && existingUserResponse.data[0]) {
            const answerText = data.answer;
            let input= { ...existingUserResponse.data[0], answer: answerText }
            let param={
                user_submission_id: data.user_submission_id,
                 response_index: data.response_index
                }
            await postApi("api/Hoa/Hoa/updateUserResponse",input, false, true);
            await postApi("api/Hoa/Hoa/deleteUserResponseByResponseIndex",param, false, true);
            // await API.graphql(graphqlOperation(updateUserResponse, { input: { ...existingUserResponse.data[0], answer: answerText } }));
            // await API.graphql(graphqlOperation(deleteUserResponseByResponseIndex, { input: { user_submission_id: data.user_submission_id, response_index: data.response_index } }));
            return true;
        }
        else {
            await postApi("api/Hoa/Hoa/createUserResponse",data, false, true);
            return 
            // await API.graphql(graphqlOperation(createUserResponse, { input: data }));
        }
    }
    catch (e: any) {
        console.log(e);
        return 0;
    }
}
export const SaveUserResponse = async (data: any) => {
    try {
        let response: any = undefined;
        const userResponses: UserResponse = data.userResponses.find(response => response.question_id === data.currentQuestionNumber);
        let answer: any = null;
        switch (userResponses && userResponses.questionType) {
            case QuestionTypes.SingleSelectCheckBox:
            case QuestionTypes.MultiSelectCheckBox:
                answer = { "checkBoxResponses": userResponses.checkBoxResponses };
                break;
            case QuestionTypes.Amount:
            case QuestionTypes.NumberInput:
                answer = { "numberResponse": userResponses.numberResponse };
                break;
            case QuestionTypes.TextInput:
                answer = { "textResponse": userResponses.textResponse };
                break;
            case QuestionTypes.DateInput:
                answer = { "dateResponse": userResponses.dateResponse };
                break;
            case QuestionTypes.Custom:
                answer = { "customResponse": userResponses.customResponse };
                break;
            case QuestionTypes.CustomHtml:
                answer = { "customInputResponse": userResponses.customInputResponse, "checkBoxResponses": userResponses.checkBoxResponses };
                break;
        }
        const loanData: LoanMaster = data.loanData;
        const userData: Users = data.userData;
        const userSubmission = {
            loan_number: loanData.loan_number,
            user_name: userData.user_name,
            servicer_id: loanData.servicer_id,
            is_primary_borrower: data.isCurrentUserPrimary
        };
        const userSubmissionId = await createUserSubmissionAsync(userSubmission);
            console.log("userSubmissionId: ",userSubmissionId)
        if (userSubmissionId) {
            const userResponse = {
                user_submission_id: userSubmissionId.toString(),
                answer: JSON.stringify(answer),
                question_id: userResponses.question_id.toString(),
                response_index: userResponses.responseIndex
            };
            response = await createUserResponseAsync(userResponse);
        }
        return response;
    }
    catch (e: any) {
        console.log(e);
        return 0;
    }
}

export const createUserSubmissionAsync = async (data: any) => {
    try {
        const existingUserSubmission: any = await getApi("api/Hoa/Hoa/getUserSubmission?user_name="+data.user_name+"&loan_number="+data.loan_number+"&servicer_id="+data.servicer_id)
        // await API.graphql(graphqlOperation(getUserSubmission, { user_name: data.user_name, loan_number: data.loan_number, servicer_id: data.servicer_id }));
        if (existingUserSubmission && existingUserSubmission.data && existingUserSubmission.data[0]) {
            return Number(existingUserSubmission.data[0].user_submission_id);
        }
        else {
            const createUserSubmissionResponse: any =  await postApi("api/Hoa/Hoa/createUserSubmission",data, false, true);

            // await API.graphql(graphqlOperation(createUserSubmission, { input: data }));
            try {
            const updateLoanStatus: any =  await postApi("api/Hoa/Hoa/KafkaDataExportToVertica?loanNumber="+data.loan_number, false, true);
                // await API.graphql(graphqlOperation(updateLoanStatusInVertica,
                //     {
                //         input: {
                //             loan_number: data.loan_number
                //         }
                //     }));
                console.log(updateLoanStatus)
            }
            catch (ex) {
                console.log(ex)
            }
            return Number(createUserSubmissionResponse.data[0].user_submission_id);
        }
    }
    catch (e: any) {
        console.log(e);
        return 0;
    }
}

export const setUserSurveyStatusAsync = async (loanDetails: any, userSurveyStatus: string) => {
    try {
        const existingUserSubmission: any = await getApi("api/Hoa/Hoa/getUserSubmission?user_name="+loanDetails.userId+"&loan_number="+loanDetails.loanId+"&servicer_id="+loanDetails.servicerId)
        // await API.graphql(graphqlOperation(getUserSubmission, { user_name: loanDetails.userId, loan_number: loanDetails.loanId, servicer_id: loanDetails.servicerId }));
        if (existingUserSubmission && existingUserSubmission.data && existingUserSubmission.data[0]) {
            let updatedUserSubmission = {
                user_submission_id: existingUserSubmission.data[0].user_submission_id,
                modified_date: new Date().toUTCString(),
                modified_by: loanDetails.userId,
                submission_status: userSurveyStatus
            };
            const updateUserSubmissionResponse: any = await postApi("api/Hoa/Hoa/updateUserSubmission",updatedUserSubmission, false, true);
            // await API.graphql(graphqlOperation(updateUserSubmission, { input: updatedUserSubmission }));
            let submissionResponse: any;
            if (updateUserSubmissionResponse && updateUserSubmissionResponse.data ) {
                submissionResponse = updateUserSubmissionResponse.data;
            }
            try {
                const updateLoanStatus: any =await postApi("api/Hoa/Hoa/KafkaDataExportToVertica?loanNumber="+loanDetails.loanId, false, true);
                //  await API.graphql(graphqlOperation(updateLoanStatusInVertica,
                //     {
                //         input: {
                //             loan_number: loanDetails.loanId
                //         }
                //     }));
                console.log(updateLoanStatus)
            }
            catch (ex) {
                console.log(ex)
            }
            return submissionResponse;
        }
        else {
            const userSubmission = {
                loan_number: loanDetails.loanId,
                user_name: loanDetails.userId,
                servicer_id: Number(loanDetails.servicerId),
                submission_status: userSurveyStatus,
                is_primary_borrower: loanDetails.is_primary_borrower,
                created_date: new Date().toUTCString(),
                created_by: loanDetails.userId,
                modified_by: loanDetails.userId,
                modified_date: new Date().toUTCString()               
            }
            let createSubmissionResponse: any;
            const createUserSubmissionResponse: any = await postApi("api/Hoa/Hoa/createUserSubmission",userSubmission, false, true);
            // await API.graphql(graphqlOperation(createUserSubmission, { input: userSubmission }));
            if (createUserSubmissionResponse && createUserSubmissionResponse.data ) {
                createSubmissionResponse = createUserSubmissionResponse.data[0];
            }
            try {
                const updateLoanStatus: any = await postApi("api/Hoa/Hoa/KafkaDataExportToVertica?loanNumber="+userSubmission.loan_number, false, true);
                // await API.graphql(graphqlOperation(updateLoanStatusInVertica,
                //     {
                //         input: {
                //             loan_number: userSubmission.loan_number
                //         }
                //     }));
                console.log(updateLoanStatus)
            }
            catch (ex) {
                console.log(ex)
            }

            return createSubmissionResponse;
            return createUserSubmissionResponse;
        }
    }
    catch (e: any) {
        console.log(e);
        return 0;
    }
}

export const clearUserResponseAsync = async (data: any) => {
    try {
        const userSubmission: any = await getApi("api/Hoa/Hoa/getUserSubmission?user_name="+data.user_id+"&loan_number="+data.loan_number+"&servicer_id="+data.servicer_id)
        if (userSubmission && userSubmission.data && userSubmission.data[0]) {
            const userSubmissionId = userSubmission.data[0].user_submission_id;
            await postApi("api/Hoa/Hoa/deleteUserResponseBySubmissionId?user_submission_id="+userSubmissionId, false, true);
            await postApi("api/Hoa/Hoa/deleteUserDocumentsBySubmissionId?user_submission_id="+userSubmissionId, false, true);
            // API.graphql(graphqlOperation(deleteUserResponseBySubmissionId, { input: { user_submission_id: userSubmissionId } }));
            // API.graphql(graphqlOperation(deleteUserDocumentsBySubmissionId, { input: { user_submission_id: userSubmissionId } }));
            return { message: "delete successfull" };
        }
    }
    catch (e: any) {
        console.log(e);
        return 0;
    }
}

export const getUserSubmissionAsync = async (data: any) => {
    try{
        const existingUserSubmission: any = await getApi("api/Hoa/Hoa/getUserSubmission?user_name="+data.userId+"&loan_number="+data.loanId+"&servicer_id="+data.servicerId )
        // await API.graphql(graphqlOperation(getUserSubmission, { user_name: data.userId, loan_number: data.loanId, servicer_id: data.servicerId }));
        if(existingUserSubmission && existingUserSubmission.data && existingUserSubmission.data[0]){
            return existingUserSubmission.data[0];
        }
    }
    catch (e: any) {
        console.log(e);
        return 0;
    }
}