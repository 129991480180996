import { API, graphqlOperation } from "aws-amplify";
import { getFinicityConnectUrl, getFinicityAssetsReport, getFinicityIncomeReport, getFinicityTransactionReport, getFinicityEmploymentReport } from '../graphql/queries';
import { postApi } from "../api/base";

export const getConnectUrl = async (data: any) => {
    try {
        const response: any = await postApi("api/Hoa/Hoa/FiniCityApiConnect?loanId="+data.loan_id.toString()+"&servicerId="+Number(data.servicer_id)+"&userId="+data.username, false, true)
        // await API.graphql(graphqlOperation(getFinicityConnectUrl, { loanId: data.loan_id.toString(), servicerId: Number(data.servicer_id), userId: data.username }));
        console.log("getFinicityConnectUrl:",response)
        let parsedResponse = JSON.parse(response.data);
        return JSON.parse(parsedResponse.body);
    }
    catch (err) {
        console.log(err);
    }
}

export const getIncome = async (params: any) => {
    try {
        const response: any = await postApi("api/Hoa/Hoa/FiniCityApiIncomeReport?loanNumber="+params.loanNumber+"&customerId="+params.customerId+"&userId="+params.userId+"&loanType="+params.loanType+"&servicerId="+params.servicerId, false, true)
        console.log("getFinicityIncomeReport:",response)
        // await API.graphql(graphqlOperation(getFinicityIncomeReport, params));
        let parsedResponse = JSON.parse(response.data);
        return parsedResponse.body;
    }
    catch (err) {
        console.log(err);
    }
}

export const getAssets = async (params: any) => {
    try {
        const response: any = await postApi("api/Hoa/Hoa/FiniCityApiAssetsReport?loanNumber="+params.loanNumber+"&customerId="+params.customerId+"&userId="+params.userId+"&loanType="+params.loanType+"&servicerId="+params.servicerId, false, true)
        // await API.graphql(graphqlOperation(getFinicityAssetsReport, params));
        console.log("getFinicityAssetsReport:",response)
        let parsedResponse = JSON.parse(response.data);
        return parsedResponse.body;
    }
    catch (err) {
        console.log(err);
    }
}

export const getTransactions = async (params: any) => {
    try {
        const response: any =await postApi("api/Hoa/Hoa/FiniCityApiTransactionReport?loanNumber="+params.loanNumber+"&customerId="+params.customerId+"&userId="+params.userId+"&loanType="+params.loanType+"&servicerId="+params.servicerId, false, true)
        //  await API.graphql(graphqlOperation(getFinicityTransactionReport, params));
        console.log("getFinicityTransactionReport:",response)
        let parsedResponse = JSON.parse(response.data);
        return parsedResponse.body;
    }
    catch (err) {
        console.log(err);
    }
}

export const getVoeTransactions = async (params: any) => {
    try {
        const response: any = await postApi("api/Hoa/Hoa/FiniCityApiVOETransactionReport?loanNumber="+params.loanNumber+"&customerId="+params.customerId+"&userId="+params.userId+"&loanType="+params.loanType+"&servicerId="+params.servicerId, false, true)
        // await API.graphql(graphqlOperation(getFinicityEmploymentReport, params));
        let parsedResponse = JSON.parse(response.data);
        return parsedResponse.body;
    }
    catch (err) {
        console.log(err);
    }
}