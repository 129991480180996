function isUpperCase(str) {
    return str === str.toUpperCase() && isNaN(str);
}

const  snakecase = (key) =>{
    // const regex = "[A-Z]";
    const arr = key.split('')
    const newArr = arr.map((element,index) => {
        if(isUpperCase(element)){
            return arr[index] = `_${element.toLowerCase()}`
        }else{
            return element
        }
    });
    return newArr.join('')
}

const  toCamelCaseFromSnakeCase = (key) =>{
    // const regex = "[A-Z]";
    const arr = key.split('_')
    let result = arr[0] || '';
    arr.forEach((element,index) => {
        if(index > 0){
            result += `${element[0].toUpperCase()}${element?.slice(1)}`
        }
    });
    return result
}

export const replaceAllObjKeys = (obj1,toCamelCase = false) => {
    const obj = obj1;
    if (Array.isArray(obj)) {
        for (let i = 0; i < obj.length; i++) {
            replaceAllObjKeys(obj[i],toCamelCase);
        }
    }
    else if (typeof obj === "object") {
        for (const key in obj) {
            const newKey = toCamelCase ? toCamelCaseFromSnakeCase(key) : snakecase(key);
            obj[newKey] = obj[key];
            if (key !== newKey) {
                delete obj[key];
            }
            replaceAllObjKeys(obj[newKey],toCamelCase);
        }
    }

    return obj;
};

export const parseApiResponse = (resObj) => {
    resObj.data = replaceAllObjKeys(resObj?.data)
    return resObj
}

export const parseApiRequest = (reqObj) => {
    reqObj = replaceAllObjKeys(reqObj, true)
    return reqObj
}

export const generateQueryString = (obj) => {
    var str: any = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p) && (obj[p] || typeof obj[p] === 'boolean')) {
        str.push(p + "=" + obj[p]);
      }
    return str.join("&");
  }