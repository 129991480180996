import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { store } from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import './scss/question.scss';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import Amplify from 'aws-amplify';
import axios from "axios";
axios.interceptors.request.use(
  config => {
    let updatedConfig = { ...config }
    if (updatedConfig.url?.toLowerCase().includes('hoa')) {
      updatedConfig = {
        ...updatedConfig,
        url: (process.env.REACT_APP_API_URL || "") + (config.url?.indexOf('livegage.ai/loadbalancer') === -1 ? config.url : config?.url?.substring(config.url?.indexOf("/api/") + 1))
      };
    }
   
    return updatedConfig
  },
  err => {
    throw new Error(err.message);
  }
)
axios.interceptors.response.use(
  response => {
    // console.log("axios response", response);
    return response
  },
  err => {
    console.log("error: ", err);
    
    throw new Error(err.message);


  }
)

let persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
