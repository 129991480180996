import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FinicityState } from "../stores/appStore";
import { getConnectUrl, getIncome, getAssets, getTransactions, getVoeTransactions } from "../../services/finicityService";
import { Status } from '../../constants/status';
import { getManualCategories } from "../../services/categoryService";

const initialState: FinicityState = {
  finicityData: null,
  error: undefined,
  status: Status.Idle
};

export const getConnectUrlAsync = createAsyncThunk(
  'finicity/getConnectUrl',
  async (data: any) => {
    try {
      const response = await getConnectUrl(data);
      return response as any;
    }
    catch (error: any) {
      throw error;
    }
  }
);

export const getIncomeAsync = createAsyncThunk(
  'finicity/getAccessToken',
  async (params: any) => {
    const response = await getIncome(params);
    return response as any;
  }
);

export const getAssetsAsync = createAsyncThunk(
  'finicity/getAssets',
  async (params: any) => {
    const response = await getAssets(params);
    return response as any;
  }
);

export const getTransactionsAsync = createAsyncThunk(
  'finicity/getTransactions',
  async (params: any) => {
    const response = await getTransactions(params);
    return response as any;
  }
);

export const getVoeTransactionsAsync = createAsyncThunk(
  'finicity/getVoeTransactions',
  async (params: any) => {
    const response = await getVoeTransactions(params);
    return response as any;
  }
);

export const getManualCategoriesAsync = createAsyncThunk(
  'finicity/getManualCategories',
  async (params: any) => {
    const response = await getManualCategories(params);
    return response as any;
  }
);


export const finicityIntegrationSlice = createSlice({
  name: 'finicity',
  initialState,
  reducers: {
  },

  extraReducers: (builder) => {
    builder
      .addCase(getConnectUrlAsync.pending, (state) => {
        state.status = Status.Loading;
      })
      .addCase(getConnectUrlAsync.fulfilled, (state, action: PayloadAction<string>) => {
        state.status = Status.Idle;
      })
      .addCase(getConnectUrlAsync.rejected, (state, action) => {
        state.status = Status.Idle;
        state.error = action.error.message;
      })
      .addCase(getIncomeAsync.pending, (state) => {
        state.status = Status.Loading;
      })
      .addCase(getIncomeAsync.fulfilled, (state, action: PayloadAction<string>) => {
        state.status = Status.Idle;
      })
      .addCase(getIncomeAsync.rejected, (state, action) => {
        state.status = Status.Idle;
        state.error = action.error.message;
      })
      .addCase(getAssetsAsync.pending, (state) => {
        state.status = Status.Loading;
      })
      .addCase(getAssetsAsync.fulfilled, (state, action: PayloadAction<string>) => {
        state.status = Status.Idle;
      })
      .addCase(getAssetsAsync.rejected, (state, action) => {
        state.status = Status.Idle;
        state.error = action.error.message;
      })
      .addCase(getTransactionsAsync.pending, (state) => {
        state.status = Status.Loading;
      })
      .addCase(getTransactionsAsync.fulfilled, (state, action: PayloadAction<string>) => {
        state.status = Status.Idle;
      })
      .addCase(getTransactionsAsync.rejected, (state, action) => {
        state.status = Status.Idle;
        state.error = action.error.message;
      })
      .addCase(getVoeTransactionsAsync.pending, (state) => {
        state.status = Status.Loading;
      })
      .addCase(getVoeTransactionsAsync.fulfilled, (state, action: PayloadAction<string>) => {
        state.status = Status.Idle;
      })
      .addCase(getVoeTransactionsAsync.rejected, (state, action) => {
        state.status = Status.Idle;
        state.error = action.error.message;
      })
      .addCase(getManualCategoriesAsync.pending, (state) => {
        state.status = Status.Loading;
      })
      .addCase(getManualCategoriesAsync.fulfilled, (state, action: PayloadAction<any>) => {
        console.log("action: ",action.payload)
        state.status = Status.Idle;
      })
      .addCase(getManualCategoriesAsync.rejected, (state, action) => {
        state.status = Status.Idle;
        state.error = action.error.message;
      })
  },
});

export default finicityIntegrationSlice.reducer;