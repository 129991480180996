import { clearUserResponseAsync, SaveUserResponse } from './../../services/questionServicers/userResponseService';
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
  getIntents
} from "../../services/questionService";
import {
  deleteFile,
  getFilesList,
  readFile,
} from "../../services/azureFileShareService";
import { getPaymentToken } from "../../services/userService";
import { Status } from "../../constants/status";
import {
  IFinancialData,
  Option,
  Question,
  QuestionApiResponse,
  QuestionState,
  UploadedDocument,
  UserResponse,
} from "../stores/questionStore";
import { QuestionTypes } from "../../constants/questionTypes";
import {
  FinancialSections,
  FinancialTabs,
} from "../../constants/financialConstants";
import { SurveyStatus } from "../../constants/surveyStatus";
import { Storage } from "aws-amplify";
import { getUploadedFiles, submitFilesUploaded } from '../../services/fileUploadServicer';
import { getSuggestions } from '../../services/suggestionService';
import { updateSurveyStatus } from '../../services/loanReviewService';

const initialState: QuestionState = {
  questionList: undefined,
  currentQuestion: undefined,
  userResponses: [],
  status: Status.Idle,
  previousQuestionId: undefined,
  previousQuestionResponse: "",
  suggestions: undefined,
  errorMessage: "",
  initialQuestionId: undefined,
  documentsUploaded: [],
  selectedOptions: undefined,
  finalDisclosure: undefined,
  lastModifiedDate: undefined,
  activeFinancialTab: FinancialTabs.Income,
  isClickable: false,
  isRequired: false,
  showContinueButton: false,
  isWorkoutExit: false,
  userSurveyStatus: SurveyStatus.NotStarted,
  loanStatus: SurveyStatus.NotStarted,
  primaryBorrowerStatus: SurveyStatus.NotStarted,
  count: 0,
  responseIndex: 0,
  borrowersCount: 0,
  isSurveyCompleted: false,
  allBorrowerSurveyStatus: [],
  isCurrentUserPrimary: false
};

export const getPaymentTokenAsync = createAsyncThunk(
  "questions/paymentToken",
  async (param: any) => {
    let response = await getPaymentToken(param);
    return response
  }
);

export const getIntentsAsync = createAsyncThunk(
  'questions/getIntents',
  async (params: any) => {
    let response = await getIntents(params);
    return response as QuestionApiResponse;
  }
);
export const updateSurveyStatusAsync = createAsyncThunk(
  "questions/updateSurveyStatus",
  async (data: any) => {
    let response = await updateSurveyStatus(data);
    return response;
  }
);

export const submitResponseAsync = createAsyncThunk(
  "questions/submitResponse",
  async (data: any) => {
    const response = await SaveUserResponse(data)
    return { response: response };
  }
);
export const getSuggestionsAsync = createAsyncThunk(
  "questions/getSuggestions",
  async (data: any) => {
    let response = await getSuggestions(data);
    return response;
  }
);

export const getFileAsync = createAsyncThunk(
  "questions/getFile",
  async (data: any) => {
    let response = await readFile(data.fileName, data.loanId, "customer1");
    if (response && response.blob) {
      window.open(
        URL.createObjectURL(
          new File([response.blob], data.fileName, {
            lastModified: Date.now(),
            type: response.type,
          })
        )
      );
    }
    return response;
  }
);

export const getFileListAsync = createAsyncThunk(
  "questions/getFileList",
  async () => {
    let response = await getFilesList("Hoa_Poc", "fshoatesting");
    return response;
  }
);

export const uploadDocumentsAsync = createAsyncThunk(
  "questions/uploadDocuments",
  async (data: any) => {
    let fileUploadResponse: any[] = [];
    if (data.files.length) {
      for (const file of data.files) {
        let fileCopy = { ...file };
        const fileBlob = await fetch(file.accessUrl).then((x) => x.blob());
        const result = await Storage.put(`${data.loanId}/${file.fileName}`, fileBlob, { contentType: file.fileType });
        const url = await Storage.get(result.key);
        if (url) {
          fileCopy.accessUrl = url;
          fileCopy.key = result.key;
          fileUploadResponse.push(fileCopy);
        }
      }
    }
    return { ...data, files: fileUploadResponse };
  }
);

export const deleteDocumentsAsync = createAsyncThunk(
  "questions/deleteDocuments",
  async (data: any) => {
    const response = await Storage.remove(data.key, { level: 'public' });
    return response;
  }
);

export const uploadFilesToDbAsync = createAsyncThunk(
  "question/uploadToDb",
  async (data: any) => {
    let response = await submitFilesUploaded(data);
    return response;
  }
);

export const getUploadedFilesAsync = createAsyncThunk(
  "question/readUploadedFiles",
  async (data: any) => {
    let response = await getUploadedFiles(data);
    return response;
  }
);

export const clearUserResponse = createAsyncThunk(
  "question/clearUserResponse",
  async (data: any) => {
    clearUserResponseAsync(data);
  }
);

const getSelectedOption = (
  currentQuestion: any,
  userResponses: UserResponse[]
) => {
  if (currentQuestion && userResponses.length > 0) {
    const currentQuestionResponse = userResponses.find(
      (x) => x.question_id === currentQuestion.question_number
    );
    switch (currentQuestion.question_type) {
      case QuestionTypes.MultiSelectCheckBox:
      case QuestionTypes.SingleSelectCheckBox:
        if (
          currentQuestionResponse &&
          currentQuestionResponse.checkBoxResponses
        ) {
          let selectedOptions = currentQuestion.options.filter((x) => {
            const response = currentQuestionResponse.checkBoxResponses!.find(
              (y) => y.optionId === x.optionId
            );
            return response ? true : false;
          });
          let modifiedOptions: Option[] = [...selectedOptions];
          if (modifiedOptions.length > 0) {
            modifiedOptions[0] = {
              ...modifiedOptions[0],
              fileList:
                currentQuestionResponse["checkBoxResponses"][0]["fileList"],
            };
          }
          return modifiedOptions;
        } else {
          return undefined;
        }
      case QuestionTypes.NumberInput:
        return currentQuestionResponse
          ? currentQuestionResponse.numberResponse
          : undefined;
      case QuestionTypes.DateInput:
        return currentQuestionResponse
          ? currentQuestionResponse.dateResponse
          : undefined;
      case QuestionTypes.TextInput:
        return currentQuestionResponse
          ? currentQuestionResponse.textResponse
          : undefined;
      case QuestionTypes.Custom:
        return currentQuestionResponse
          ? currentQuestionResponse.customResponse
          : undefined;
      case QuestionTypes.CustomHtml:
        if (
          currentQuestionResponse &&
          currentQuestionResponse.checkBoxResponses
        ) {
          let selectedOptions = currentQuestion.options.filter((x) => {
            const response = currentQuestionResponse.checkBoxResponses!.find(
              (y) => y.optionId === x.optionId
            );
            return response ? true : false;
          });
          let modifiedOptions: Option[] = [...selectedOptions];
          if (modifiedOptions.length > 0) {
            modifiedOptions[0] = {
              ...modifiedOptions[0],
              fileList:
                currentQuestionResponse["checkBoxResponses"][0]["fileList"],
            };
          }
          return {
            selectedValues: modifiedOptions,
            customInputResponses:
              currentQuestionResponse.customInputResponse || [],
          };
        } else {
          return undefined;
        }
    }
  } else {
    return undefined;
  }
};

export const questionSlice = createSlice({
  name: "question",
  initialState,
  reducers: {
    setContinueButtonStatus: (
      state: QuestionState,
      action: PayloadAction<any>
    ) => {
      state.showContinueButton = action.payload;
    },
    setIsWorkoutExit: (state: QuestionState, action: PayloadAction<any>) => {
      state.isWorkoutExit = action.payload;
    },
    clearState: (state: QuestionState) => {
      state.userSurveyStatus = SurveyStatus.NotStarted;
      state.loanStatus = SurveyStatus.NotStarted;
      state.questionList = undefined;
      state.userResponses = [];
      state.suggestions = undefined;
      state.isWorkoutExit = false;
      state.isRequired = false;
      state.selectedOptions = undefined;
      state.finalDisclosure = undefined;
      state.status = Status.Idle;
      state.showContinueButton = false;
      state.activeFinancialTab = FinancialTabs.Income;
      state.documentsUploaded = [];
      state.initialQuestionId = undefined;
      state.previousQuestionResponse = "";
      state.previousQuestionId = undefined;
      state.primaryBorrowerStatus = SurveyStatus.NotStarted;
      state.count = 0;
      state.responseIndex = 0;
      state.isSurveyCompleted = false;
      state.allBorrowerSurveyStatus = [];
    },
    setDisclosure: (state: QuestionState, action: PayloadAction<any>) => {
      state.finalDisclosure = action.payload;
    },
    updateLoadingStatus: (state: QuestionState, action: PayloadAction<any>) => {
      state.status = action.payload;
    },
    resetUserResponses: (state: QuestionState) => {
      if (state.questionList) {
        state.userResponses = [];
        state.currentQuestion = state.questionList.find(
          (x) => x.question_number === state.initialQuestionId
        );
        state.previousQuestionResponse = "";
        state.previousQuestionId = undefined;
        state.selectedOptions = undefined;
        state.suggestions = undefined;
        state.finalDisclosure = undefined;
        state.activeFinancialTab = FinancialTabs.Income;
        state.showContinueButton = false;
        state.isWorkoutExit = false;

        state.selectedOptions = undefined;
        state.loanStatus = SurveyStatus.NotStarted;
        state.userSurveyStatus = SurveyStatus.NotStarted;
        state.count = 0;
        state.responseIndex = 0;
        state.isSurveyCompleted = false;
        state.allBorrowerSurveyStatus = [];
      }
    },
    changeActiveFinancialTab: (
      state: QuestionState,
      action: PayloadAction<any>
    ) => {
      state.activeFinancialTab = action.payload;
    },
    handleSelectedOptions: (
      state: QuestionState,
      action: PayloadAction<any>
    ) => {
      state.selectedOptions = action.payload;
    },
    handleCount: (state: QuestionState, action: PayloadAction<any>) => {
      state.count = action.payload;
    },

    addDocument: (state: QuestionState, action: PayloadAction<any>) => {
      if (action.payload) {
        state.documentsUploaded = action.payload;
      }
    },
    handleDocumentUpload: (
      state: QuestionState,
      action: PayloadAction<any>
    ) => {
      if (action.payload) {
        let userResponses = [...state.userResponses];

        let checkBoxResponse = userResponses.find(
          (x) => x.question_id === action.payload.question_id
        )!.checkBoxResponses;
        if (checkBoxResponse) {
          checkBoxResponse.find((x) => {
            if (x.optionId === action.payload.option.optionId) {
              x["fileUploaded"] = action.payload.uploadedFiles;
            }
          })!;
        }

        let customResponse = userResponses.find(
          (x) => x.question_id === action.payload.question_id
        )!.customResponse;
        if (customResponse) {
          customResponse[FinancialSections.Income].find((x: IFinancialData) => {
            if (x.display_value === action.payload.option.option) {
              x["fileUploaded"] = action.payload.uploadedFiles;
            }
          });
        }

        const uploadedFiles = [...state.documentsUploaded];
        if (action.payload.uploadedFiles) {
          action.payload.uploadedFiles.forEach((x) => uploadedFiles.push(x));
        }
        state.documentsUploaded = uploadedFiles;
      }
    },
    setCurrentQuestion: (state: QuestionState, action: PayloadAction<any>) => {
      state.currentQuestion = action.payload;
      if (
        state.currentQuestion &&
        state.currentQuestion.question_type === QuestionTypes.Custom
      ) {
        let financialTabsVisibility: any = {};
        financialTabsVisibility.income = state.currentQuestion.options.filter(
          (o) => o.optionText === FinancialTabs.Income
        )[0].visibility;
        financialTabsVisibility.assets = state.currentQuestion.options.filter(
          (o) => o.optionText === FinancialTabs.Assets
        )[0].visibility;
        financialTabsVisibility.expenses = state.currentQuestion.options.filter(
          (o) => o.optionText === FinancialTabs.Expenses
        )[0].visibility;
        financialTabsVisibility.summary = state.currentQuestion.options.filter(
          (o) => o.optionText === FinancialTabs.Summary
        )[0].visibility;
        let firstTab = financialTabsVisibility.income
          ? FinancialTabs.Income
          : financialTabsVisibility.assets
            ? FinancialTabs.Assets
            : financialTabsVisibility.expenses
              ? FinancialTabs.Expenses
              : FinancialTabs.Summary;
        let currentTabVisibile = state.currentQuestion.options.filter(
          (o) => o.optionText === state.activeFinancialTab
        )[0].visibility;
        state.activeFinancialTab =
          state.activeFinancialTab === FinancialTabs.Income
            ? firstTab
            : currentTabVisibile
              ? state.activeFinancialTab
              : firstTab;
      }
    },
    // verifyDependency: (state: QuestionState) => {
    //   if (
    //     state.currentQuestion?.dependency?.key === "SUGGESTIONS" &&
    //     state.suggestions?.suggestions
    //   ) {
    //     const currentQuestion = state!.questionList!.find(
    //       (x) => x.question_number === state.currentQuestion!.next_question_id
    //     );
    //     state.currentQuestion = currentQuestion;
    //   }
    // },
    updateUserResponse: (state: QuestionState, action: PayloadAction<any>) => {
      if (state.questionList) {
        let response: any;
        let userResponse: UserResponse;
        let updatedUserResponses = [...state.userResponses];
        const questionType = action.payload.question.questionType;
        let previousResponseIndex = updatedUserResponses.findIndex(
          (x) => String(x.question_id) === String(action.payload.question.question_id)
        );

        switch (questionType) {
          case QuestionTypes.SingleSelectCheckBox:
          case QuestionTypes.MultiSelectCheckBox:
            response = action.payload.option.map((x: any) => {
              return {
                optionId: x.optionId,
                option: x.optionText,
                optionValue: x.optionValue,
                fileUpload: x.fileUpload,
                fileList: x.fileList,
                dmnId: x.dmnId,
                supportedDocs: x.supportedDocs,
                isManual: x.isManual,
                nextQuestionId: x.nextQuestionId
              };
            });
            userResponse = {
              question_id: action.payload.question.questionId,
              question: action.payload.question.question,
              questionType: action.payload.question.questionType,
              checkBoxResponses: response,
              dmnId: action.payload.question.dmnId,
              dependency: action.payload.question.dependency,
              isManual: action.payload.question.isManual,
              responseIndex: state.responseIndex + 1
            };
            if (previousResponseIndex !== -1) {
              updatedUserResponses[previousResponseIndex].checkBoxResponses =
                response;
            } else {
              updatedUserResponses.push(userResponse);
              state.responseIndex = state.responseIndex + 1;
            }
            break;
          case QuestionTypes.TextInput:
            response = action.payload.option;
            userResponse = {
              question_id: action.payload.question.questionId,
              question: action.payload.question.question,
              questionType: action.payload.question.questionType,
              textResponse: response,
              dmnId: action.payload.question.dmnId,
              dependency: action.payload.question.dependency,
              isManual: action.payload.question.isManual,
              responseIndex: state.responseIndex + 1
            };
            if (previousResponseIndex !== -1) {
              updatedUserResponses[previousResponseIndex].textResponse =
                response;
            } else {
              updatedUserResponses.push(userResponse);
              state.responseIndex = state.responseIndex + 1;
            }
            break;
          case QuestionTypes.NumberInput:
            response = action.payload.option;
            userResponse = {
              question_id: action.payload.question.questionId,
              question: action.payload.question.question,
              questionType: action.payload.question.questionType,
              numberResponse: response,
              dmnId: action.payload.question.dmnId,
              dependency: action.payload.question.dependency,
              isManual: action.payload.question.isManual,
              responseIndex: state.responseIndex + 1
            };
            if (previousResponseIndex !== -1) {
              updatedUserResponses[previousResponseIndex].numberResponse =
                response;
            } else {
              updatedUserResponses.push(userResponse);
              state.responseIndex = state.responseIndex + 1;
            }
            break;
          case QuestionTypes.DateInput:
            response = action.payload.option;
            userResponse = {
              question_id: action.payload.question.questionId,
              question: action.payload.question.question,
              questionType: action.payload.question.questionType,
              dateResponse: response,
              dmnId: action.payload.question.dmnId,
              dependency: action.payload.question.dependency,
              isManual: action.payload.question.isManual,
              responseIndex: state.responseIndex + 1
            };
            if (previousResponseIndex !== -1) {
              updatedUserResponses[previousResponseIndex].dateResponse =
                response;
            } else {
              updatedUserResponses.push(userResponse);
              state.responseIndex = state.responseIndex + 1;
            }
            break;
          case QuestionTypes.Custom:
            response = action.payload.option;
            userResponse = {
              question_id: action.payload.question.questionId,
              question: action.payload.question.question,
              questionType: action.payload.question.questionType,
              customResponse: response,
              dmnId: action.payload.question.dmnId,
              dependency: action.payload.question.dependency,
              isManual: action.payload.question.isManual,
              responseIndex: state.responseIndex + 1
            };
            if (previousResponseIndex !== -1) {
              updatedUserResponses[previousResponseIndex].customResponse =
                response;
            } else {
              updatedUserResponses.push(userResponse);
              state.responseIndex = state.responseIndex + 1;
            }
            break;
        }
        state.userResponses = updatedUserResponses;
      }
    },
    addUserResponse: (state: QuestionState, action: PayloadAction<any>) => {
      if (state.questionList) {
        const questionType = action.payload.question.questionType;
        let userResponse: UserResponse;
        let currentQuestion: Question | undefined;
        let response: any;
        let updatedUserResponses = [...state.userResponses];
        let previousResponseIndex = updatedUserResponses.findIndex(
          (x) => String(x.question_id) === String(action.payload.question.questionId)
        );
        let isDifferent = false;
        let selectedOption = action.payload.option
          ? action.payload.option[0]
          : null;
        let responseIndex = state.responseIndex + 1;
        if ((questionType === QuestionTypes.SingleSelectCheckBox || questionType === QuestionTypes.MultiSelectCheckBox) && (selectedOption !== null || state.currentQuestion!.is_required)) {
          currentQuestion = state.questionList.find(
            (x) => x.question_number === action.payload.option[0].nextQuestionId
          );
          response = action.payload.option.map((x: any) => {
            return {
              optionId: x.optionId,
              option: x.optionText,
              optionValue: x.optionValue,
              fileUpload: x.fileUpload,
              fileList: x.fileList,
              dmnId: x.dmnId,
              supportedDocs: x.supportedDocs,
              isManual: x.isManual,
              nextQuestionId: x.nextQuestionId
            };
          });
          userResponse = {
            question_id: action.payload.question.questionId,
            question: action.payload.question.question,
            questionType: action.payload.question.questionType,
            checkBoxResponses: response,
            dmnId: action.payload.question.dmnId,
            dependency: action.payload.question.dependency,
            isManual: action.payload.question.isManual,
            responseIndex: responseIndex
          };

          if (previousResponseIndex !== -1) {

            if (
              !updatedUserResponses[previousResponseIndex].checkBoxResponses
            ) {
              isDifferent = true;
            } else {
              const previousCheckBoxResponses =
                updatedUserResponses[previousResponseIndex].checkBoxResponses!;
              if (previousCheckBoxResponses.length !== response.length) {
                isDifferent = true;
              } else {
                for (var previousResponse of previousCheckBoxResponses) {
                  let alreadyExists = false;
                  for (var checkBoxResponse of response) {
                    if (
                      checkBoxResponse.optionId === previousResponse.optionId
                    ) {
                      alreadyExists = true;
                    }
                  }
                  if (!alreadyExists) {
                    isDifferent = true;
                    break;
                  }
                }
              }
            }
            if (isDifferent) {
              updatedUserResponses[previousResponseIndex].checkBoxResponses =
                response;
              updatedUserResponses = [...updatedUserResponses].filter(
                (x, i) => i <= previousResponseIndex
              );
            }
          }
          else {
            responseIndex = state.responseIndex + 1;
            state.responseIndex = responseIndex;
          }
        } else if (questionType === QuestionTypes.CustomHtml) {
          currentQuestion = state.questionList.find(
            (x) => x.question_number === action.payload.option[0].nextQuestionId
          );
          response = action.payload.option.map((x: any) => {
            return {
              optionId: x.optionId,
              option: x.optionText,
              optionValue: x.optionValue,
              fileUpload: x.fileUpload,
              fileList: x.fileList,
              dmnId: x.dmnId,
              supportedDocs: x.supportedDocs,
              isManual: x.isManual,
              nextQuestionId: x.nextQuestionId
            };
          });

          const customInputResponses = action.payload.customInputResponse;

          userResponse = {
            question_id: action.payload.question.questionId,
            question: action.payload.question.question,
            questionType: action.payload.question.questionType,
            checkBoxResponses: response,
            customInputResponse: customInputResponses,
            dmnId: action.payload.question.dmnId,
            dependency: action.payload.question.dependency,
            isManual: action.payload.question.isManual,
            responseIndex: responseIndex
          };
          if (previousResponseIndex !== -1) {
            updatedUserResponses[previousResponseIndex].customInputResponse =
              customInputResponses;
            if (
              !updatedUserResponses[previousResponseIndex].checkBoxResponses
            ) {
              isDifferent = true;
            } else {
              const previousCheckBoxResponses =
                updatedUserResponses[previousResponseIndex].checkBoxResponses!;
              if (previousCheckBoxResponses.length !== response.length) {
                isDifferent = true;
              }
              else {
                for (let previousResponse of previousCheckBoxResponses) {
                  let alreadyExists = false;
                  for (let checkBoxResponse of response) {
                    if (checkBoxResponse.optionId === previousResponse.optionId) {
                      alreadyExists = true;
                    }
                  }
                  if (!alreadyExists) {
                    isDifferent = true;
                    break;
                  }
                }
              }
            }
            if (isDifferent) {
              updatedUserResponses[previousResponseIndex].checkBoxResponses =
                response;
              updatedUserResponses = [...updatedUserResponses].filter(
                (x, i) => i <= previousResponseIndex
              );
            }
          }
        } else {
          currentQuestion = state.questionList.find(
            (x) => x.question_number === state.currentQuestion!.next_question_id
          );
          response = action.payload.option;
          if (questionType === QuestionTypes.TextInput) {
            userResponse = {
              question_id: action.payload.question.questionId,
              question: action.payload.question.question,
              questionType: action.payload.question.questionType,
              textResponse: response,
              dmnId: action.payload.question.dmnId,
              dependency: action.payload.question.dependency,
              isManual: action.payload.question.isManual,
              responseIndex: responseIndex
            };
            if (previousResponseIndex !== -1) {
              let isDifferent = false;
              if (!updatedUserResponses[previousResponseIndex].textResponse) {
                isDifferent = true;
              } else {
                const previoustextResponse =
                  updatedUserResponses[previousResponseIndex].textResponse!;
                if (
                  previoustextResponse.toLowerCase() !== response.toLowerCase()
                ) {
                  isDifferent = true;
                }
                if (isDifferent) {
                  updatedUserResponses[previousResponseIndex].textResponse =
                    response;
                  updatedUserResponses = [...updatedUserResponses].filter(
                    (x, i) => i <= previousResponseIndex
                  );
                }
              }
            }
          } else if (questionType === QuestionTypes.DateInput) {
            userResponse = {
              question_id: action.payload.question.questionId,
              question: action.payload.question.question,
              questionType: action.payload.question.questionType,
              dateResponse: response,
              dmnId: action.payload.question.dmnId,
              dependency: action.payload.question.dependency,
              isManual: action.payload.question.isManual,
              responseIndex: responseIndex
            };
            if (previousResponseIndex !== -1) {
              updatedUserResponses[previousResponseIndex].dateResponse =
                response;
            }
          } else if (questionType === QuestionTypes.NumberInput) {
            userResponse = {
              question_id: action.payload.question.questionId,
              question: action.payload.question.question,
              questionType: action.payload.question.questionType,
              numberResponse: response,
              dmnId: action.payload.question.dmnId,
              dependency: action.payload.question.dependency,
              isManual: action.payload.question.isManual,
              responseIndex: responseIndex
            };
            if (previousResponseIndex !== -1) {
              let isDifferent = false;
              const previousNumberResponse =
                updatedUserResponses[previousResponseIndex].numberResponse!;
              if (previousNumberResponse !== response) {
                isDifferent = true;
              }
              if (isDifferent) {
                updatedUserResponses[previousResponseIndex].numberResponse =
                  response;
                updatedUserResponses = [...updatedUserResponses].filter(
                  (x, i) => i <= previousResponseIndex
                );
              }
            }
          } else {
            userResponse = {
              question_id: action.payload.question.questionId,
              question: action.payload.question.question,
              questionType: action.payload.question.questionType,
              customResponse: response,
              dmnId: action.payload.question.dmnId,
              dependency: action.payload.question.dependency,
              isManual: action.payload.question.isManual,
              responseIndex: responseIndex
            };
            if (previousResponseIndex !== -1) {
              updatedUserResponses[previousResponseIndex].customResponse =
                response;
            }
          }
        }
        if (previousResponseIndex === -1) {
          updatedUserResponses.push(userResponse);
          state.responseIndex = responseIndex;
        }
        // if (response && response.length && response[0].dmnId) {
        //   state.status = Status.Loading;
        // }
        if (currentQuestion && currentQuestion.dependency) {
          let skipQuestion: string = "NA";
          let defValue: string = "";

          updatedUserResponses.forEach((res) => {
            if (skipQuestion !== "NA") return false;
            if (res && res.checkBoxResponses) {
              res.checkBoxResponses.every((option) => {
                if (skipQuestion !== "NA") return false;
                if (option && option.optionValue && option.optionValue.length > 0) {
                  option!.optionValue.every((val) => {
                    if (
                      currentQuestion &&
                      val.key === currentQuestion!.dependency?.key
                    ) {
                      if (val.value === currentQuestion!.dependency?.value) {
                        skipQuestion =
                          currentQuestion!.dependency?.operator === "equals"
                            ? "false"
                            : "true";
                        return false;
                      } else {
                        skipQuestion =
                          currentQuestion!.dependency?.operator !== "equals"
                            ? "true"
                            : "false";
                        return false;
                      }
                    } else {
                      defValue =
                        currentQuestion!.dependency?.operator === "equals"
                          ? "true"
                          : "false";
                    }
                    return true;
                  });
                }
                return true;
              });
            }
            return true;
          });

          if (skipQuestion === "NA") skipQuestion = defValue;
          if (skipQuestion === "true") {
            currentQuestion = state!.questionList!.find(
              (x) => x.question_number === currentQuestion!.next_question_id
            );
          }
        }

        // if(action.payload.question.isFinal) {
        //   currentQuestion = undefined
        // }

        state.previousQuestionId = action.payload.question.questionId;
        state.previousQuestionResponse = response;
        state.currentQuestion = currentQuestion;
        state.userResponses = updatedUserResponses;
        state.selectedOptions = getSelectedOption(
          currentQuestion,
          updatedUserResponses
        );
      }
    },
    setPreviousQuestion: (state: QuestionState, action: PayloadAction<any>) => {
      if (state.questionList && state.currentQuestion) {
        let previousQuestionId = action.payload;
        const currentQuestion = state.questionList.find(
          (x) => x.question_number === previousQuestionId
        );
        if (currentQuestion) {
          state.currentQuestion = currentQuestion;
          let currentQuestionIndex = 0;
          if (state.userResponses.length > 0) {
            currentQuestionIndex = state.userResponses.findIndex(
              (x) => x.question_id === currentQuestion.question_number
            );
            if (currentQuestionIndex !== -1 && currentQuestionIndex !== 0) {
              const previousQuestion =
                state.userResponses[currentQuestionIndex - 1];
              state.previousQuestionId = previousQuestion.question_id;
            } else {
              state.previousQuestionId = undefined;
              state.previousQuestionResponse = "";
            }
          } else {
            state.previousQuestionId = undefined;
            state.previousQuestionResponse = "";
          }
          state.selectedOptions = getSelectedOption(
            currentQuestion,
            state.userResponses
          );
        }
      }
      if (
        state.currentQuestion &&
        state.currentQuestion.question_type === QuestionTypes.Custom
      ) {
        let financialTabsVisibility: any = {};
        financialTabsVisibility.income = state.currentQuestion.options.filter(
          (o) => o.optionText === FinancialTabs.Income
        )[0].visibility;
        financialTabsVisibility.assets = state.currentQuestion.options.filter(
          (o) => o.optionText === FinancialTabs.Assets
        )[0].visibility;
        financialTabsVisibility.expenses = state.currentQuestion.options.filter(
          (o) => o.optionText === FinancialTabs.Expenses
        )[0].visibility;
        financialTabsVisibility.summary = state.currentQuestion.options.filter(
          (o) => o.optionText === FinancialTabs.Summary
        )[0].visibility;
        let firstTab = financialTabsVisibility.income
          ? FinancialTabs.Income
          : financialTabsVisibility.assets
            ? FinancialTabs.Assets
            : financialTabsVisibility.expenses
              ? FinancialTabs.Expenses
              : FinancialTabs.Summary;
        let currentTabVisibile = state.currentQuestion.options.filter(
          (o) => o.optionText === state.activeFinancialTab
        )[0].visibility;
        state.activeFinancialTab =
          state.activeFinancialTab === FinancialTabs.Income
            ? firstTab
            : currentTabVisibile
              ? state.activeFinancialTab
              : firstTab;
      }
    },
    setSelectedOption: (state: QuestionState) => {
      let currentQuestionId: any = undefined;
      let currentQuestion: any = { ...state.currentQuestion };
      if (state.currentQuestion) {
        switch (state.currentQuestion.question_type) {
          case QuestionTypes.SingleSelectCheckBox:
          case QuestionTypes.MultiSelectCheckBox:
            let optionId =
              state.userResponses![0].checkBoxResponses![0].optionId;
            currentQuestionId = state.currentQuestion.options.find(
              (x) => x.optionId === optionId
            )!.nextQuestionId;
            currentQuestion = state.questionList!.find(
              (x) => x.question_number === currentQuestionId
            );
            state.currentQuestion = currentQuestion;
            state.selectedOptions = getSelectedOption(
              currentQuestion,
              state.userResponses
            );
        }
      }
    },
    handleCustomCheckBoxInput: (
      state: QuestionState,
      action: PayloadAction<any>
    ) => {
      let customInput = action.payload.customInputResponse;
      if (state.selectedOptions) {
        let selectedOptions = { ...state.selectedOptions };
        let isNew = true;
        let updatedCustomInputResponse =
          selectedOptions.customInputResponses &&
            selectedOptions.customInputResponses.length > 0
            ? selectedOptions.customInputResponses.map((x) => {
              if (x.key === customInput.key) {
                x = customInput;
                isNew = false;
              }
              return x;
            })
            : [];
        if (isNew) {
          updatedCustomInputResponse.push(customInput);
        }
        selectedOptions = {
          ...selectedOptions,
          customInputResponses: updatedCustomInputResponse,
        };
        state.selectedOptions = selectedOptions;
      } else {
        const selectedOptions = {
          customInputResponses: [customInput],
          selectedValues: [],
        };
        state.selectedOptions = selectedOptions;
      }
    },
    updateStatus: (state: QuestionState, action: PayloadAction<any>) => {
      state.userSurveyStatus = action.payload.userSurveyStatus;
      state.loanStatus = action.payload.loanStatus;
    },
  },
  extraReducers: (builder) => {
    builder
      //getIntentsAsync
      .addCase(getIntentsAsync.pending, (state: QuestionState) => {
        state.status = Status.Loading;
        state.userResponses = [];
        state.currentQuestion = undefined;
        state.previousQuestionResponse = "";
        state.previousQuestionId = undefined
      })
      .addCase(
        getIntentsAsync.fulfilled,
        (state: QuestionState, action: PayloadAction<QuestionApiResponse>) => {
          if (action.payload) {
            
            state.questionList = action.payload.intents;
            console.log("intentsss:",action.payload);
            if (action.payload.intents && action.payload.intents.length > 0) {
              const initialQuestion = action.payload.intents.find(
                (x) => x.is_primary
              );
              if (initialQuestion) {
                state.initialQuestionId = initialQuestion.question_number;
                state.currentQuestion = action.payload.intents.find(
                  (x) => x.question_number === initialQuestion.question_number
                );
              }
            }
            const userResponses = action.payload.userResponses;
            state.lastModifiedDate = action.payload.lastModifiedDate;
            state.userResponses = userResponses;
            if (userResponses.length && userResponses.length > 1) {
              state.responseIndex = userResponses.length;
              state.previousQuestionId =
                userResponses[userResponses.length - 2].question_id;
              state.previousQuestionResponse =
                userResponses[userResponses.length - 2].checkBoxResponses;
              const currentQuestionNumber: number = Number(userResponses[userResponses.length - 1].question_id);
              const currentQuestion = action.payload.intents.find(
                (x) => x.question_number === currentQuestionNumber
              );
              state.currentQuestion = currentQuestion;
              state.selectedOptions = getSelectedOption(
                currentQuestion,
                userResponses
              );
            } else {
              state.selectedOptions = undefined;
              state.previousQuestionId = undefined;
              state.previousQuestionResponse = "";
            }
            if (action.payload.userSurveyStatus) {
              state.userSurveyStatus = action.payload.userSurveyStatus;
            }
            if (action.payload.loanStatus) {
              state.loanStatus = action.payload.loanStatus;
            }
            state.primaryBorrowerStatus = action.payload.primaryBorrowerStatus;
            state.borrowersCount = action.payload.borrowersCount;
            state.isSurveyCompleted = action.payload.isSurveyCompleted;
            state.allBorrowerSurveyStatus = action.payload.allBorrowerSurveyStatus;
            state.suggestions = action.payload.suggestions;
            state.isCurrentUserPrimary = action.payload.isCurrentUserPrimary;
          }
          state.status = Status.Idle;
        }
      )
      .addCase(getIntentsAsync.rejected, (state: QuestionState) => {
        state.status = Status.Idle;
      })
      .addCase(getSuggestionsAsync.pending, (state: QuestionState) => {
        state.status = Status.Loading;
        state.suggestions = undefined;
      })
      .addCase(
        getSuggestionsAsync.fulfilled,
        (state: QuestionState, action: PayloadAction<any>) => {
          state.status = Status.Idle;
          console.log("suggestions: ",action.payload)
          if (action.payload && !action.payload.error) {
            state.suggestions = action.payload;
          }
        }
      )
      .addCase(getSuggestionsAsync.rejected, (state: QuestionState) => {
        state.status = Status.Idle;
        state.errorMessage = "An Error Occured While Getting Suggestions.";
      })
      .addCase(getUploadedFilesAsync.pending, (state: QuestionState) => {
        state.documentsUploaded = [];
      })
      .addCase(
        getUploadedFilesAsync.fulfilled,
        (state: QuestionState, action: PayloadAction<any>) => {
          if (action.payload && action.payload.length > 0) {
            const uploadedDocs = action.payload as UploadedDocument[];
            state.documentsUploaded = uploadedDocs.map((doc) => {
              const updatedCategory = doc.category.map((category) => {
                const updateFiles = category.files.map((file) => {
                  file = { ...file, isUploaded: true };
                  return file;
                });
                category = { ...category, files: updateFiles };
                return category;
              });
              doc = { ...doc, category: updatedCategory };
              return doc;
            });
          } else {
            state.documentsUploaded = [];
          }
        }
      );
  },
});

export const {
  setContinueButtonStatus,
  clearState,
  updateLoadingStatus,
  setDisclosure,
  resetUserResponses,
  addUserResponse,
  setPreviousQuestion,
  addDocument,
  handleDocumentUpload,
  handleSelectedOptions,
  setCurrentQuestion,
  changeActiveFinancialTab,
  //verifyDependency,
  setSelectedOption,
  updateUserResponse,
  setIsWorkoutExit,
  handleCustomCheckBoxInput,
  updateStatus,
  handleCount,
} = questionSlice.actions;

export const readQuestionState = (state: RootState) => state.question;

export default questionSlice.reducer;
