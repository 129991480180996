import { API, graphqlOperation } from "aws-amplify";
import { listCategories } from '../graphql/queries';
import { IFinancialData } from "../redux/stores/questionStore";
import { getApi } from "../api/base";

export const getManualCategories = async (params: any) => {
    try {
        const listCategoriesResponse: any = await getApi("api/Hoa/Hoa/listCategories")
        // await API.graphql(graphqlOperation(listCategories));
       
        let data =  JSON.parse(listCategoriesResponse?.data?.data?.res).category;
        const categoryList = data.filter(l => l.servicer_id === params.loanData.servicer_id && l.sub_type === params.loanData.loan_type);
        console.log("category: ", categoryList)
        const categories = {
            "assets": categoryList.filter((c) => c.type === "manual_assets").map((a) => { return { "display_value": a.display_value, "description1": a.description1, "description2": a.description2, "description3": a.description3, "value": null, "display_order": a.display_order, servicer_provided: false } as IFinancialData }),
            "income": categoryList.filter((c) => c.type === "manual_income").map((a) => { return { "display_value": a.display_value, "description1": a.description1, "description2": a.description2, "description3": a.description3, "value": null, "display_order": a.display_order, servicer_provided: false } as IFinancialData }),
            "expenses": categoryList.filter((c) => c.type === "manual_expenses").map((a) => { return { "display_value": a.display_value, "description1": a.description1, "description2": a.description2, "description3": a.description3, "value": null, "display_order": a.display_order, servicer_provided: false } as IFinancialData })
        };
        console.log("category: ", categories)
        categoryList.filter((c) => c.type === "servicer_provided_expenses").map((a: any) => {
            categories["expenses"].push({
                display_value: a.display_value,
                description1: a.description1,
                value: Math.abs(params.loanData ? params.loanData[a.data_value] : 0.00),
                servicer_provided: true,
                display_order: a.display_order
            } as IFinancialData);
        });
        console.log("category: ", categories)

        return categories;

    }
    catch (e) {
        console.log(e);
    }


}
