export const workoutOptions = {
    Forbearance: "COVID-19 Forbearance"
}

export const workoutDescriptions = {
    Forbearance: "COVID-19 Forbearance allows you to temporarily suspend or reduce your payments for a specified time period."
}

export const ruleSelectionCriteria = {
    Forbearance: "User opts for Forbearance"
}