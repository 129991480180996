export const QuestionTypes = {
    TextInput: "text_input",
    NumberInput: "number_input",
    DateInput: "date_input",
    SingleSelectCheckBox: "single_select_checkbox",
    MultiSelectCheckBox: "multi_select_checkbox",
    Custom: "custom",
    InformationScreen: "information_screen",
    PaymentScreen: "payment_screen",
    DocumentUploadScreen: "document_upload_screen",
    Amount: "amount",
    Disclosure: "disclosure_screen",
    CustomHtml: "custom_html"
}