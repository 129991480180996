/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo, useContext, useEffect, useState } from "react";
import { useAppDispatch } from "../../app/hooks";
import { getUserDetailsAsync, setIsloggedIn, setTheme, setUser } from "../../redux/reducers/appReducer";
import { AuthRequestParams } from "../../redux/stores/appStore";
import { useNavigate } from "react-router";
import { liveGageTheme, themeContext } from "../../constants/theme";
import { updateTheme } from "../../helpers/theme";
import { Loader } from "../Loader";
import { Auth } from "aws-amplify";

const Login = () => {
    const theme = useContext(themeContext);
    console.log(theme);
    const dispatch = useAppDispatch();
    const [modal, showModal] = useState(false);
    const history = useNavigate();
    const [inputType, changeInputType] = React.useState("password");
    const [loadingStatus, changeLoadingStatus] = React.useState(false);

    useEffect(() => {
        updateTheme(liveGageTheme);
        dispatch(setTheme(liveGageTheme));
    }, [dispatch]);

    const [userDetails, updateUserDetails] = React.useState({ userId: "", password: "" } as AuthRequestParams);

    const handleSubmit = async (e: any) => {
        if (!userDetails.userId) {
            showModal(!modal);
        }
        else {
            changeLoadingStatus(true);
            dispatch(setUser(userDetails.userId));

            try {
                const username = userDetails.userId;
                const password = userDetails.password;
                const user = await Auth.signIn(username, password);
                console.log(user);
                if (user && user.signInUserSession && user.signInUserSession.accessToken) {
                    const data = { userId: userDetails.userId, password: null }
                    await dispatch(getUserDetailsAsync(data));
                    dispatch(setIsloggedIn(true));
                    sessionStorage.setItem('jwtToken', user.signInUserSession.accessToken.jwtToken);
                    history("/")
                }
                else{
                    window.alert("User not authorised!");
                    changeLoadingStatus(false);
                }

            } catch (error) {
                console.log(error);
                changeLoadingStatus(false);
                window.alert("Invalid user credentials!");
            }
        }
    }

    const handleChange = (e: any, field: string) => {
        updateUserDetails({ ...userDetails, [field]: e.target.value });
    }

    const viewPassword = (e: any) => {
        if (inputType === "password") {
            changeInputType("text");
        }
        else {
            changeInputType("password");
        }
    }

    return (
        <>
            {loadingStatus && <Loader />}
            <div className="login_section">
                <div className="login_logo_sect">
                    <div className="login_logo">
                        <span><img alt="login" src="assets/img/HOMEAID.svg" /></span>
                    </div>
                </div>
                <div className="login_form_sect">
                    <h6 className="login_link"><a >Let’s find your account!</a></h6>
                    <h1 className="login_title">Login</h1>


                    <div className="form-floating ct_form_field">
                        <input type="text" className="form-control" id="floatingInput" placeholder="User Id" value={userDetails.userId} onChange={(e: any) => handleChange(e, "userId")} />
                        <label htmlFor="floatingInput">User Id</label>
                    </div>

                    <div className="form-floating ct_form_field ct_form_icon_group">
                        <input type={inputType} className="form-control" id="floatingInput" placeholder="Password" value={userDetails.password} onChange={(e: any) => handleChange(e, "password")} />
                        <span onClick={viewPassword} className={`far ${inputType === "text" ? "fa-eye" : "fa-eye-slash"}`} />
                        <label htmlFor="floatingInput">Password</label>
                    </div>
                    <div className="login_b_btns">
                        <ul>

                            <a href="/forgotpassword" className="btn btn-link  text-center btn-block" >Forgot Password</a>
                        </ul>
                    </div>

                    <div className="login_b_btns">
                        <ul>
                            <li onClick={handleSubmit}><a className="btn btn-primary text-center btn-block">Login</a></li>
                            <li onClick={() => showModal(!modal)}><a className="btn btn-link  text-center btn-block" data-toggle="modal" data-target="#troublereg">Trouble accessing your account</a></li>
                        </ul>
                    </div>
                    <div className="login_b_btns">
                        <ul>

                            <a href="/register" className="btn btn-link  text-center btn-block" >Register</a>
                        </ul>
                    </div>
                </div>
            </div>


            <div className={`modal fade`} id="troublereg" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="trouble_popup_block">
                                <h3>Trouble Login?</h3>
                                <p>If you are having trouble Login, please double check yourID and Password and try again.</p>
                                <p>If you are still unable to register, please contact customer service at: 1.888.777.6666</p>
                                <a onClick={() => showModal(!modal)} className="btn btn-primary text-center btn-sm" data-toggle="modal" data-target="#troublereg" >OK</a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
export default memo(Login);