import { getUserSubmission, listUserResponsesBySubmissionId, listBorrowersByLoanId, getLoanReviewStatus, getBorrowers, getCovidForbearance, listUserSubmissionsByLoanId, listBorrowersDataByLoanId } from './../graphql/queries';
import { Question, UserResponse } from './../redux/stores/questionStore';
import { API, graphqlOperation } from "aws-amplify";
import { listQuestionMasters } from '../graphql/queries';
import { LoanMaster } from "../redux/stores/appStore";
import { SurveyStatus } from '../constants/surveyStatus';
import { intentCategories } from '../constants/intentCategoryConstants';
import { getApi } from '../api/base';
export const getIntents = async (params: any) => {
    try {
        let userResponses: any[] = [];
        let userSurveyStatus = SurveyStatus.NotStarted;
        let primaryBorrowerUserId: any = null;
        let primaryBorrowerStatus = SurveyStatus.NotStarted;
        let borrowerLength = 0;
        let isCurrentUserPrimary = true;
        let column="loan_number='"+params.loanData.loan_number+"'";
        const listUserSubmissionsByLoanIdResponse: any = await getApi("api/Hoa/Hoa/listUserSubmissionsByLoanId?column="+column)
        // await API.graphql(graphqlOperation(listUserSubmissionsByLoanId, { loan_number: params.loanData.loan_number }));
        console.log("data: ",listUserSubmissionsByLoanIdResponse)
        const allSubmissions: any = listUserSubmissionsByLoanIdResponse?.data;
        if (allSubmissions && allSubmissions.length > 0) {
            const firstBorrowerSubmission = allSubmissions.find(x => x.is_primary_borrower);
            primaryBorrowerStatus = firstBorrowerSubmission.submission_status;
            primaryBorrowerUserId = firstBorrowerSubmission.user_name;
            if (primaryBorrowerUserId !== params.userData.user_name) {
                isCurrentUserPrimary = false;
            }
        }
        else {
            primaryBorrowerUserId = params.userData.user_name;
        }

        const intents = await getIntentsData(params.loanData, params.userData, isCurrentUserPrimary);
        console.log("intents",intents)
        let allBorrowers: any = await getApi("api/Hoa/Hoa/listBorrowersDataByLoanId?loan_number="+params.loanData.loan_number)
        // await API.graphql(graphqlOperation(listBorrowersDataByLoanId, { loan_number: params.loanData.loan_number }));
        console.log("borrower: ",allBorrowers.data)
        if (allBorrowers && allBorrowers?.data  && allBorrowers?.data.length > 0) {
            borrowerLength = allBorrowers?.data.length;
            allBorrowers = allBorrowers?.data;
        }


        const getUserSubmissionResponse: any =await getApi("api/Hoa/Hoa/getUserSubmission?user_name="+params.userData.user_name+"&loan_number="+params.loanData.loan_number+"&servicer_id="+params.loanData.servicer_id)
        console.log("users:",getUserSubmissionResponse)
        //  await API.graphql(graphqlOperation(getUserSubmission, { user_name: params.userData.user_name, loan_number: params.loanData.loan_number, servicer_id: params.loanData.servicer_id }));
        if (getUserSubmissionResponse && getUserSubmissionResponse?.data && getUserSubmissionResponse?.data[0]) {
            userSurveyStatus = getUserSubmissionResponse?.data[0].submission_status;
            let column="user_submission_id='"+getUserSubmissionResponse?.data[0].user_submission_id+"' ";
            let userResponseList: any = await getApi("api/Hoa/Hoa/listUserResponsesBySubmissionId?column="+column)
            // await API.graphql(graphqlOperation(listUserResponsesBySubmissionId, { user_submission_id: getUserSubmissionResponse.data.getUserSubmission.user_submission_id }));
            if (userResponseList && userResponseList.data ) {
                userResponseList.data.forEach(response => {
                    console.log("userss:", response.answer)
                    const answer = JSON.parse(response?.answer);
                    console.log("userss:",answer)
                    const questionNumber = parseInt(response.question_id)
                    console.log("userss:",questionNumber)
                    const question = intents?.find(x => x.question_number === questionNumber);
                    console.log("userss:",question)
                  
                    if (question) {
                        const userResponse: UserResponse = {
                            question_id: response.question_id,
                            question: question.question_text,
                            questionType: question.question_type,
                            checkBoxResponses: answer ? answer.checkBoxResponses : null,
                            customInputResponse: answer ? answer.customInputResponse : null,
                            customResponse: answer ? answer.customResponse : null,
                            dateResponse: answer ? answer.dateResponse : null,
                            numberResponse: answer ? answer.numberResponse : null,
                            textResponse: answer ? answer.textResponse : null,
                            dependency: question.dependency,
                            isManual: question.is_manual,
                            responseIndex: response.response_index
                        }
                        userResponses.push(userResponse);
                       
                    }
                });
            }
        }
        let isSurveyCompleted = false;
        let allBorrowerSurveyStatus: any[] = [];
        //const listUserSubmissionsByLoanIdResponse: any = await API.graphql(graphqlOperation(listUserSubmissionsByLoanId, { loan_number: params.loanData.loan_number }));
        //const allResponses: any = listUserSubmissionsByLoanIdResponse.data.listUserSubmissionsByLoanId;
        console.log("userss:",userResponses)
        if (allSubmissions) {
            allBorrowers.forEach(borr => {
                let borrResponse = allSubmissions.find(x => x.user_name === borr.user_name);
                
                if (borrResponse) {
                    
                    allBorrowerSurveyStatus.push({
                        user_name: borrResponse.user_name,
                        submission_status: borrResponse.submission_status
                    });
                }
                else {
                    allBorrowerSurveyStatus.push({
                        user_name: borr.user_name,
                        submission_status: SurveyStatus.NotStarted
                    });
                }
            });
        }


        let impendingResponses: any = undefined;
        if (allSubmissions && allSubmissions.length === borrowerLength) {
            impendingResponses = allSubmissions.find((x: any) => (x.submission_status !== SurveyStatus.Completed && x.submission_status !== SurveyStatus.DocPending && x.user_name !== params.userData.user_name));
            if (!impendingResponses) {
                isSurveyCompleted = true;
            }
        }
        let userReview: any = await getApi("api/Hoa/Hoa/getLoanReviewStatus?loan_number="+params.loanData.loan_number+"&servicer_id="+params.loanData.servicer_id)
        // await API.graphql(graphqlOperation(getLoanReviewStatus, filter));
        console.log("response: ",userReview)
        let workoutStatus: any = null;
        if (userReview && userReview.data && userReview?.data[0] && userReview?.data[0].workout_option) {
            workoutStatus = {
                suggestions: {
                    workoutOption: userReview?.data[0].workout_option,
                    workoutDescription: userReview.data[0].workout_description
                }
            }
        }
        console.log("response: ",intents)
        const response = {
            intents: intents,
            userResponses: userResponses,
            lastModifiedDate: "",
            userSurveyStatus: userSurveyStatus,
            loanStatus: userReview && userReview.data && userReview.data? userReview.data[0].status : SurveyStatus.NotStarted,
            primaryBorrowerStatus: primaryBorrowerStatus,
            borrowersCount: borrowerLength,
            isSurveyCompleted: isSurveyCompleted,
            allBorrowerSurveyStatus: allBorrowerSurveyStatus,
            suggestions: workoutStatus,
            isCurrentUserPrimary: isCurrentUserPrimary
        };
        console.log("response: ",response)
        return response;
    }
    catch (err) {
        return null;
    }
}



const getIntentsData = async (loanData: LoanMaster, userData: any, isCurrentUserPrimary: boolean) => {
    let intentsResponse: any[] = [];
    let filter: any = {};
    filter = {};
    let primaryBorrowerUserId: any = "";

    //let borrowerData: any = await API.graphql(graphqlOperation(getBorrowers, { user_name: userData.user_name }));
    //if (borrowerData && borrowerData.data && borrowerData.data.getBorrowers) {
    //if (borrowerData.data.getBorrowers.is_primary_borrower) {

    const intents: any =await getApi("api/Hoa/Hoa/listQuestionMasters");
    console.log("intents: ",JSON.parse(intents?.data?.data?.res).questions)
    const intentsData=JSON.parse(intents?.data?.data?.res).questions;
    // userReviewawait API.graphql(graphqlOperation(listQuestionMasters));

    if (isCurrentUserPrimary) {
        if (loanData) {
            const covidForbearanceData: any = await getForbearanceData(loanData);
            console.log("covid: ",covidForbearanceData)
            loanData = { ...loanData, forbearanceEndDate: covidForbearanceData.forbearanceEndDate };
            switch (loanData.loan_type) {
                case "FHA":
                    filter = {
                        category: covidForbearanceData.category,
                        servicerId: loanData.servicer_id
                    }
                    break;
            }
                console.log("intentsData: ",intentsData,loanData)
            intentsResponse = intentsData.filter(l => l.category === covidForbearanceData.category && l.servicer_id === parseInt(loanData.servicer_id)); //TODO: filter to be change based on switch
            console.log("intentsData: ",intentsResponse)
            intentsResponse = intentsResponse.map((i: any) => {
                const fromIndex: number = i["question_text"].indexOf('@@')
                i.tool_tip = i.tool_tip ? i.tool_tip.tooltip : [];
                i.dependency = i.dependency ? i.dependency.dependency : null;
                i.options = i.options ? i.options.options : [];
                if (fromIndex !== -1) {
                    const columnName: string = i["question_text"].substring(fromIndex + 2, i["question_text"].indexOf(' ', fromIndex));

                    i.question_text = i["question_text"].replace('@@' + columnName, loanData[columnName])

                    return i
                } else {
                    return i
                }
            })

            if (!intentsResponse) {
                console.log("Some error occurred fetching the records");
            }
            else {
                console.log(intentsResponse);
            }
            console.log("intentsData: ",intentsData)
            return intentsResponse as Question[];
        }
    }

    //}
    else {
        switch (loanData.loan_type) {
            case "FHA":
                let category = await getCoborrowerCategory(loanData.loan_number, intentsData);
                filter = {
                    category: category,
                    servicerId: loanData.servicer_id
                }
                break;
            default:
                filter = {
                    category: "",
                    servicerId: 0
                }
        }
        console.log("intentsData: ",intentsData)
        intentsResponse = intentsData.filter(l => l.category === filter.category && l.servicer_id === loanData.servicer_id); //TODO: filter to be change based on switch
        intentsResponse = intentsResponse.map((i: any) => {
            i.tool_tip = i.tool_tip ? JSON.parse(JSON.parse(i.tool_tip)).tooltip : [];
            i.dependency = i.dependency ? JSON.parse(JSON.parse(i.dependency)) : null;
            i.options = i.options ? (JSON.parse(JSON.parse(i.options))).options : [];
            return i;
        });
        return intentsResponse as Question[];
    }

}

//}

const getForbearanceData = async (loanData: LoanMaster) => {
    try {
        const forbearanceResponse: any = await getApi("api/Hoa/Hoa/getCovidForbearance?loan_type="+loanData.loan_type+"&servicer_id="+loanData.servicer_id);
        console.log("forb: ",loanData.covid_forbearance_data,loanData)
        // await API.graphql(graphqlOperation(getCovidForbearance, { loan_type: loanData.loan_type, servicer_id: loanData.servicer_id }));
        const dates = forbearanceResponse.data && forbearanceResponse.data[0].dates ? forbearanceResponse.data[0].dates.dates : [];
        const loanForbearanceData = loanData.covid_forbearance_data ? (JSON.parse(loanData.covid_forbearance_data).length>0 ?  JSON.parse(JSON.parse(loanData.covid_forbearance_data)).data: []) : [];

        let covidForbearanceData: any = {};
        let onForbearanceStatus: boolean = false;
        let initialForbearanceDate = new Date();
        let covidForbearancePeriod = 0;
        let category: string = "";
        let maxForbearancePeriod = 0;
        let forbearanceEndDate = "";

        if (loanForbearanceData !== null && loanForbearanceData.length > 0) {
            covidForbearancePeriod = getCovidForbearancePeriod(loanForbearanceData);
            const sortedForbearanceData = sortForbearanceDates(loanForbearanceData);
            forbearanceEndDate = sortedForbearanceData[sortedForbearanceData.length - 1].forbearanceEndDate;
            initialForbearanceDate = new Date(sortedForbearanceData[0].initialForbearanceDate);
            onForbearanceStatus = true;
        }

        if (onForbearanceStatus) {
            if (dates && dates.length > 0) {
                dates.forEach((x: any) => {
                    if (initialForbearanceDate >= new Date(x.startDate) && initialForbearanceDate <= new Date(x.endDate)) {
                        maxForbearancePeriod = x.maxForbearancePeriod;
                    }
                });

                if (maxForbearancePeriod > 0 && covidForbearancePeriod > 0) {
                    if (loanData.is_eligible_for_covid_assistance) {
                        category = (maxForbearancePeriod - covidForbearancePeriod) >= 1 ? intentCategories.FHA_FB_EXTN : intentCategories.FHA_FB_NO_EXTN;
                    }
                    else {
                        category = (maxForbearancePeriod - covidForbearancePeriod) >= 1 ? intentCategories.FHA_FB_EXTN_NO_COVID : intentCategories.FHA_FB_NO_EXTN_NO_COVID;
                    }
                }
            }
        }
        else {
            const covidEmergencyEndDate = new Date(dates[dates.length - 1].endDate);
            const differenceInTime = covidEmergencyEndDate.getTime() - initialForbearanceDate.getTime();
            const differenceInMonths = Math.round(differenceInTime / (1000 * 3600 * 24 * 7 * 4));

            if (differenceInMonths >= 1) {
                category = loanData.is_eligible_for_covid_assistance ? intentCategories.FHA : intentCategories.FHA_FB_NO_COVID;
            }
            else {
                category = loanData.is_eligible_for_covid_assistance ? intentCategories.FHA_NO_FB : intentCategories.FHA_NO_FB_NO_COVID;
            }
        }
        covidForbearanceData = { category, forbearanceEndDate };
        return covidForbearanceData;
    }
    catch (e) {
        console.log(e);
    }

}

const getCovidForbearancePeriod = (data: any) => {
    let monthsOfForbearance = 0;
    if (data && data.length > 0) {
        data.forEach((period: any) => {
            const differenceInTime = new Date(period.forbearanceEndDate).getTime() - new Date(period.initialForbearanceDate).getTime();
            const differenceInMonths = differenceInTime / (1000 * 3600 * 24 * 7 * 4);
            monthsOfForbearance += differenceInMonths;
        });
    }
    return Math.round(monthsOfForbearance);
}

const sortForbearanceDates = (data: any[]) => {
    const sortedForbearanceDates = data.sort((a, b) => new Date(a.initialForbearanceDate).getTime() - new Date(b.initialForbearanceDate).getTime());
    return sortedForbearanceDates;
}

const getCoborrowerCategory = async (loanId: any, intents: any) => {
    let category: string = "";
    let dmnId: string = "";
    let column="loan_number='"+loanId+"'"
    const userSubmissionsByLoanIdResponse: any = await getApi("api/Hoa/Hoa/listUserSubmissionsByLoanId?column="+column);
    // await API.graphql(graphqlOperation(listUserSubmissionsByLoanId, { loan_number: loanId }));
    if (userSubmissionsByLoanIdResponse && userSubmissionsByLoanIdResponse.data && userSubmissionsByLoanIdResponse.data[0]) {
        const allSubmissions: any = userSubmissionsByLoanIdResponse.data;
        let primaryBorrowerSubmissionId: any = "";
        allSubmissions.forEach(submission => {
            if (submission.is_primary_borrower) {
                primaryBorrowerSubmissionId = submission.user_submission_id;
            }
        });
        let column1="user_submission_id='"+primaryBorrowerSubmissionId+"'";
        const userResponsesBySubmissionId: any =await getApi("api/Hoa/Hoa/listUserResponsesBySubmissionId?column="+column1);
        //  await API.graphql(graphqlOperation(listUserResponsesBySubmissionId, { user_submission_id: primaryBorrowerSubmissionId.toString() }));
        if (userResponsesBySubmissionId && userResponsesBySubmissionId.data) {
            const primaryBorrowerResponses = userResponsesBySubmissionId.data;
            let customResponseQuestionId : any;
            primaryBorrowerResponses.forEach(res => {
                let answer = JSON.parse(res.answer);
                if (answer && answer.checkBoxResponses) {
                    answer.checkBoxResponses.forEach(x => {
                        if (x.dmnId) {
                            dmnId = x.dmnId;
                        }
                    });
                }
                if(answer && answer.customResponse){
                    customResponseQuestionId = res.question_id;
                }
            });
            if ((dmnId === "" || dmnId === null) && intents   && intents.length > 0) {
                let questionList = intents;
                let question = questionList.find(x => x.question_number.toString() === customResponseQuestionId);
                if (question.dmn_id) {
                    dmnId = question.dmn_id;
                }
            }
        }
        if (dmnId && dmnId !== "" && dmnId !== null && dmnId !== "null") {
            if (dmnId === "fhaCovidRuleNewKey") {
                category = "FHA_CB_COVID";
            }
            else {
                category = "FHA_CB"
            }
        }

        return category;

    }

}

