import { ruleSelectionCriteria, workoutDescriptions, workoutOptions } from './../constants/workoutOptions';
import { API, graphqlOperation } from "aws-amplify";
import { templates } from "../constants/emailTemplateConstants";
import { SurveyStatus } from "../constants/surveyStatus";
import { createLoanReviewStatus, updateLoanReviewStatus, updateLoanStatusInVertica } from "../graphql/mutations";
import { getLoanReviewStatus, listBorrowersByLoanId, listBorrowersDataByLoanId, listUserSubmissionsByLoanId, sendEmailService } from "../graphql/queries";
import { setUserSurveyStatusAsync } from "./questionServicers/userResponseService";
import { getApi, postApi } from '../api/base';

export const submitLoanReview = async (loanId: string, servicerId: number, userName: string, ruleEngineResponse?: any) => {
    try {
        const loanReview = await getLoanReview(loanId, servicerId);
        let createdBy = "";
        let createdDate = "";
        let requestDate = "";
        if (loanReview) {
            createdBy = loanReview.created_by;
            createdDate = loanReview.created_date;
            requestDate = loanReview.request_date;
        }
        let loanReviewData: any = {
            loanId: loanId,
            servicerId: servicerId,
            status: SurveyStatus.ServicerFollowUpRequired,
            createdBy: createdBy ? createdBy : userName,
            requestDate: requestDate ? requestDate : new Date().toUTCString(),
            createdDate: createdDate ? createdDate : new Date().toUTCString(),
            modifiedBy: userName,
            modifiedDate: new Date().toUTCString()
        }
        if (ruleEngineResponse && ruleEngineResponse.body && (ruleEngineResponse.statusCode === 200)) {
            ruleEngineResponse = JSON.parse(ruleEngineResponse.body)
            if (ruleEngineResponse && ruleEngineResponse.suggestions && ruleEngineResponse.suggestions.workoutOption) {
                loanReviewData = {
                    ...loanReviewData,
                    workoutOption: ruleEngineResponse.suggestions.workoutOption,
                    workoutDescription: ruleEngineResponse.suggestions.workoutDescription,
                    ruleSelectionCriteria: ruleEngineResponse.suggestions.ruleSelectionDetails,
                    disclosure: "",
                    execution_id: ruleEngineResponse.suggestions.executionID

                }
            }
            else {
                loanReviewData = {
                    ...loanReviewData,

                    workoutOption: "",
                    workoutDescription: "",
                    ruleSelectionCriteria: "",
                    disclosure: "User could not get pre-qualified for any workout option",
                    execution_id: ""

                }
            }
        }
        else {
            loanReviewData = {
                ...loanReviewData,

                workoutOption: "",
                workoutDescription: "",
                ruleSelectionCriteria: "",
                disclosure: "User could not get pre-qualified for any workout option",
                execution_id: ""
            }
        }
        let submitStatus: any;
        let submitResponse: any;
        if (!loanReview) {
            let data={
                loan_number: loanReviewData.loanId,
                    servicer_id: loanReviewData.servicerId,
                    created_by: loanReviewData.createdBy,
                    created_date: loanReviewData.createdDate,
                    modified_by: loanReviewData.modifiedBy,
                    modified_date: loanReviewData.modifiedDate,
                    request_date: loanReviewData.requestDate,
                    status: loanReviewData.status,
                    workout_option: loanReviewData.workoutOption,
                    workout_description: loanReviewData.workoutDescription,
                    rule_selection_criteria: loanReviewData.ruleSelectionCriteria.replaceAll("\n", ".,"),
                    disclosure: loanReviewData.disclosure,
                    execution_id: loanReviewData.execution_id
            }
            submitStatus = await postApi("api/Hoa/Hoa/createLoanReviewStatus",data, false, true)
            // await API.graphql(graphqlOperation(createLoanReviewStatus, {
            //     input: {
            //         loan_number: loanReviewData.loanId,
            //         servicer_id: loanReviewData.servicerId,
            //         created_by: loanReviewData.createdBy,
            //         created_date: loanReviewData.createdDate,
            //         modified_by: loanReviewData.modifiedBy,
            //         modified_date: loanReviewData.modifiedDate,
            //         request_date: loanReviewData.requestDate,
            //         status: loanReviewData.status,
            //         workout_option: loanReviewData.workoutOption,
            //         workout_description: loanReviewData.workoutDescription,
            //         rule_selection_criteria: loanReviewData.ruleSelectionCriteria.replaceAll("\n", ".,"),
            //         disclosure: loanReviewData.disclosure,
            //         execution_id: loanReviewData.execution_id
            //     }
            // }));
        }
        else {
            const input = {
                loan_number: loanReviewData.loanId,
                servicer_id: loanReviewData.servicerId,
                modified_by: loanReviewData.modifiedBy,
                modified_date: loanReviewData.modifiedDate,
                status: loanReviewData.status,
                workout_option: loanReviewData.workoutOption,
                workout_description: loanReviewData.workoutDescription,
                rule_selection_criteria: loanReviewData.ruleSelectionCriteria.replaceAll("\n", ".,"),
                disclosure: loanReviewData.disclosure,
                execution_id: loanReviewData.execution_id

            }
            submitStatus =await postApi("api/Hoa/Hoa/updateLoanReviewStatus",input, false, true)
            //  await API.graphql(graphqlOperation(updateLoanReviewStatus, {
            //     input: input
            // }));
        }
        if (submitStatus && submitStatus.data ) {
            submitResponse = submitStatus.data
        }
        try {
            const updateLoanStatus: any =await postApi("api/Hoa/Hoa/KafkaDataExportToVertica?loanNumber="+loanReviewData.loanId, false, true)
            //  await API.graphql(graphqlOperation(updateLoanStatusInVertica,
            //     {
            //         input: {
            //             loan_number: loanReviewData.loanId
            //         }
            //     }));
            console.log(updateLoanStatus)
        }
        catch (ex) {
            console.log(ex)
        }
        return submitResponse;
    }
    catch (error) {
        console.log(JSON.stringify(error));
    }
}
export const getLoanReview = async (loanId: string, servicerId: number) => {
    const loanReview: any = await getApi("api/Hoa/Hoa/getLoanReviewStatus?loan_number="+loanId+"&servicer_id="+servicerId)
    // await API.graphql(graphqlOperation(getLoanReviewStatus, filter));
    // if (!loanReview) {
    //     Logger.error("Unable to find records for the intended user");
    // }
    return loanReview ? loanReview.data[0] : undefined;
}

export const updateSurveyStatus = async (params: any) => {
    try {
        const appUrl = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ":" + window.location.port : "");
        const userId: string = params.loanDetails.user_id;
        const loanId = params.loanDetails.loan_number;
        const servicerId = params.loanDetails.servicer_id;
        const userSurveyStatus = params.userSurveyStatus;
        const is_primary_borrower = params.isCurrentUserPrimary;

        let loanSurveyStatus = "";
        const listBorrowersByLoanIdResponse: any = await getApi("api/Hoa/Hoa/listBorrowersDataByLoanId?loan_number="+loanId)
        // await API.graphql(graphqlOperation(listBorrowersDataByLoanId, { loan_number: loanId }));
        const allBorrowers: any = listBorrowersByLoanIdResponse.data;
        // const primaryBorrower = allBorrowers && allBorrowers.length > 0 ? allBorrowers.find(x => x.is_primary_borrower) : null;
        //const is_primary_borrower: boolean = primaryBorrower ? primaryBorrower.user_name === userId : false;

        const loanDetails = {
            userId: userId,
            loanId: loanId,
            servicerId: servicerId as number,
            is_primary_borrower: is_primary_borrower
        }
        const response = await setUserSurveyStatusAsync(loanDetails, params.userSurveyStatus);
        let column="loan_number='"+loanId+"'";
        const listUserSubmissionsByLoanIdResponse: any = await getApi("api/Hoa/Hoa/listUserSubmissionsByLoanId?column="+column)
        // await API.graphql(graphqlOperation(listUserSubmissionsByLoanId, { loan_number: loanId }));
        const allSubmissions: any = listUserSubmissionsByLoanIdResponse.data;
        switch (params.userSurveyStatus) {
            case SurveyStatus.NotStarted:
                if (allSubmissions && allSubmissions.length > 0) {
                    loanSurveyStatus = SurveyStatus.BorrowerPending;
                }
                else {
                    loanSurveyStatus = SurveyStatus.NotStarted;
                }
                break;
            case SurveyStatus.Initiated:
                if (allSubmissions && allSubmissions.length > 0) {
                    if (allSubmissions.length === 1) {
                        loanSurveyStatus = SurveyStatus.Initiated;
                    }
                    else {
                        allSubmissions.forEach((x: any) => {
                            if (x.submission_status === SurveyStatus.Completed) {
                                loanSurveyStatus = SurveyStatus.BorrowerPending;
                                return;
                            }
                        });
                    }
                }
                break;
            case SurveyStatus.DocPending:
            case SurveyStatus.Completed:
                let userLoanReview = await getLoanReview(loanId, servicerId);
                if (allSubmissions.length === 1 && allBorrowers.length > 1) {
                    let currentBorrower = allBorrowers.find((x: any) => x.user_name === userId);
                    let templateData: any = [];
                    let currentBorrowerTemplateData: any = [];
                    if (currentBorrower) {
                        currentBorrowerTemplateData.push({ "email": params.email, "name": currentBorrower.first_name });
                    }
                    if (currentBorrowerTemplateData.length > 0) {
                        let emailData = {
                            templateName: templates.CurrentBorrowerSurveyInitiated,
                            templateData: currentBorrowerTemplateData,
                            action: "SendEmail"
                        }
                      const emailResponse =await postApi("api/Hoa/Hoa/HoaEmailService?templateName="+templates.CurrentBorrowerSurveyInitiated+"&action="+emailData.action+"&templateInput="+emailData.action,currentBorrowerTemplateData, false, true)
                    //     // await API.graphql(graphqlOperation(sendEmailService, { data: JSON.stringify(emailData) }));
                   console.log(emailResponse);
                    }
                    allBorrowers.forEach((borrower: any) => {
                        if (borrower.user_name !== userId) {
                            let borrower_type = "coborrower";
                            templateData.push({ "email": params.email, "name": borrower.first_name, "user": borrower_type, "appUrl": appUrl });
                        }
                    });
                    if (templateData.length > 0) {
                        let emailData = {
                            templateName: templates.BorrowerSurveyInitiated,
                            templateData: templateData,
                            action: "SendEmail"
                        }
                       const emailResponse = await postApi("api/Hoa/Hoa/HoaEmailService?templateName="+templates.BorrowerSurveyInitiated+"&action="+emailData.action+"&templateInput="+emailData.action,templateData, false, true)
                        // // await API.graphql(graphqlOperation(sendEmailService, { data: JSON.stringify(emailData) }));
                        // console.log(emailResponse);
                    }
                }
                else if (allSubmissions && allBorrowers && allSubmissions.length === allBorrowers.length) {
                    let impendingResponses = allSubmissions.find((x: any) => (x.submission_status !== SurveyStatus.Completed && x.submission_status !== SurveyStatus.DocPending));
                    if (!impendingResponses) {
                        if (userLoanReview && userLoanReview.workout_option) {
                            let templateData: any = [];
                            allBorrowers.forEach((borrower: any) => {
                                templateData.push({ "email": params.email, "name": borrower.first_name, "option": userLoanReview.workout_option });
                            });
                            if (templateData.length > 0) {
                                let emailData = {
                                    templateName: templates.AssistanceOption,
                                    templateData: templateData,
                                    action: "SendEmail"
                                }
                                console.log("dsta:" ,templateData)
                                await postApi("api/Hoa/Hoa/HoaEmailService?templateName="+templates.AssistanceOption+"&action="+emailData.action+"&templateInput="+emailData.action,templateData, false, true)
                                // await API.graphql(graphqlOperation(sendEmailService, { data: JSON.stringify(emailData) }));
                            }
                        }
                        else if (userLoanReview && !userLoanReview.workout_option && userLoanReview.disclosure) {
                            console.log("borrower: ",allBorrowers)
                            let templateData: any = [];
                            allBorrowers.forEach((borrower: any) => {
                                templateData.push({ "email": params.email, "name": borrower.first_name });
                            });
                            if (templateData.length > 0) {
                                let emailData = {
                                    templateName: templates.RuleEngineDisclosure,
                                    templateData: templateData,
                                    action: "SendEmail"
                                }
                              await postApi("api/Hoa/Hoa/HoaEmailService?templateName="+templates.RuleEngineDisclosure+"&action="+emailData.action+"&templateInput="+emailData.action,templateData, false, true)
                                // await API.graphql(graphqlOperation(sendEmailService, { data: JSON.stringify(emailData) }));
                            }
                        }
                    }
                }
                if (allSubmissions && allBorrowers && allSubmissions.length > 0 && allBorrowers.length > 0 && allSubmissions.length === allBorrowers.length) {
                    let completedCount: number = 0;
                    allSubmissions.forEach(res => {
                        if (res.submission_status === SurveyStatus.Completed || res.submission_status === SurveyStatus.DocPending) {
                            completedCount++;
                        }
                    });
                    if (completedCount === allSubmissions.length) {
                        loanSurveyStatus = SurveyStatus.PendingReview;
                    }
                    else {
                        loanSurveyStatus = SurveyStatus.BorrowerPending;
                    }
                }
                else if (allSubmissions.length === 1 && userLoanReview && userLoanReview.workout_option) {
                    loanSurveyStatus = SurveyStatus.PendingReview;
                }
                else if (allSubmissions && allBorrowers && allSubmissions.length !== allBorrowers.length) {
                    loanSurveyStatus = SurveyStatus.BorrowerPending;
                }
                break;
            case SurveyStatus.AssistanceRequested:
            case SurveyStatus.FbExtn:
            case SurveyStatus.FbSetup:
                if (allSubmissions && allSubmissions.length > 0) {
                    loanSurveyStatus = SurveyStatus.PendingReview;
                }
                break;
            case SurveyStatus.BankruptcyExit:
                if (allSubmissions && allSubmissions.length > 0) {
                    loanSurveyStatus = params.userSurveyStatus;
                }
                break;
            case SurveyStatus.FinancialScreenExit:
            case SurveyStatus.PaymentScreenExit:
                if (allSubmissions && allSubmissions.length > 0) {
                    loanSurveyStatus = SurveyStatus.Initiated;
                }
                break;
            default:
                loanSurveyStatus = SurveyStatus.NotStarted;
        }
        if (loanSurveyStatus) {
            await setLoanSurveyStatus(loanId, servicerId, userId, loanSurveyStatus, userSurveyStatus);
        }

        return response;

    }
    catch (error) {
        console.log(JSON.stringify(error));
    }
}

export const setLoanSurveyStatus = async (loanId: string, servicerId: number, userName: string, loanSurveyStatus: string, userSurveyStatus: string) => {
    try {
        const filter = "loan_number='"+loanId+"' and servicer_id="+servicerId
        let loanReview: any =await getApi("api/Hoa/Hoa/getLoanReviewStatus?loan_number="+loanId+"&servicer_id="+servicerId)
        //  await API.graphql(graphqlOperation(getLoanReviewStatus, filter));
        let createdBy = "";
        let createdDate = "";
        let requestDate = "";
        let execution_id = "";
        if (loanReview && loanReview.data && loanReview.data[0]) {
            createdBy = loanReview.data[0].created_by;
            createdDate = loanReview.data[0].created_date;
            requestDate = loanReview.data[0].request_date;
            execution_id = loanReview.data[0].execution_id;
        }
        let data: any = {
            createdBy: createdBy !== "" ? createdBy : userName,
            createdDate: createdDate !== "" ? createdDate : new Date().toUTCString(),
            modifiedBy: userName,
            modifiedDate: new Date().toUTCString(),
            status: loanSurveyStatus,
            execution_id: execution_id
        }
        if (loanSurveyStatus === SurveyStatus.Initiated || (loanSurveyStatus === SurveyStatus.BankruptcyExit && !requestDate)) {
            data = {
                ...data,
                requestDate: new Date().toUTCString()
            }
        }
        let submitStatus: any;
        const createInput = {
            loan_number: loanId,
            servicer_id: servicerId,
            created_by: data.createdBy,
            created_date: data.createdDate,
            modified_by: data.modifiedBy,
            modified_date: data.modifiedDate,
            request_date: data.requestDate,
            status: data.status,
            workout_option: "",
            workout_description: "",
            rule_selection_criteria: "",
            disclosure: "",
            execution_id: data.execution_id
        }
        if (loanReview && (!loanReview.data || !loanReview.data[0])) {
            submitStatus = await postApi("api/Hoa/Hoa/createLoanReviewStatus",createInput, false, true)
            // await API.graphql(graphqlOperation(createLoanReviewStatus, {
            //     input: createInput
            // }));
            try {
                const updateLoanStatus: any = await postApi("api/Hoa/Hoa/KafkaDataExportToVertica?loanNumber="+loanId, false, true)
                // await API.graphql(graphqlOperation(updateLoanStatusInVertica,
                //     {
                //         input: {
                //             loan_number: loanId
                //         }
                //     }));
                console.log(updateLoanStatus)
            }
            catch (ex) {
                console.log(ex)
            }
        }
        else {
            if (loanSurveyStatus === SurveyStatus.PendingReview && (userSurveyStatus === SurveyStatus.FbExtn || userSurveyStatus === SurveyStatus.FbSetup)) {
              let  input= {
                            loan_number: loanReview.data[0].loan_number,
                            servicer_id: loanReview.data[0].servicer_id,
                            workout_option: workoutOptions.Forbearance,
                            workout_description: workoutDescriptions.Forbearance,
                            rule_selection_criteria: ruleSelectionCriteria.Forbearance,
                            disclosure: "",
                            modified_by: userName,
                            modified_date: new Date().toUTCString(),
                            status: data.status,
                            execution_id: data.execution_id
                        }
                submitStatus = await postApi("api/Hoa/Hoa/updateLoanReviewStatus",input, false, true)
                // await API.graphql(graphqlOperation(updateLoanReviewStatus, {
                //     input: {
                //         loan_number: loanReview.data[0].loan_number,
                //         servicer_id: loanReview.data[0].servicer_id,
                //         workout_option: workoutOptions.Forbearance,
                //         workout_description: workoutDescriptions.Forbearance,
                //         rule_selection_criteria: ruleSelectionCriteria.Forbearance,
                //         disclosure: "",
                //         modified_by: userName,
                //         modified_date: new Date().toUTCString(),
                //         status: data.status,
                //         execution_id: data.execution_id
                //     }
                // }));
            }
            else {
                let input={
                        loan_number: loanReview.data[0].loan_number,
                        servicer_id: loanReview.data[0].servicer_id,
                        workout_option: loanReview.data[0].workout_option,
                        workout_description: loanReview.data[0].workout_description,
                        rule_selection_criteria: loanReview.data[0].rule_selection_criteria,
                        disclosure: loanReview.data[0].disclosure,
                        modified_by: userName,
                        modified_date: new Date().toUTCString(),
                        status: data.status,
                        execution_id: data.execution_id
               
                }
               submitStatus = await postApi("api/Hoa/Hoa/updateLoanReviewStatus",input, false, true)
                // await API.graphql(graphqlOperation(updateLoanReviewStatus, {
                //     input: {
                //         loan_number: loanReview.data[0].loan_number,
                //         servicer_id: loanReview.data[0].servicer_id,
                //         workout_option: loanReview.data[0].workout_option,
                //         workout_description: loanReview.data[0].workout_description,
                //         rule_selection_criteria: loanReview.data[0].rule_selection_criteria,
                //         disclosure: loanReview.data[0].disclosure,
                //         modified_by: userName,
                //         modified_date: new Date().toUTCString(),
                //         status: data.status,
                //         execution_id: data.execution_id
                //     }
                // }));
            }
            try {
                const updateLoanStatus: any = await postApi("api/Hoa/Hoa/KafkaDataExportToVertica?loanNumber="+loanReview.data[0].loan_number, false, true)
                // await API.graphql(graphqlOperation(updateLoanStatusInVertica,
                //     {
                //         input: {
                //             loan_number: loanReview.data[0].loan_number
                //         }
                //     }));
                console.log(updateLoanStatus)
            }
            catch (ex) {
                console.log(ex)
            }
        }

        return submitStatus;
    }
    catch (e) {
        console.log(e);
    }
}
