import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { Status } from '../../constants/status';
import { liveGageTheme } from '../../constants/theme';
import { createUserForLbp, getUserDetails } from '../../services/userService';
import { AppState } from '../stores/appStore';

const initialState: AppState = {
    currentUser: undefined,
    isLoggedIn: false,
    errorMessage: "",
    clientId: "",
    status: Status.Idle,
    theme: liveGageTheme,
    loanData: undefined,
    userDetailFetched: false,
    CurrentUserEmail: undefined
};

export const getUserDetailsAsync = createAsyncThunk(
    'app/getUserDetails',
    async (param: any) => {
        const response = await getUserDetails(param);
        return response;
    }
);

export const createUserForLbpAsync = createAsyncThunk(
    'app/createUserForLbp',
    async (param: any) => {
        const response = await createUserForLbp(param);
        return response;
    }
);

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        logout: (state: AppState) => {
            console.log('logout...hoa')
            state.currentUser = undefined;
            state.isLoggedIn = false;
            state.theme = liveGageTheme;
        },
        setTheme: (state: AppState, action: PayloadAction<any>) => {
            state.theme = action.payload;
        },
        setUser: (state: AppState, action: PayloadAction<any>) => {
            state.currentUser = action.payload;
        },
        setIsloggedIn: (state: AppState, action: PayloadAction<any>) => {
            state.isLoggedIn = action.payload
        },
        setUserDetailFetchedFalse : (state: AppState, action: PayloadAction<any>) => {
            state.userDetailFetched = action.payload;
        },
        setEmail: (state: AppState, action: PayloadAction<any>) => {
            state.CurrentUserEmail = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserDetailsAsync.pending, (state: AppState) => {
                state.status = Status.Loading;
                state.userDetailFetched = false;
            })
            .addCase(getUserDetailsAsync.fulfilled, (state: AppState, action: PayloadAction<any>) => {
                state.status = Status.Idle;
                if (action.payload.theme) {
                    state.theme = action.payload.theme;
                }
                state.loanData = action.payload.loanDetails;
                state.isLoggedIn = true;
                if (action.payload.userDetails) {
                    state.userData = action.payload.userDetails;
                    console.log('userData...hoa',state.userData)
                    state.currentUser = action.payload.userDetails.user_name;
                }
                state.userDetailFetched = true;
            })
            .addCase(getUserDetailsAsync.rejected, (state: AppState) => {
                state.status = Status.Idle;
            })
    }
});

export const { logout, setUser, setTheme, setIsloggedIn, setUserDetailFetchedFalse,setEmail } = appSlice.actions;

export const readAppState = (state: RootState) => state.app;

export default appSlice.reducer;