import { API, graphqlOperation } from "aws-amplify";
// import * as jwt from 'jsonwebtoken';
import { getServicer, getLoanMaster, getBorrowers, listUsers, getBorrowersData, listUsersData } from '../graphql/queries';
import { createUsers, createUsersData } from '../graphql/mutations';
import { getApi, postApi } from "../api/base";

export const getPaymentToken = async (param) => {
    // return { paymentToken: jwt.sign({ "loanNumber": "3000000420", "userName": "jdoe", "paymentType": param.paymentType, "outstanding_debt": param.outstanding_debt }, 'lgpaymentsecret') }
}

export const getUserDetails = async (param) => {
    try {
        const res = await getApi("api/Hoa/Hoa/getBorrowersData?user_name="+param.userId)
        console.log("data: ",res?.data[0])
        if (res && res?.data && res?.data[0]) {
            const loanDetails = await getApi("api/Hoa/Hoa/getLoanMaster?loan_id="+res?.data[0]?.loan_number)
            console.log("dataa: ",loanDetails?.data)
                    // const loanDetails: any = await API.graphql(graphqlOperation(getLoanMaster, { loan_id: borrowerResponse.data.getBorrowersData.loan_number, servicer_id: "1" }));
                    const servicerResponse: any =await getApi("api/Hoa/Hoa/getServicer?servicer_id=1")
                    console.log("dataa: ",servicerResponse?.data[0].theme.theme)
                    // await API.graphql(graphqlOperation(getServicer, { servicer_id: "1" }));
                    const response = {
                        loanDetails: loanDetails?.data[0],
                        theme: servicerResponse.data[0]?.theme.theme || {},
                        userDetails: res?.data[0]
                    }
                    return response;
                }
       
      } catch (error) {
        console.log("error from service ", error);
      }
    // try {
    //     const borrowerResponse: any = await API.graphql(graphqlOperation(getBorrowersData, { user_name: param.userId }));
    //     if (borrowerResponse && borrowerResponse.data && borrowerResponse.data.getBorrowersData) {
    //         const loanDetails: any = await API.graphql(graphqlOperation(getLoanMaster, { loan_id: borrowerResponse.data.getBorrowersData.loan_number, servicer_id: "1" }));
    //         const servicerResponse: any = await API.graphql(graphqlOperation(getServicer, { servicer_id: "1" }));
    //         const response = {
    //             loanDetails: loanDetails.data.getLoanMaster,
    //             theme: servicerResponse.data.getServicer.theme ? JSON.parse(JSON.parse(servicerResponse.data.getServicer.theme)).theme : {},
    //             userDetails: borrowerResponse.data.getBorrowersData
    //         }
    //         return response;
    //     }
    // }
    // catch (e: any) {
    //     console.log(e);
    // }
}

export const createUserForLbp = async (param) => {
    try {
        const listUserResponse: any = await getApi("api/Hoa/Hoa/listUsersData?username="+param.username);
        // await API.graphql(graphqlOperation(listUsersData,
        //     {
        //         filter: { field: "user_name", operator: "=", value: param.username, operation: "" }
        //     }
        // ));
        let users = listUserResponse?.data;

        if (!users ) {
            let data={
                                loan_number: param.loan_id,
                                user_name: param.username,
                                password: "",
                                servicer_id: param.servicer_id
                    
            }
            const createUserResponse: any = await postApi("api/Hoa/Hoa/createUsersData",data, false, true)
            // await API.graphql(graphqlOperation(createUsersData,
            //     {
            //         input: {
            //             loan_number: param.loan_id,
            //             user_name: param.username,
            //             password: "",
            //             servicer_id: param.servicer_id,
            //             // borrower_id: param.borrower_id
            //         }
            //     }
            // ));
            console.log(createUserResponse.data);
            return createUserResponse.data;
        }
        return users[0];
    }
    catch (ex) {
        console.log(ex);
        throw ex;
    }
}