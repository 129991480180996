import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { composeMessage, ReplyToMessage, getMessage, readInboxMessage } from '../../services/messageCenterService';
import { Status } from '../../constants/status';
import { MessageApiResponse, MessageState } from '../stores/messageStore';
import { MessageTabs } from '../../constants/messageConstants';
import { Storage } from "aws-amplify";

const initialState: MessageState = {
  messageList: [],
  status: Status.Idle,
  errorMessage: "",
  activeMessageTab: MessageTabs.Inbox,
};

export const composeMessageAsync = createAsyncThunk(
  'messages/composeMessage',
  async (data: any) => {
    let response = await composeMessage(data);
    return response;
  }
);

export const uploadDocumentsMessageCenterAsync = createAsyncThunk(
  'messages/uploadDocuments',
  async (data: any) => {
    let fileUploadResponse: any[] = [];
    if (data.uploadFile.length) {
      for (const file of data.uploadFile) {
        let fileCopy = { ...file };
        const fileBlob = await fetch(file.accessUrl).then(x => x.blob());
        const result = await Storage.put(`${data.loanId}/messageCenter/${file.fileName}`, fileBlob, { contentType: file.fileType });
        const url = await Storage.get(result.key);
        if (url) {
          fileCopy.accessUrl = url;
          fileUploadResponse.push(fileCopy);
        }

      }
      return { ...data, files: fileUploadResponse };
    }
  }
)

export const replyToMessageAsync = createAsyncThunk(
  'messages/reply',
  async (data: any) => {
    let response = await ReplyToMessage(data);
    return response;
  }
);

export const readInboxMessageAsync = createAsyncThunk(
  'messages/readMessage',
  async (data: any) => {
    let response = await readInboxMessage(data);
    return response;
  }
);

export const getMessageAsync = createAsyncThunk(
  'messages/getMessage',
  async (data: any) => {
    let response = await getMessage(data);
    return response;
  }
);

export const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    changeMessageTab: (state: MessageState, action: PayloadAction<any>) => {
      state.activeMessageTab = action.payload;
    },

  },

  extraReducers: (builder) => {
    builder
      .addCase(composeMessageAsync.pending, (state) => {
        state.status = Status.Loading;
      })
      .addCase(composeMessageAsync.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = Status.Idle;
      })
      .addCase(composeMessageAsync.rejected, (state, action) => {
        state.status = Status.Idle;
        state.errorMessage = "An Error Occured While sending Message.";
      })
      .addCase(replyToMessageAsync.pending, (state) => {
        state.status = Status.Loading;
      })
      .addCase(replyToMessageAsync.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = Status.Idle;
      })
      .addCase(replyToMessageAsync.rejected, (state, action) => {
        state.status = Status.Idle;
        state.errorMessage = "An Error Occured While sending Message.";
      })
      .addCase(readInboxMessageAsync.pending, (state) => {
        state.status = Status.Loading;
      })
      .addCase(readInboxMessageAsync.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = Status.Idle;
        if (action.payload) {
          state.messageList = state.messageList.map((value, index) => {
            if (value.message_id === action.payload.messageId && value.is_admin === true) {
              value.read_flag = true;
            }
            return value;

          })
        }
      })
      .addCase(readInboxMessageAsync.rejected, (state, action) => {
        state.status = Status.Idle;
        state.errorMessage = "An Error Occured While sending Message.";
      })

      .addCase(getMessageAsync.pending, (state) => {
        state.status = Status.Loading;
      })
      .addCase(getMessageAsync.fulfilled, (state, action: PayloadAction<any[]>) => {
        state.status = Status.Idle;
        if (action.payload) {
          state.messageList = action.payload;
        }
      })
      .addCase(getMessageAsync.rejected, (state, action) => {
        state.status = Status.Idle;
        state.errorMessage = "An Error Occured While Getting Messages.";
      })
      .addCase(uploadDocumentsMessageCenterAsync.pending, (state) => {
        state.status = Status.Loading;
      })
      .addCase(uploadDocumentsMessageCenterAsync.fulfilled, (state, action: PayloadAction<string>) => {
        state.status = Status.Idle;
      })
      .addCase(uploadDocumentsMessageCenterAsync.rejected, (state, action) => {
        state.status = Status.Idle;
        state.errorMessage = "An Error Occured While sending Message.";
      })

  },
});

export const {
  changeMessageTab

} = messageSlice.actions;

export const readMessageState = (state: RootState) => state.message;

export default messageSlice.reducer;