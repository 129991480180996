import { memo, useState } from "react";
import {Auth} from "aws-amplify";
import { useNavigate } from "react-router";




const ForgotPassword = () =>{
    const history = useNavigate();
    const [email,setEmail]=useState("");

    const forgotPasswordHandler = async (event) => {
        event.preventDefault();
    
        // AWS Cognito integration here
        // this will send email to changepassword
        try {
          await Auth.forgotPassword(email);
          history('/forgotpasswordverification');
    
        } catch (error) {
          console.log(error);
        }
      }


    return(
        <section className="section auth">
        <div className="container">
          <h1>Forgot your password?</h1>
          <p>
            Please enter the email address associated with your account and we'll
            email you a password reset link.
          </p>
         
          <form onSubmit={forgotPasswordHandler}>
            <div className="field">
              <p className="control has-icons-left has-icons-right">
                <input
                  type="email"
                  className="input"
                  id="email"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e)=>setEmail(e.target.value)}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-envelope"></i>
                </span>
              </p>
            </div>
        
            <div className="field">
              <p className="control">
                <button className="button is-success">
                  Send
                </button>
              </p>
            </div>
          </form>
        </div>
      </section>
    )

}

export default memo(ForgotPassword);