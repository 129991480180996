import { ShareServiceClient, FileCreateOptions, ShareDirectoryClient } from "@azure/storage-file-share";

const connectionString = "BlobEndpoint=https://hoatesting.blob.core.windows.net/;QueueEndpoint=https://hoatesting.queue.core.windows.net/;FileEndpoint=https://hoatesting.file.core.windows.net/;TableEndpoint=https://hoatesting.table.core.windows.net/;SharedAccessSignature=sv=2020-08-04&ss=bfqt&srt=sco&sp=rwdlacuptfx&se=2022-01-28T22:35:57Z&st=2021-09-02T14:35:57Z&spr=https&sig=FqY65cHx3gN%2FnorABxucT4FsusJYdy59JOKP4dxhrNI%3D";
const shareServiceClient = ShareServiceClient.fromConnectionString(connectionString);

export const uploadFiles = async (fileContent: any, fileName: string, fileType: string, loanId: string, servicer: string) => {
    try {
        const servicerDirectoryClient = await getDirectoryClient(servicer, "fshoatesting");
        if (servicerDirectoryClient) {
            const directoryClient = await getDirectoryClient(loanId, undefined, servicerDirectoryClient);
            if (directoryClient) {
                const fileClient = directoryClient.getFileClient(fileName);
                const fileHttpHeader: FileCreateOptions = {
                    fileHttpHeaders: {
                        fileContentType: fileType
                    }
                };
                await fileClient.create(fileContent.size, fileHttpHeader);
                console.log(`Create file ${fileName} successfully`);

                await fileClient.uploadRange(fileContent, 0, fileContent.size,);
                return fileClient.url;
            }
        }
    }
    catch (e) {
        console.log(e);
    }
}


export const uploadFilesForMessageCenter = async (fileContent: any, fileName: string, fileType: string, loanId: string, servicer: string) => {
    try {
        const servicerDirectoryClient = await getDirectoryClient(servicer, "fshoatesting");
        if (servicerDirectoryClient) {
            const directoryClient = await getDirectoryClient(loanId, undefined, servicerDirectoryClient);
            if (directoryClient) {
                const dirClient = await getDirectoryClient("messagecenter", undefined, directoryClient);
                if(dirClient){
                const fileClient = dirClient.getFileClient(fileName);
                const fileHttpHeader: FileCreateOptions = {
                    fileHttpHeaders: {
                        fileContentType: fileType
                    }
                };
                await fileClient.create(fileContent.size, fileHttpHeader);
                console.log(`Create file ${fileName} successfully`);

                // Upload file range
                await fileClient.uploadRange(fileContent, 0, fileContent.size,);
                return fileClient.url;
            }
            }
        }
    }
    catch (e) {
        console.log(e);
    }
}
export const getDirectoryClient = async (directoryName: string, shareClientName?: string, parentDirectoryClient?: ShareDirectoryClient) => {
    try {
        if (parentDirectoryClient) {
            const directoryClient = parentDirectoryClient.getDirectoryClient(directoryName);
            await directoryClient.createIfNotExists();
            return directoryClient;
        }
        else if (shareClientName) {
            const shareClient = shareServiceClient.getShareClient(shareClientName)
            if (shareClient) {
                const directoryClient = shareClient.getDirectoryClient(directoryName);
                await directoryClient.createIfNotExists();
                return directoryClient;
            }
        }
    }
    catch (e: any) {
        console.log(e);
    }
}

export const getFilesList = async (folderName: string, shareName: string) => {
    const filesList: string[] = [];
    try {
        const shareClient = shareServiceClient.getShareClient(shareName);
        if (shareClient) {
            const directoryClient = shareClient.getDirectoryClient(folderName);
            if (directoryClient) {
                const dirIter = directoryClient.listFilesAndDirectories();
                let i = 1;
                for await (const item of dirIter) {
                    if (item.kind === "file") {
                        filesList.push(item.name);
                    }
                }
                i++;
            }
        }
    }
    catch (e: any) {
        console.log(e);
    }
    return filesList;
}

export const readFile = async (fileName: string, folderName: string, parentFolder: string) => {
    const shareClient = shareServiceClient.getShareClient("fshoatesting");
    if (shareClient) {
        const parentDirectoryClient = shareClient.getDirectoryClient(parentFolder);
        if (parentDirectoryClient) {
            const directoryClient = shareClient.getDirectoryClient(`${parentFolder}/${folderName}`);
            if (directoryClient) {
                const fileClient = directoryClient.getFileClient(fileName);
                if (fileClient) {
                    const downloadResponse = await fileClient.download();
                    let response = { blob: await downloadResponse.blobBody, type: downloadResponse.contentType }
                    return response;
                }
            }
        }
    }
    return null;
}

export const deleteFile = async (fileName: string, folderName: string, parentFolder: string) => {
    try {
        const shareClient = shareServiceClient.getShareClient("fshoatesting");
        if (shareClient) {
            const parentDirectoryClient = shareClient.getDirectoryClient(parentFolder);
            if (parentDirectoryClient) {
                const directoryClient = shareClient.getDirectoryClient(`${parentFolder}/${folderName}`);
                if (directoryClient) {
                    const fileClient = await directoryClient.deleteFile(fileName);
                    return (`File deleted successfully`);
                }
            }
        }
        return null;
    }
    catch (ex) {
        console.log(ex);
    }

}