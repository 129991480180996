import { createUserDocuments, deleteUserDocumentsBySubmissionId } from './../graphql/mutations';
import { API, graphqlOperation } from "aws-amplify";
import { updateUserDocuments } from "../graphql/mutations";
import { getUserDocuments, listUserDocuments, getUserSubmission } from "../graphql/queries";
import { UploadedDocument } from '../redux/stores/questionStore';
import { getApi, postApi } from '../api/base';

export const submitFilesUploaded = async (data: any) => {
    try {
        const existingUserSubmission: any = await getApi("api/Hoa/Hoa/getUserSubmission?loan_number="+ data.loan_number+"&user_name="+data.user_name+"&servicer_id="+data.servicer_id)
        // await API.graphql(graphqlOperation(getUserSubmission, { user_name: data.user_name, loan_number: data.loan_number, servicer_id: data.servicer_id }));
        if (existingUserSubmission && existingUserSubmission.data && existingUserSubmission?.data[0]) {
            const submissionId = existingUserSubmission.data[0].user_submission_id;
            const option = data.uploadData.option;
            let column= "user_submission_id='"+submissionId+"' and option= '"+option+"' ";
            const previousDataResponse: any = await getApi("api/Hoa/Hoa/getUserDocuments?column="+column)
            // await API.graphql(graphqlOperation(getUserDocuments, { user_submission_id: submissionId, option: option }));
            const previousData = previousDataResponse.data && previousDataResponse?.data[0];
            let updatedCategory: any[] = [];

            if (previousData) {
                let categoryAlreadyExists = false;
                updatedCategory = JSON.parse(previousData.category).category.map(element => {
                    if (element.categoryName === data.uploadData.categoryName) {
                        categoryAlreadyExists = true;
                        const updatedFiles: { fileName: string, fileType: string, accessUrl: string }[] = data.uploadData.files.map((file: any) => {
                            return { fileName: file.fileName, fileType: file.fileType, accessUrl: file.accessUrl }
                        })
                        element.files = [...updatedFiles];
                    }
                    return element;
                })
                if (!categoryAlreadyExists) {
                    updatedCategory.push({ categoryName: data.uploadData.categoryName, files: data.uploadData.files });
                }
                const categoriesCopy = JSON.stringify({ category: updatedCategory })
                // .replaceAll(/"/g, '\\"');
                let input= {
                    user_submission_id: submissionId.toString(),
                    option: option,
                    category: categoriesCopy
                }
                const submitStatusResponse: any = await postApi("api/Hoa/Hoa/updateUserDocuments",input, false, true);
                // await API.graphql(graphqlOperation(updateUserDocuments, {
                //     input: {
                //         user_submission_id: submissionId,
                //         option: option,
                //         category: categoriesCopy
                //     }
                // }));
                return submitStatusResponse;
            }
            else {
                updatedCategory = [{ categoryName: data.uploadData.categoryName, files: data.uploadData.files }];
                updatedCategory = updatedCategory.filter(x => x.files.length > 0);
                let input= {
                    user_submission_id : submissionId.toString(),
                    option: option,
                    category: JSON.stringify({ category: updatedCategory })
                    // .replaceAll(/"/g, '\\"')
                }
                const submitStatusResponse: any =await postApi("api/Hoa/Hoa/createUserDocuments",input, false, true);
                //  await API.graphql(graphqlOperation(createUserDocuments, {
                //     input: {
                //         user_submission_id: submissionId,
                //         option: option,
                //         category: JSON.stringify({ category: updatedCategory }).replaceAll(/"/g, '\\"')
                //     }
                // }));
                return submitStatusResponse;
            }
        }
    }
    catch (e) {
        console.log(e);
    }
}

export const getUploadedFiles = async (data: any) => {
    try {
        const existingUserSubmission: any = await getApi("api/Hoa/Hoa/getUserSubmission?user_name="+data.user_name+"&loan_number="+data.loan_number+"&servicer_id="+data.servicer_id)
        // await API.graphql(graphqlOperation(getUserSubmission, { user_name: data.user_name, loan_number: data.loan_number, servicer_id: data.servicer_id }));
        if (existingUserSubmission && existingUserSubmission.data && existingUserSubmission.data[0]) {
            let column="user_submission_id='"+existingUserSubmission.data[0].user_submission_id+"' ";
            const list: any = await getApi("api/Hoa/Hoa/getUserDocuments?column="+column)
            // await API.graphql(graphqlOperation(listUserDocuments, { user_submission_id: existingUserSubmission.data.getUserSubmission.user_submission_id }));
            if (list && list.data && list.data) {
                const userDocumentsList = list.data.map(x => {
                    x.category = JSON.parse(x.category).category;
                    return x;
                });
                return userDocumentsList as UploadedDocument[];
            }
        }
        return {};
    }
    catch (e) {
        console.log(e);
        return {};
    }
}

export const deleteDocuments = async (data: any) => {
    try {
        const existingUserSubmission: any = await getApi("api/Hoa/Hoa/getUserSubmission?user_name="+data.user_name+"&loan_number="+data.loan_number+"&servicer_id="+data.servicer_id);
        // await API.graphql(graphqlOperation(getUserSubmission, { user_name: data.user_name, loan_number: data.loan_number, servicer_id: data.servicer_id }));
        if (existingUserSubmission && existingUserSubmission.data && existingUserSubmission.data[0]) {
            const response: any = await postApi("api/Hoa/Hoa/deleteUserDocumentsBySubmissionId?user_submission_id="+existingUserSubmission.data[0].user_submission_id, false,true);
            // await API.graphql(graphqlOperation(deleteUserDocumentsBySubmissionId, { input: { user_submission_id: existingUserSubmission.data[0].user_submission_id } }));
            return response;
        }
    }
    catch (e) {
        console.log(e);
    }

}