import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import appReducer from '../redux/reducers/appReducer';
import questionReducer from '../redux/reducers/questionReducer';
import messageReducer from '../redux/reducers/messageReducer';
import finicityReducer from '../redux/reducers/finicityReducer'; 
import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session'
import { combineReducers } from 'redux';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

const persistConfig = {
  key: 'counter',
  storage:storageSession,
};

const reducers = combineReducers({
  app: appReducer,
  question: questionReducer,
  finicity : finicityReducer,
  message:messageReducer
});

const persistedReducer = persistReducer(persistConfig, reducers)

export const store =  configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
