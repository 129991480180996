import { memo, useState } from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router";

const ConfirmRegistration = () => {

    const [username, setUserName] = useState("");
    const [code, setCode] = useState("");
    const history = useNavigate();

    const handleSubmit = async () => {
        try {
          const res =    await Auth.confirmSignUp(username, code);
    
        } catch (error) {
            console.log('error confirming sign up', error);
        }
       
    }

    return (
        <form onSubmit={handleSubmit}>
            <input
                className="input"
                type="text"
                id="username"

                placeholder="Enter username"
                value={username}
                onChange={(event) => setUserName(event.target.value)}
            />
            <input
                className="input"
                type="text"
                id="otp"

                placeholder="Enter OTP"
                value={code}
                onChange={(event) => setCode(event.target.value)}
            />
            <div className="field">
                        <p className="control">
                            <button className="button is-success">
                                Confirm
                            </button>
                        </p>

                    </div>

        </form>

    )

}

export default memo(ConfirmRegistration);