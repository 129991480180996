import Welcome from "./Welcome";
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { createUserForLbpAsync, getUserDetailsAsync, readAppState } from "../../redux/reducers/appReducer";
// import * as jwt from "jsonwebtoken";
import jwt_decode from "jwt-decode";

const LBPWelcome = (props : any) => {
    const dispatch = useAppDispatch();
    const appState = useAppSelector(readAppState);
    console.log('appState.userDetailFetched',appState.userDetailFetched)
    // console.log('jenkins pipeline testing for dev')

    useEffect(() => {
        let lbptoken = props.lbpToken;
        if (lbptoken && !appState.userDetailFetched) {
           // let decodedData: any = jwt.decode(lbptoken);
           let  decodedData: any = jwt_decode(lbptoken);
            if (decodedData.username) {
                let params = {
                    loan_id: decodedData.loan_id,
                    username: decodedData.username,
                    servicer_id: decodedData.servicer_id,
                    // borrower_id: decodedData.borrower_id
                };
                dispatch(createUserForLbpAsync(params)).then(() => {
                    const data = { userId: decodedData.username, password: null }
                    console.log('data..hoa',data)
                    dispatch(getUserDetailsAsync(data));
                })
            }
        }
    }, []);

    return (<>
        {!appState.userDetailFetched && <Welcome />}         
    </>);
}

export default LBPWelcome;