import React from "react";
import { useState } from "react";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";

export const PopoverComponent = (props: any) => {

    const [isOpen, toggle] = useState(false);

    const renderMessage = () => {
        const message = props.message.split("\\n").map(line => {
            return (
                <React.Fragment>
                    {line}
                    <br />
                </React.Fragment>);
        })
        return message;
    }

    return (<>
        <div className="btn-group-toggle mb20" data-toggle="buttons">
            {!props.isSpan && <button className="btn blue-outline-btn text-center educational_links btn-block" id={props.id} onClick={() => toggle(!isOpen)}>{props.header}</button>
            }
            {props.isSpan && <span id={props.id} className={"clickable"} onClick={() => toggle(!isOpen)}>{props.text}</span>}
        </div>

        <Popover trigger="legacy" placement="bottom" isOpen={isOpen} target={props.id} toggle={() => toggle(!isOpen)}>
            <PopoverHeader>{props.header}<span className="fas fa-close tooltip_close" onClick={() => toggle(!isOpen)} /></PopoverHeader>
            <PopoverBody>{renderMessage()}</PopoverBody>
        </Popover>
    </>
    );
}