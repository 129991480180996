export const SurveyStatus = {
    NotStarted: "Not Started",
    Initiated: "Request Initiated",
    BorrowerPending: "Co-borrower Income and Asset Verfication Pending",
    DocPending: "Pending Servicer Review/ Documents Pending",
    Completed: "Completed",
    PendingReview: "Pending Servicer Review",
    FbExtn: "Requested Forbearance Extension/ Pending Servicer Review",
    FbSetup: "Requested Forbearance/ Pending Servicer Review",
    BankruptcyExit: "Bankruptcy Identified",
    AssistanceRequested: "Assistance Requested/ Pending Servicer Review",
    FinancialScreenExit: "Financial Screen Exit/ Request Initiated",
    PaymentScreenExit: "Payment Screen Exit/ Request Initiated",
    ServicerFollowUpRequired: "Servicer Follow Up Required",
    RequestClosedByServicer: "Request Closed by Servicer",
    ClosedWorkoutApprovedAndAccepted: "Closed–Workout Approved and Accepted",
    ClosedCustomerDidNotQualify: "Closed–Customer did not qualify",
    ClosedCustomerCancelledRequest: "Closed–Customer Cancelled Request",
    ClosedCustomerDeclined: "Closed–Customer Declined",
    ClosedOther: "Closed–Other"
}