import { memo, useState } from "react"
import {Auth} from "aws-amplify";

import { useNavigate } from "react-router";

const Registration = () => {

    const [username,setUserName] = useState("");
    const [email,setEmail] = useState("");
    const [password,setPassword] = useState("");
    const [confirmpassword,setConfirmPassword] = useState("");
    const history = useNavigate();
   

   const  handleSubmit = async event => {
        event.preventDefault();
        history("/confirmRegistration")
    
        try {
          const signUpResponse = await Auth.signUp({
            username, 
            password,
            attributes:{
              email: email
            }
          });
          console.log(signUpResponse);
     
        } catch (error) {
          let err = null;
          console.log(err);
        
          
        }
      };
    
  




    return (

        <section className="section auth">
            <div className="container">
                <h1>Register</h1>
               

                <form onSubmit={handleSubmit}>
                    <div className="field">
                        <p className="control">
                            <input
                                className="input"
                                type="text"
                                id="username"

                                placeholder="Enter username"
                                value={username}
                                onChange={(event) => setUserName(event.target.value)}
                            />
                        </p>
                    </div>
                    <div className="field">
                        <p className="control has-icons-left has-icons-right">
                            <input
                                className="input"
                                type="email"
                                id="email"
                                aria-describedby="emailHelp"
                                placeholder="Enter email"
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                            />
                            <span className="icon is-small is-left">
                                <i className="fas fa-envelope"></i>
                            </span>
                        </p>
                    </div>
                    <div className="field">
                        <p className="control has-icons-left">
                            <input
                                className="input"
                                type="password"
                                id="password"
                                placeholder="Password"
                                value={password}
                                onChange={(event) => setPassword(event.target.value)}
                            />
                            <span className="icon is-small is-left">
                                <i className="fas fa-lock"></i>
                            </span>
                        </p>
                    </div>
                    <div className="field">
                        <p className="control has-icons-left">
                            <input
                                className="input"
                                type="password"
                                id="confirmpassword"
                                placeholder="Confirm password"
                                value={confirmpassword}
                                onChange={(event) => setConfirmPassword(event.target.value)}
                            />
                            <span className="icon is-small is-left">
                                <i className="fas fa-lock"></i>
                            </span>
                        </p>
                    </div>
                    <div className="field">
                        <p className="control">
                            <a href="/forgotpassword">Forgot password?</a>
                        </p>
                    </div>
                    <div className="field">
                        <p className="control">
                            <button className="button is-success">
                                Register
                            </button>
                        </p>

                    </div>
                </form>
            </div>
        </section>
    )

}
export default memo(Registration);