import { listBorrowersDataByLoanId, listUserResponsesBySubmissionId } from './../graphql/queries';
import { API, graphqlOperation } from "aws-amplify";
import { SurveyStatus } from "../constants/surveyStatus";
import { execRuleEngine, getUserResponse, listBorrowersByLoanId, listUserSubmissionsByLoanId, sendEmailService } from "../graphql/queries";
import { submitLoanReview } from "./loanReviewService";
import { getApi, postApi } from '../api/base';
export const getSuggestions = async (data: any) => {
    try {
        const userId = data.userId;
        const loanId = data.loanId;
        const servicerId = Number(data.servicerId);
        let primaryBorrower: any = null;
        let response: any = null;
        let primaryBorrowerUserId: any = null;
        let allBorrowers: any = await getApi("api/Hoa/Hoa/listBorrowersDataByLoanId?loan_number="+loanId)
        // await API.graphql(graphqlOperation(listBorrowersDataByLoanId, { loan_number: loanId}));

        if (allBorrowers && allBorrowers.data  && allBorrowers.data.length > 0) {
            allBorrowers = allBorrowers.data;
            // primaryBorrower = allBorrowers.find((x: any) => x.is_primary_borrower);
            // primaryBorrowerUserId = primaryBorrower.user_name;
        }
        let column="loan_number='"+loanId+"'";
        let allSubmissions: any = await getApi("api/Hoa/Hoa/listUserSubmissionsByLoanId?column="+column)
        // await API.graphql(graphqlOperation(listUserSubmissionsByLoanId, { loan_number: loanId }));
        if (allSubmissions && allSubmissions.data && allSubmissions.data.length > 0) {
            allSubmissions = allSubmissions.data;
            //response = allSubmissions.find((x: any) => x.user_name === primaryBorrower.user_name && x.servicer_id === data.servicerId);
            response = allSubmissions.find(x => x.is_primary_borrower);
            primaryBorrowerUserId = response.user_name;
        }

        let surveyCompleted = true;
        let completeCount = 0;
        if (allSubmissions.length === allBorrowers.length) {
            allSubmissions.forEach((x) => {
                if (x.user_name !== userId) {
                    if (!x.submission_status || (x.submission_status !== SurveyStatus.DocPending && x.submission_status !== SurveyStatus.Completed)) {
                        surveyCompleted = false;
                    }
                    if (x.submission_status === SurveyStatus.Completed) {
                        completeCount++;
                    }
                }
            })
        }
        else {
            surveyCompleted = false;
        }

        // let userResponse: any;
        // let dmnId: any;
        // if (response) {
        //     userResponse = await API.graphql(graphqlOperation(listUserResponsesBySubmissionId, { user_submission_id: response.user_submission_id }));
        //     if (userResponse && userResponse.data && userResponse.data.listUserResponsesBySubmissionId) {
        //         const lastUserResponse = userResponse.data.listUserResponsesBySubmissionId.sort((x, y) => y.response_index - x.response_index)[0];
        //         if (lastUserResponse && lastUserResponse.answer) {
        //             const checkBoxResponses = JSON.parse(lastUserResponse.answer).checkBoxResponses;
        //             if (checkBoxResponses) {
        //                 dmnId = checkBoxResponses[0].dmnId;
        //             }
        //         }
        //     }
        // }

        if ((surveyCompleted && response && primaryBorrowerUserId && 
            response.submission_status !== SurveyStatus.FbExtn && 
            response.submission_status !== SurveyStatus.FbSetup &&
            response.submission_status !== SurveyStatus.BankruptcyExit)) {
            let ruleEngineResponse: any = undefined;
            let result: any;
            const stringifiedRuleEngineResponse: any = await postApi("api/Hoa/Hoa/RuleExecution?loanId="+loanId+"&userId="+primaryBorrowerUserId+"&servicerId="+servicerId, false, true)
            // await API.graphql(graphqlOperation(execRuleEngine, { userId: primaryBorrowerUserId, loanId: loanId, servicerId: servicerId }));
            console.log("stringifiedRuleEngineResponse.data: ",stringifiedRuleEngineResponse.data)
            if (stringifiedRuleEngineResponse.data && stringifiedRuleEngineResponse.data) {
                ruleEngineResponse = JSON.parse(stringifiedRuleEngineResponse.data);
                if(ruleEngineResponse && ruleEngineResponse.statusCode === 200){
                    result = JSON.parse(ruleEngineResponse.body);
                }
                else {
                    result = { disclosure: "Thank you for inquiring about Homeowner’s Assistance Options! \\nYour information is being reviewed further and a representative will contact you within the next 24 hours to help you explore your options. \\nIf you would like to speak directly to a Customer Service Representative, please contact us at 1.888.777.6666 or visit the Contact Us link on the main menu for additional options." };
                }
            }          
            submitLoanReview(loanId, servicerId, primaryBorrowerUserId, ruleEngineResponse);
            return result;
        }
        else {
            if (primaryBorrowerUserId === userId) {
                return ({ disclosure: "In order to complete your request for assistance, all borrowers listed on your Mortgage document must complete the Income and Asset Verification section. \\nWe will send them a link to complete the verification to the email address we have on file. \\nIf your co-borrower does not have an email address on file, please have them log in to complete the Income and Asset Verification or they can contact us at 1.888.777.6666 for assistance." });
            }
            else {
                return ({ disclosure: "In order to complete your request for assistance, all borrowers listed on your Mortgage document must complete the Income and Asset Verification section. \\nWe will send them a link to complete the verification to the email address we have on file. \\nIf your borrower does not have an email address on file, please have them log in to complete the Income and Asset Verification or they can contact us at 1.888.777.6666 for assistance." });
            }
        }
    }
    catch (e) {
        console.log(e);
    }
}

export const sendEmail = async (data: any) => {
    let loan_number = data.loanId;
    const listBorrowersByLoanIdResponse: any = await getApi("api/Hoa/Hoa/listBorrowersDataByLoanId?loan_number="+loan_number)
    //  await API.graphql(graphqlOperation(listBorrowersDataByLoanId, { loan_number: loan_number }));
    const allBorrowers: any = listBorrowersByLoanIdResponse.data;
    let templateData: any = [];
    allBorrowers.forEach((borrower: any) => {
        templateData.push({ "email": data.email, "name": borrower.first_name });
    });
    let emailData = {
        templateName: data.templateName,
        templateData: templateData,
        action: "SendEmail"
    }
   const response =  await postApi("api/Hoa/Hoa/HoaEmailService?templateName="+data.templateName+"&action="+emailData.action+"&templateInput="+emailData.action,templateData, false, true)
    // await API.graphql(graphqlOperation(sendEmailService, { data: JSON.stringify(emailData) }));
}