import { Theme } from "../constants/theme"

export const updateTheme = (theme: Theme) => {
    document.documentElement.style.setProperty('--primary-color', theme.primary_color);
    document.documentElement.style.setProperty('--primary-color-hover', theme.primary_color_hover);
    document.documentElement.style.setProperty('--secondary-color', theme.secondary_color);
    document.documentElement.style.setProperty('--body-color', theme.body_color);
    document.documentElement.style.setProperty('--font-family', theme.font_family);
    document.documentElement.style.setProperty('--bottom-icons-color', theme.bottom_icons_color);
    document.documentElement.style.setProperty('--btn-border-color', theme.btn_border_color);
    document.documentElement.style.setProperty('--btn-background-color', theme.btn_background_color);
    document.documentElement.style.setProperty('--btn-bg-gradient-value1', theme.btn_bg_gradient_value1);
    document.documentElement.style.setProperty('--btn-bg-gradient-value2', theme.btn_bg_gradient_value2);
    document.documentElement.style.setProperty('--btn-bg-gradient-value3', theme.btn_bg_gradient_value3);
    if(theme.favicon){
        document.getElementById("favicon")!.setAttribute('href',theme.favicon);
    }
}