/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, useEffect, useState } from "react"
import { useNavigate } from "react-router";
import { readAppState } from "../../redux/reducers/appReducer";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { readMessageState, getMessageAsync, changeMessageTab, readInboxMessageAsync } from "../../redux/reducers/messageReducer";
import { Status } from "../../constants/status";
import { Loader } from "../Loader";
import { useLocation } from "react-router-dom";
import { MessageTabs } from "../../constants/messageConstants";
import { Storage } from "aws-amplify";

const MessageCenter = () => {
    const appState = useAppSelector(readAppState);
    const messageState = useAppSelector(readMessageState);
    const dispatch = useAppDispatch();
    const user = appState.currentUser
    const history = useNavigate();
    const [showDetails, setShowDetails] = useState({});
    const location = useLocation();
    const activeMessageTab = messageState.activeMessageTab;

    const msgdetails = {
        'userId': user,
    }

    useEffect(() => {

        dispatch(getMessageAsync(msgdetails)).then(data => {
            console.log(data);
        })

        if (location.state && location.state.sendFlag) {
            dispatch(changeMessageTab(MessageTabs.Outbox));
        }


    }, []);

    const toggleMessage = (id) => {
        setShowDetails(prevShowDetails => ({
            ...prevShowDetails,
            [id]: !prevShowDetails[id]
        }));
    };

    const handleTabChange = (tabName: string) => {
        dispatch(changeMessageTab(tabName));
    }

    const readMessage = (messageId, threadId, userId, readFlag) => {
        let messageDetail = {
            message_id: messageId,
            threas_id: threadId,
            user_name: userId,
            is_admin: true,
            read_flag: true
        }
        if (!readFlag) {
            dispatch(readInboxMessageAsync(messageDetail)).then(data => {
                console.log(data);
            })
        }
    }


    const getInboxMessageList = () => {
        if (messageState.status === Status.Loading) {
            return (
                <Loader />
            )

        }
        else {
            if (messageState.status !== Status.Loading && messageState.messageList && messageState.messageList.length > 0) {

                let inboxArray = messageState.messageList.filter(obj => obj.is_admin === true);
            
                if (inboxArray.length === 0) {
                    return (<div className="empty_message_box">
                        <span className="empty_m">
                            <i className="far fa-envelope-open"></i>
                            <p>You do not have any messages</p>
                        </span>
                    </div>)

                }


                let inboxFilterArray: any[] = [];
                inboxArray.reduce((res, value) => {
                    if (!res[Number(value.message_id)]) {
                        res[Number(value.message_id)] = {
                            message_id: value.message_id,
                            message_type: '',
                            values: [],
                            time:'',
                            read_flag:''
                        };
                        inboxFilterArray.push(res[Number(value.message_id)]);
                    }
                    res[Number(value.message_id)].message_type = value.message_type;
                    res[Number(value.message_id)].send_date = value.send_date.substring(0, 10);
                    res[Number(value.message_id)].read_flag = value.read_flag;
                    res[Number(value.message_id)].thread_id = value.thread_id;
                    res[Number(value.message_id)].user_name = value.user_name;
                    res[Number(value.message_id)].values = messageState.messageList.filter(i => i.message_id === value.message_id);
                    res[Number(value.message_id)].time = res[Number(value.message_id)].values.sort((a,b)=>{
                        let dateA = new Date(a.send_date); 
                        let dateB = new Date(b.send_date); 
                        if (dateA < dateB) {  return 1; }
                        if (dateA > dateB) {  return -1; }
                        return 0;
                    });
                    res[Number(value.message_id)].read_flag = res[Number(value.message_id)].time.filter(obj => obj.is_admin === true);
                 
                    return res;
                }, {});
                  
                
                return (inboxFilterArray.map((value, i) => {
                   
                    return (

                        <div key={`inbox${i}`}>

                            <div className="mesage_card" style={value.read_flag[0].read_flag  ? { backgroundColor: "white" } : { backgroundColor: " #ccf5ff" }} >

                                <div className="m_card_avatar"><span className="user_a_name">{ appState.userData?.first_name?.charAt(0) + "" + appState.userData?.last_name?.charAt(0)}</span></div>
                                <div className="m_card_content">
                                    <h3 className="m_c_title" style={{ fontSize: "14px" }} >Re: {value.message_type}</h3>
                                </div>
                                <div className="m_card_date" style={{ textAlign: "inherit" }}><span>{dateFormatter(value.time[0].send_date)}{" "}{timeFormatter(value.time[0].send_date)}</span></div>
                                <div style={{ alignSelf: "center" }} >
                                    <i style={{ cursor: "pointer", fontSize: 20 }} className={`primary-icons fas fa-arrow-circle-${showDetails[inboxArray.length + i] ? `up` : `down`}`} onClick={() => { toggleMessage(inboxArray.length + i); readMessage(value.message_id, value.thread_id, value.user_name, value.read_flag[0].read_flag) }} />
                                </div>
                            </div>

                            {value.values.map((item, indx) => {
                                return (
                                    <div key={`inboxcard${indx}`}>
                                        {showDetails[inboxArray.length + i] &&
                                            <div className="message_page" style={{ paddingTop: "15px" }}  >
                                                <div className="container" >

                                                    <div style={{ fontSize: '14px', display: "flex", justifyContent: "space-between" }}>
                                                        <div>
                                                            {item.is_admin === true ? <p><strong>From:</strong> {item.admin_id}</p> : <p><strong>From:</strong> {item.user_name}</p>}
                                                            <div>
                                                                <p><strong>Sent:</strong> {dateFormatter(item.send_date)} {timeFormatter(item.send_date)}</p>
                                                            </div>
                                                        </div>
                                                        <div>

                                                            {indx === 0 &&
                                                                <a href="#" className="btn btn-primary text-center" onClick={() => handleReply(item.message_type, item.message_reason, item.message_id, item.admin_id)}>Reply</a>
                                                            }

                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p style={{ wordBreak: "break-word" }}>{item.message_text}</p>
                                                    </div>

                                                    {item.file && item.file.length > 0 &&
                                                        <>
                                                            {item.file.map((data, i) => {
                                                                return (<a key={`inboxfile${i}`} href="#" style={{ textDecoration: 'none' }} onClick={() => { handleFileClick(data) }} className="added_doc_name">

                                                                    <li style={{ listStyle: 'none' }}>
                                                                        <span hidden={!data} style={{ width: '30px', height: '40px', color: '#00b6dd', textAlign: 'center', borderRadius: '50PX', fontSize: '15px', boxShadow: '0px 3px 9px rgba(0, 0, 0, .2),', alignItems: 'center' }} className="far fa-paperclip "></span>
                                                                        {data.fileName}
                                                                    </li>

                                                                </a>)
                                                            })}
                                                        </>}


                                                </div>
                                                {value.values.length > 1 && indx !== value.values.length - 1 &&
                                                    <hr />}
                                            </div>
                                        }
                                    </div>
                                )

                            })}

                        </div>



                    )
                })
                )


            }

            if (messageState.errorMessage || messageState.messageList.length === 0) {
                return (<div className="empty_message_box">
                    <span className="empty_m">
                        <i className="far fa-envelope-open"></i>
                        <p>You do not have any messages</p>
                    </span>
                </div>)
            }
        }
    }



    const getSentMessageList = () => {
        if (messageState.status === Status.Loading) {
            return (
                <Loader />
            )

        }
        else {

            if (messageState.status !== Status.Loading && messageState.messageList && messageState.messageList.length > 0) {

                let sentArray = messageState.messageList.filter(obj => obj.is_user === true);

                if (sentArray.length === 0) {
                    return (<div className="empty_message_box">
                        <span className="empty_m">
                            <i className="far fa-envelope"></i>
                            <p>You haven’t sent any messages</p>
                        </span>
                    </div>)

                }


                let outboxFilterArray: any[] = [];
                sentArray.reduce((res, value) => {
                    if (!res[Number(value.message_id)]) {
                        res[Number(value.message_id)]= {
                            message_id: value.message_id,
                            message_type: '',
                            values: [],
                            time:''
                        };
                        outboxFilterArray.push(res[Number(value.message_id)]);
                    }
                    res[Number(value.message_id)].message_type = value.message_type;
                    res[Number(value.message_id)].send_date = value.send_date;
                    res[Number(value.message_id)].values = messageState.messageList.filter(i => i.message_id === value.message_id);
                    res[Number(value.message_id)].time = res[Number(value.message_id)].values.sort((a,b)=>{
                        let dateA = new Date(a.send_date); 
                        let dateB = new Date(b.send_date); 
                        if (dateA < dateB) {  return 1; }
                        if (dateA > dateB) {  return -1; }
                        return 0;
                    });
                    return res;
                }, {});


                return (outboxFilterArray.map((value, i) => {
                   

                    return (

                        <div key={`sent${i}`}>

                            <div className="mesage_card"  >

                                <div className="m_card_avatar"><span className="user_a_name">{appState.userData?.first_name?.charAt(0) + "" + appState.userData?.last_name?.charAt(0)}</span></div>
                                <div className="m_card_content">
                                    <h3 className="m_c_title" style={{ fontSize: "14px" }}>Re: {value.message_type}</h3>
                                </div>
                                <div className="m_card_date" style={{ textAlign: "inherit" }}><span>{dateFormatter(value.time[0].send_date)}{" "}{timeFormatter(value.time[0].send_date)}</span></div>
                                <div style={{ alignSelf: "center" }} >
                                    <i style={{ cursor: "pointer", fontSize: 20 }} className={`primary-icons fas fa-arrow-circle-${showDetails[sentArray.length + i] ? `up` : `down`}`} onClick={() => toggleMessage(sentArray.length + i)} />
                                </div>
                            </div>

                            {value.values.map((item, indx) => {
                                return (
                                    <div key={`sentcard${indx}`}>
                                        {showDetails[sentArray.length + i] &&
                                            <div className="message_page" style={{ paddingTop: "15px" }}  >
                                                <div className="container" >

                                                    <div style={{ fontSize: '14px', display: "flex", justifyContent: "space-between" }}>
                                                        <div>
                                                            {item.is_admin === true ? <p><strong>From:</strong> {item.admin_id}</p> : <p><strong>From:</strong> {item.user_name}</p>}
                                                            <div>
                                                                <p><strong>Sent:</strong> {dateFormatter(item.send_date)} {timeFormatter(item.send_date)}</p>
                                                            </div>
                                                        </div>
                                                        <div>

                                                            {indx === 0 &&
                                                                <a href="#" className="btn btn-primary text-center" onClick={() => handleReply(item.message_type, item.message_reason, item.message_id, item.admin_id)}>Reply</a>
                                                            }

                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p style={{ wordBreak: "break-word" }}>{item.message_text}</p>
                                                    </div>

                                                    {item.file && item.file.length > 0 &&
                                                        <>
                                                            {item.file.map((data, i) => {
                                                                return (<a key={`sentfile${i}`} href="#" style={{ textDecoration: 'none' }} onClick={() => { handleFileClick(data) }} className="added_doc_name">

                                                                    <li style={{ listStyle: 'none' }}>
                                                                        <span hidden={!data} style={{ width: '30px', height: '40px', color: '#00b6dd', textAlign: 'center', borderRadius: '50PX', fontSize: '15px', boxShadow: '0px 3px 9px rgba(0, 0, 0, .2),', alignItems: 'center' }} className="far fa-paperclip "></span>
                                                                        {data.fileName}
                                                                    </li>

                                                                </a>)
                                                            })}
                                                        </>}

                                                </div>
                                                {value.values.length > 1 && indx !== value.values.length - 1 &&
                                                    <hr />}
                                            </div>}
                                    </div>
                                )

                            })}

                        </div>



                    )
                })
                )

            }
            if (messageState.errorMessage || messageState.messageList.length === 0) {
                return (<div className="empty_message_box">
                    <span className="empty_m">
                        <i className="far fa-envelope"></i>
                        <p>You haven’t sent any messages</p>
                    </span>
                </div>)
            }

        }
    }
    const handleClick = () => {
        history("/compose-message");

    }

    const handleReply = (messageType: string, messageReason: string, messageId: number, adminId: string) => {
        history("/compose-message", {state:{ messageId: messageId, messageType: messageType, messageReason: messageReason, adminId: adminId, isReplyFlag: true }});

    }

    const handleFileClick = async (fileData: any) => {
        // const url = await Storage.get(fileName);
        window.open(fileData.accessUrl);
    }

    const dateFormatter = (sendDate) => {
        var date = new Date(sendDate).toLocaleDateString();
        return date;

    }
    const timeFormatter = (sendDate) => {
        var time = new Date(sendDate).toLocaleTimeString('en-US', { hour12: false,hour:"numeric",minute:"numeric" },);
        return time;
    }

    return (
        <div className="main_wrapper message_page">

            <div className="container">
                <h2 className="page_title d-none d-md-block d-lg-block d-xl-block">Secure Messages</h2>

                <div className="content_section">

                    <h3 className="page_title2 mb20 d-lg-none d-md-none">Secure Messages</h3>

                    <ul className="nav cm_tabs">
                        <li><a href="#" className={`${activeMessageTab === MessageTabs.Inbox ? "active" : ""}`} data-toggle="tab" onClick={() => handleTabChange(MessageTabs.Inbox)}  >Inbox</a></li>
                        <li><a href="#" className={`${activeMessageTab === MessageTabs.Outbox ? "active" : ""}`} data-toggle="tab" onClick={() => handleTabChange(MessageTabs.Outbox)} >Sent</a></li>
                    </ul>

                    <div className="tab-content cm_tabs_content" style={{minHeight:"400px"}}>
                        <div style={{minHeight:"400px"}} className={`tab-pane ${activeMessageTab === MessageTabs.Inbox ? "active" : ""}`} id="inbox" >{getInboxMessageList()} </div>
                        <div style={{minHeight:"400px"}} className={`tab-pane ${activeMessageTab === MessageTabs.Outbox ? "active" : ""}`} id="sent" >{getSentMessageList()} </div>


                        <a href="#" className="mail_compose_btn" data-toggle="modal" data-target="#compose_mail" onClick={() => handleClick()}><span className="far fa-edit"></span></a>

                    </div>
                </div>
            </div>
        </div>
    )



}
export default memo(MessageCenter);