// import axios from "axios";
import { listMessageCenterAttachements, listMessageCenterThreads } from '../graphql/queries';
import { API, graphqlOperation } from "aws-amplify";
import { createMessageCenter, createMessageCenterAttachement, createMessageCenterThread, updateMessageCenterThread } from '../graphql/mutations';
import { getApi, postApi } from '../api/base';

const nodeApiUrl = process.env.REACT_APP_NODE_API_URL;

const setHeaders = () => {
    const token = sessionStorage.getItem('jwtToken');
    const requestHeader = {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
        }
    }
    return requestHeader
}

export const composeMessage = async (data: any) => {
    try {
        const messageCenterResponse: any = await postApi("api/Hoa/Hoa/createMessageCenter",data, false, true)
        console.log("msg:",messageCenterResponse)
        // await API.graphql(graphqlOperation(createMessageCenter,
            // {
            //     input: {
            //         user_name: data.userId,
            //         servicer_id: data.servicerId,
            //         loan_id: data.loanId,
            //         admin_id: "admin 1"
            //     }
            // }));
        let data1={...data,message_id: messageCenterResponse?.message_id, is_user: true,
                        is_admin: false,
                        read_flag: false}
        const messageCenterThreadResponse: any = await postApi("api/Hoa/Hoa/createMessageCenterThread",data1, false, true)
        // await API.graphql(graphqlOperation(createMessageCenterThread,
        //     {
        //         input: {
        //             message_id: messageCenterResponse.data.createMessageCenter.message_id,
        //             message_type: data.Category,
        //             user_name: data.userId,
        //             message_text: data.Message,
        //             message_reason: data.Reason,
        //             admin_id: "admin 1",
        //             send_date: data.Date,
        //             is_user: true,
        //             is_admin: false,
        //             read_flag: false
        //         }
        //     }));

        data.UploadFile.map(async (d) => {
            let file = JSON.stringify({ fileData: d }).replaceAll(/"/g, '\\"');
            let param={
                thread_id:messageCenterThreadResponse.thread_id,
                message_id: messageCenterThreadResponse.message_id,
                file: file
            }
            await postApi("api/Hoa/Hoa/createMessageCenterAttachement",param, false, true)
            // await API.graphql(graphqlOperation(createMessageCenterAttachement, {
            //     input: {
            //         thread_id: messageCenterThreadResponse.data.createMessageCenterThread.thread_id,
            //         message_id: messageCenterThreadResponse.data.createMessageCenterThread.message_id,
            //         file: file
            //     }
            // }));
        });

        return messageCenterThreadResponse;
    }
    catch (err) {
        return err;
    }
}

export const ReplyToMessage = async (data: any) => {
    try {
        const messageCenterThreadResponse: any = await postApi("api/Hoa/Hoa/createMessageCenterThread",data, false, true);
        console.log("data:",messageCenterThreadResponse)
        // await API.graphql(graphqlOperation(createMessageCenterThread,
        //     {
        //         input: {
        //             message_id: data.messageId,
        //             message_type: data.Category,
        //             user_name: data.userId,
        //             message_text: data.Message,
        //             message_reason: data.Reason,
        //             admin_id: data.adminId,
        //             send_date: data.Date,
        //             is_user: true,
        //             is_admin: false,
        //             read_flag: false
        //         }
        //     }));

       
            data.UploadFile.map(async (d) => {
                let file = JSON.stringify({ fileData: d }).replaceAll(/"/g, '\\"');
                let param={
                    thread_id:messageCenterThreadResponse.thread_id,
                    message_id:  data.message_id,
                    file: file
                }
                await postApi("api/Hoa/Hoa/createMessageCenterAttachement",param, false, true)

            // await API.graphql(graphqlOperation(createMessageCenterAttachement, {
            //     input: {
            //         thread_id: messageCenterThreadResponse.data.createMessageCenterThread.thread_id,
            //         message_id: data.message_id,
            //         file: file
            //     }
            // }));
        });

        return messageCenterThreadResponse;
    }
    catch (err) {
        console.log(err);
    }
}

export const getMessage = async (data: any) => {
    try {
        const responseMessageCenterThreads: any =  await getApi("api/Hoa/Hoa/listMessageCenterThreads")
        // await API.graphql(graphqlOperation(listMessageCenterThreads));
        console.log("msg: ",responseMessageCenterThreads.data)
        let messageCenterThreads = responseMessageCenterThreads.data;
        messageCenterThreads = messageCenterThreads.filter(m => m.user_name === data.userId);
        let messageCenterThreadsData = await Promise.all(messageCenterThreads.map(async (m) => {
            let data = { ...m };
            const messageCenterAttachementResponse: any = await getApi("api/Hoa/Hoa/listMessageCenterAttachements")
            // await API.graphql(graphqlOperation(listMessageCenterAttachements));
            console.log("msg: ",messageCenterAttachementResponse)
            let messageCenterAttachments: any = messageCenterAttachementResponse.data;
            let files: any[] = [];
            messageCenterAttachments.filter(f => f.thread_id === m.thread_id).forEach(f => {
                const file = JSON.parse(f.file);
                if (file) {
                    files.push(file.fileData);
                }
            });
            data['file'] = files;
            return data;
        }));

        return messageCenterThreadsData;
    }
    catch (err) {
        throw err;
    }
}

export const readInboxMessage = async (data: any) => {
    try {
        const messageCenterThreadResponse: any = await postApi("api/Hoa/Hoa/updateMessageCenterThread",data, false, true)
        // await API.graphql(graphqlOperation(updateMessageCenterThread,
        //     {
        //         input: {
        //             message_id: data.messageId,
        //             user_name: data.userId,
        //             send_date: data.Date,
        //             is_admin: true,
        //             read_flag: true
        //         }
        //     }));
        return { messageId: data.messageId }
    }
    catch (err) {
        throw err;
    }

}