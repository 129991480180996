export const disclosures = {
    FbExtensionMessage: "Your Forbearance request is being reviewed. Please allow up to 5 business days from your date of request for processing. \n\nIf you would like to speak directly to a Customer Service Representative, please contact us at 1.888.777.6666 or visit the Contact Us link on the main menu for additional options.",
    FbSetupMessage: "Your Forbearance request is being reviewed and set-up.  Please allow up to 5 business days from your date of request for processing. \n\nIf you would like to speak directly to a Customer Service Representative, please contact us at 1.888.777.6666 or visit the Contact Us link on the main menu for additional options.",
    BankruptcyMessage: "We see that your account is currently in a Bankruptcy status.  Thank you for inquiring about Homeowner Assistance options.  Your request will be forwarded to your Account Representative who will contact you within the next 24 hours to offer further assistance. \n\nIf you would like to speak directly to a Customer Service Representative, please contact us at 1.888.777.6666 or visit the Contact Us link on the main menu for additional options.",
    WorkoutOptionDeclined: "A representative will be contacting you within 24 hours of your inquiry to help you explore your options. If you would like to continue to submit your request for assistance for review, please click Continue.",
    PreQualifiedMessage: "You’ve been pre-qualified for the assistance plan you selected!  Your request is being reviewed and a customer service representative will contact you within 24 hours of your request to further assist you.",
    AdditionalBorrowerPendingMessage: "Your request for assistance has been started. In order to help you with assistance options, all borrowers listed on your Mortgage document must complete the Income and Asset Verification section.  Once all borrowers have completed and confirmed that information, your request for assistance will be submitted for review.",
    NotQualifiedDisclosure: "Your request has been submitted for additional review and a customer service representative will contact you within 24 hours of your request.",
    AssistanceRequested: "Thank you for inquiring about Homeowner’s Assistance Options! Your request for assistance is being reviewed further and a representative will contact you within the next 24 hours to help you explore your options.",
    FinancialScreenExit: "We’re glad you’ve reached out for help making your mortgage payments. You can return here any time and pick up right where you’ve left off!",
    PaymentScreenExit: "Thank you for inquiring about mortgage assistance options. If you’d like to continue to learn how we can assist you, click continue to keep going!",
    DocPendingSurveyComplete: "We are glad you’ve started the process to find available mortgage payment assistance options! We look forward to helping you further whenever you’re ready.When you come back, you can pick up right where you’ve left off! \\nIf you would like to speak directly to a Customer Service Representative, please contact us at 1.888.777.6666 or visit the Contact Us link on the main menu for additional options.",
    DocPendingSurveyIncomplete: "<p style='color:#2275BC'><b>Next Steps!</b></p>• Your request for assistance is pending the co-borrower's income and asset verification.  Once all borrowers have completed the verification, your request for assistance will be submitted for review.\\n• Please upload the required documents to complete the review.\\n Once these steps are complete, we can review your assistance request.We look forward to helping you further whenever you’re ready.  When you come back, you can pick up right where you’ve left off! \\n",
    DocSurveyComplete: "Thank you for submitting your request for assistance! You’ve been pre-qualified for the assistance plan you selected!  Your request is being reviewed and a customer service representative will contact you within 24 hours of your request to further assist you.",
    DocCompleteSurveyIncomplete: "<p style='color:#2275BC'><b>Next Steps!</b></p>Your request for assistance is pending the co-borrower's income and asset verification.  Once all borrowers have completed the verification, your request for assistance will be submitted for review. Please contact us at 1.888.777.6666 if you need additional information.",
    LoanBankrupt: "I see that your account is currently in a Bankruptcy status.  Thank you for inquiring about Homeowner Assistance options.  Your request will be forwarded to your Account Representative who will contact you within the next 24 hours to offer further assistance. \n\nIf you would like to speak directly to a Customer Service Representative, please contact us at 1.888.777.6666 or visit the Contact Us link on the main menu for additional options.",
    RequestClosed: "Your request for mortgage assistance has been closed. For more information, please call us at 1.888.777.6666.",
    CoborrWelcomeMessage: "The co-borrower on your Mortgage Loan Account has submitted a request for Mortgage Assistance.  In order to review the request for assistance, we need you to complete the Income and Asset Verification section of the request.  Once all borrowers have completed the verification, the request for Mortgage Assistance will be submitted for review.",
    PrimarySurveyInitiated: "The co-borrower on your Mortgage Loan Account has initiated a request for Mortgage Assistance.  In order to review the request for assistance, we need you to complete the Income and Asset Verification section of the request.  Once all borrowers have completed the verification, the request for Mortgage Assistance will be submitted for review."
}