import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

export const ProtectedRoute = () => {
  const isAuthenticated = sessionStorage.getItem("jwtToken");
  const isLbpToken = sessionStorage.getItem("lbpToken");
  console.log("this", isAuthenticated);
  console.log("protected : " + isLbpToken);

  // return (
  //   <Route
  //     {...restOfProps}
  //     render={(props) =>
  //       isAuthenticated || isLbpToken ? <Component {...props} /> : <Redirect to="/login" />
  //     }
  //   />
  // );

  return(

    isAuthenticated || isLbpToken ?
            <>
              
                <Outlet />
                {/* <Chatbot/> */}
              
            </> :
            <Navigate to="/login" />);

  
}
