import { Fragment, useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { addDocument, readQuestionState, uploadDocumentsAsync, uploadFilesToDbAsync, deleteDocumentsAsync, setContinueButtonStatus, setDisclosure, setIsWorkoutExit, updateSurveyStatusAsync } from "../../redux/reducers/questionReducer";
import { QuestionTypes } from "../../constants/questionTypes";
import { IFinancialData } from "../../redux/stores/questionStore";
import { FinancialSections } from "../../constants/financialConstants";
import { useNavigate } from "react-router";
import { readAppState } from "../../redux/reducers/appReducer";
import { disclosures } from "../../constants/disclosureMessages";
import { SurveyStatus } from "../../constants/surveyStatus";


export const UploadedFiles = () => {
    const history = useNavigate();
    const questionState = useAppSelector(readQuestionState);
    const appState = useAppSelector(readAppState);
    const [fileList, updateFileList] = useState([] as any[]);
    const [documentUploadedList, handleUploadList] = useState([] as any[]);

    const dispatch = useAppDispatch();

    useEffect(() => {
        let values: any[] = [];
        let uploadedDocuments: any[] = [];
        if (questionState.userResponses) {
            console.log(questionState.userResponses);
            questionState.userResponses.forEach(x => {
                switch (x.questionType) {
                    case QuestionTypes.SingleSelectCheckBox:
                    case QuestionTypes.MultiSelectCheckBox:
                        x.checkBoxResponses!.filter(checkBoxResponse => checkBoxResponse["fileUpload"]).forEach((responseRequireFile) => {
                            values.push({ questionId: x.question_id, option: responseRequireFile })
                            if (responseRequireFile.supportedDocs) {
                                let documentList: { categoryName: string, shortName: string, files: string[] }[] = [];
                                if (responseRequireFile.supportedDocs) {
                                    if (responseRequireFile.supportedDocs.and) {
                                        responseRequireFile.supportedDocs.and.forEach(supportedDocument => {
                                            documentList.push({ categoryName: supportedDocument.file, shortName: supportedDocument.shortName, files: [] });
                                            if (supportedDocument.or && supportedDocument.or.length > 0) {
                                                supportedDocument.or.forEach(additionalDocument => {
                                                    documentList.push({ categoryName: additionalDocument.file, shortName: additionalDocument.shortName, files: [] });
                                                })
                                            }
                                        })
                                    }
                                    else if (responseRequireFile.supportedDocs.or) {
                                        responseRequireFile.supportedDocs.or.forEach(supportedDocument => {
                                            documentList.push({ categoryName: supportedDocument.file, shortName: supportedDocument.shortName, files: [] });
                                            if (supportedDocument.and && supportedDocument.and.length > 0) {
                                                supportedDocument.and.forEach(additionalDocument => {
                                                    documentList.push({ categoryName: additionalDocument.file, shortName: additionalDocument.shortName, files: [] });
                                                })
                                            }
                                        })
                                    }
                                }
                                uploadedDocuments.push({ option: responseRequireFile.option, category: documentList })
                            }
                            if (responseRequireFile.supportedDocs && questionState.documentsUploaded && questionState.documentsUploaded.length > 0) {
                                questionState.documentsUploaded.forEach(documentInState => {
                                    if (documentInState.option === responseRequireFile.option) {
                                        uploadedDocuments = uploadedDocuments.map(uploadedDocument => {
                                            if (uploadedDocument.option === documentInState.option) {
                                                documentInState.category.forEach(stateCtgr => {
                                                    let updatedLocalCtgr = uploadedDocument.category.map(localCtgr => {
                                                        if (stateCtgr.categoryName === localCtgr.categoryName) {
                                                            let fileNames = stateCtgr.files.map(file => file.fileName);
                                                            localCtgr = { ...localCtgr, files: fileNames }
                                                        }
                                                        return localCtgr;
                                                    });
                                                    uploadedDocument = { ...uploadedDocument, category: updatedLocalCtgr };
                                                });
                                            }
                                            return uploadedDocument;
                                        });
                                    }
                                });

                            }
                        })
                        break;
                    case QuestionTypes.Custom:
                        x.customResponse[FinancialSections.Income].filter(x => x.value).map((z: IFinancialData) => {
                            const description: any = z.description3;
                            let description3 = description.supportedDocs;
                            if (description3.or) {
                                description3 = description3.or.map(x => {
                                    return { file: x.file, shortName: x.shortName }
                                });
                            }
                            else if (description3.and) {
                                description3 = description3.and.map(x => {
                                    return { file: x.file, shortName: x.shortName }
                                });
                            }

                            values.push({ questionId: x.question_id, option: { supportedDocs: description.supportedDocs, fileUpload: z.display_value, option: z.display_value, fileUploaded: z.file_uploaded } })
                            let ctgr: any[] = [];
                            description3.forEach(docName => {
                                ctgr.push({ categoryName: docName.file, shortName: docName.shortName, files: [] });
                            })
                            uploadedDocuments.push({ option: z.display_value, category: ctgr });
                        })
                        questionState.documentsUploaded.forEach(documentInState => {
                            uploadedDocuments = uploadedDocuments.map(uploadedDocument => {
                                if (uploadedDocument.option === documentInState.option) {
                                    documentInState.category.forEach(stateCtgr => {
                                        let updatedLocalCtgr = uploadedDocument.category.map(localCtgr => {
                                            if (stateCtgr.categoryName === localCtgr.categoryName) {
                                                let fileNames = stateCtgr.files.map(file => file.fileName);
                                                localCtgr = { ...localCtgr, files: fileNames }
                                            }
                                            return localCtgr;
                                        });
                                        uploadedDocument = { ...uploadedDocument, category: updatedLocalCtgr };
                                    });
                                }
                                return uploadedDocument;
                            });
                        });
                        break;
                }
            });
            updateFileList(values);
            handleUploadList(uploadedDocuments);
        }
    }, [questionState.documentsUploaded]);

    const handleClick = async (option, categoryName, fileName: any) => {
        if (questionState.documentsUploaded && questionState.documentsUploaded.length) {
            let selectedOption = questionState.documentsUploaded.find(x => x.option === option);
            if (selectedOption) {
                let category = selectedOption.category.find(x => x.categoryName === categoryName);
                if (category) {
                    let file = category.files.find(x => x.fileName === fileName);
                    if (file) {
                        window.open(file.accessUrl);
                    }
                }
            }
        }
    }

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>, response: any, categoryName: string, shortName: string) => {
        let fileUploaded: any = undefined;
        if (e.target.files) {
            for (var fileObject in e.target.files) {
                if (typeof e.target.files[fileObject] === "object") {
                    const file = (e.target.files[fileObject]);
                    const fileBlob = new Blob([file], { type: file.type });
                    fileUploaded = { accessUrl: URL.createObjectURL(fileBlob), fileName: `${shortName}-${appState.loanData!.loan_number}-${file.name}`, fileType: file.type, isUploaded: false };
                }
            }
            let optionAlreadyExists = false;
            let documentsUploaded = [...questionState.documentsUploaded].map(x => {
                if (x.option === response.option) {
                    optionAlreadyExists = true;
                    let category: any = [];
                    let categoryAlreadyExists = false;
                    category = [...x.category].map(y => {
                        if (categoryName === y.categoryName) {
                            categoryAlreadyExists = true;
                            if (fileUploaded) {
                                let previousFileList = [...y.files];
                                previousFileList.push(fileUploaded);
                                y = { ...y, files: previousFileList };
                            }
                        }
                        return y;
                    });
                    if (!categoryAlreadyExists) {
                        category.push({
                            categoryName: categoryName,
                            files: [fileUploaded]
                        })
                    }
                    x = { ...x, category: category };
                }
                return x;
            });

            if (!optionAlreadyExists) {
                documentsUploaded.push({
                    option: response.option,
                    fileUpload: response.fileUpload,
                    category: [
                        {
                            categoryName: categoryName,
                            files: [fileUploaded]
                        }
                    ]
                });
            }
            dispatch(addDocument(documentsUploaded));
        }
    }

    const isDocumentMissing = () => {
        let isMissing = false;
        if (documentUploadedList.length > 0) {
            documentUploadedList.forEach(doc => {
                const supportedDocument = fileList.find(x => x.option.option === doc.option).option.supportedDocs;
                if (supportedDocument.and && supportedDocument.and.length > 0) {
                    let docsInState = questionState.documentsUploaded.find(y => y.option === doc.option);
                    if (docsInState && docsInState.category && docsInState.category.length > 0) {
                        supportedDocument.and.forEach(supportedDoc => {
                            let uploadedCategory = docsInState!.category.find(x => x.categoryName === supportedDoc.file);
                            const additionalDocuments = supportedDoc.or;
                            if (uploadedCategory && uploadedCategory.files && uploadedCategory.files.length > 0) {
                                uploadedCategory.files.forEach((file) => {
                                    if (!file.isUploaded) {
                                        isMissing = true;
                                        return
                                    }
                                })
                                if (!isMissing && additionalDocuments && additionalDocuments.length > 0) {
                                    additionalDocuments.forEach(additionalDoc => {
                                        let additionalUploadedCategory = docsInState!.category.find(x => x.categoryName === additionalDoc.file);
                                        if (additionalUploadedCategory && additionalUploadedCategory.files && additionalUploadedCategory.files.length > 0) {
                                            additionalUploadedCategory.files.forEach((file, fileIndex) => {
                                                if (file.isUploaded) {
                                                    return
                                                }
                                                if (fileIndex === additionalUploadedCategory!.files.length - 1) {
                                                    isMissing = true;
                                                }
                                            })
                                        }
                                        else {
                                            isMissing = true;
                                        }
                                    });
                                }
                            }
                            else {
                                isMissing = true;
                            }

                            if (isMissing) {
                                return;
                            }
                        });
                    }
                    else {
                        isMissing = true;
                    }
                }
                else if (supportedDocument.or && supportedDocument.or.length > 0) {
                    let docsInState = questionState.documentsUploaded.find(y => y.option === doc.option);
                    let documentUploaded = false;
                    let documentUploadedFor = "";
                    if (docsInState && docsInState.category && docsInState.category.length > 0) {
                        supportedDocument.or.forEach((supportedDoc, supportedDocIndex) => {
                            let uploadedCategory = docsInState!.category.find(x => x.categoryName === supportedDoc.file);
                            const additionalDocuments = supportedDoc.and;
                            if (uploadedCategory && uploadedCategory.files) {
                                uploadedCategory.files.forEach((file, fileIndex) => {
                                    if (file.isUploaded) {
                                        documentUploadedFor = uploadedCategory!.categoryName;
                                        documentUploaded = true;
                                        return;
                                    }
                                    if (!documentUploaded && fileIndex === uploadedCategory!.files.length - 1) {
                                        isMissing = true;
                                    }
                                })
                                if (documentUploaded && documentUploadedFor === uploadedCategory!.categoryName && additionalDocuments && additionalDocuments.length > 0) {
                                    additionalDocuments.forEach(additionalDoc => {
                                        let additionalUploadedCategory = docsInState!.category.find(x => x.categoryName === additionalDoc.file);
                                        if (additionalUploadedCategory && additionalUploadedCategory.files && additionalUploadedCategory.files.length > 0) {
                                            additionalUploadedCategory.files.forEach((file) => {
                                                if (!file.isUploaded) {
                                                    isMissing = true;
                                                    return;
                                                }
                                            })
                                        }
                                        else {
                                            isMissing = true;
                                            return;
                                        }
                                    });
                                }
                            }
                            if (!documentUploaded && supportedDocIndex === supportedDocument.or.length - 1) {
                                isMissing = true;
                            }
                            if (documentUploaded && !isMissing) {
                                return;
                            }
                            if (isMissing) {
                                return;
                            }
                        });
                    }
                    else {
                        isMissing = true;
                    }
                }
                else {
                    let docsInState = questionState.documentsUploaded.find(y => y.option === doc.option);
                    if (docsInState) {
                        if (docsInState.category.length > 0) {
                            docsInState.category.forEach((y, j) => {
                                if (y.files.length > 0) {
                                    y.files.forEach((file) => {
                                        if (!file.isUploaded) {
                                            isMissing = true;
                                            return
                                        }
                                    })
                                }
                                else {
                                    isMissing = true;
                                }
                                if (isMissing) {
                                    return;
                                }
                            });
                        }
                        else {
                            isMissing = true;
                        }
                    }
                    else {
                        isMissing = true;

                    }
                    if (isMissing) {
                        return;
                    }
                }
            });
        }
        else {
            isMissing = true;
        }

        return isMissing;
    }


    const getuploadedDocList = (categoryName: string, response: any) => {
        let category = documentUploadedList.find(x => x.option === response.option)!.category;
        if (category) {
            let listItems: any = [];
            category.forEach(item => {
                if (item.categoryName === categoryName) {
                    item.files.forEach((file, i) => {
                        listItems.push(<li key={response.option + categoryName + i}>
                            <a onClick={() => { handleClick(response.option, item.categoryName, file) }} className="added_doc_name">{file}</a>
                            <a className="add_doc_delete" onClick={() => removeDocument(response.option, item.categoryName, file)}><span className="fas fa-trash-alt"></span></a>
                        </li>
                        );
                    })
                }
            })
            return <ul className="added_document_list">
                {listItems}
            </ul>
        }
        return null;
    }

    const isFileUploaded = (categoryName: string, option: string, disableFlag?: boolean) => {
        if (questionState.documentsUploaded.length > 0) {
            let optionSelected = questionState.documentsUploaded.find(x => x.option === option);
            if (optionSelected) {
                let category = optionSelected.category.find(y => y.categoryName === categoryName);
                if (category) {
                    if (category.files.length > 0) {
                        let isUploaded = true;
                        category.files.forEach(file => {
                            if (!file.isUploaded) {
                                isUploaded = false;
                                return;
                            }
                        })
                        return isUploaded;
                    }
                    else if (disableFlag) {
                        return true;
                    }
                    else {
                        return false
                    }
                }
            }
        }
        return false || disableFlag;

    }

    const handleUploadClick = (categoryName: string, option: string) => {
        let documentsUploadedCopy = [...questionState.documentsUploaded].map(doc => {
            if (doc.option === option) {
                const category = doc.category.map(ctgr => {
                    if (ctgr.categoryName === categoryName) {
                        const files = ctgr.files.map(file => {
                            file = { ...file, isUploaded: true }
                            return file
                        })
                        ctgr = { ...ctgr, files: files }
                    }
                    return ctgr;
                });
                doc = { ...doc, category: category }
            }
            return doc;
        });
        let filesToUpload = documentsUploadedCopy.find(x => x.option === option)!.category.find(y => y.categoryName === categoryName)!.files;
        const uploadData = { categoryName: categoryName, option: option, files: filesToUpload }
        dispatch(uploadDocumentsAsync({ ...uploadData, loanId: appState.loanData?.loan_number, servicer: appState.loanData?.servicer_id })).then((response) => {
            if (response.payload) {
                let data = response.payload as any;
                const updateDocuments = documentsUploadedCopy.map(doc => {
                    if (doc.option === option) {
                        const category = doc.category.map(ctgr => {
                            if (ctgr.categoryName === categoryName) {
                                const files = ctgr.files.map(file => {
                                    data.files.forEach(k => {
                                        if (k.fileName === file.fileName) {
                                            file.key = k.key;
                                        }
                                    })

                                    return file
                                })
                                ctgr = { ...ctgr, files: files }
                            }
                            return ctgr;
                        });
                        doc = { ...doc, category: category }
                    }
                    return doc
                });
                dispatch(addDocument(updateDocuments));
                const params = { uploadData: data, user_name: appState.currentUser, loan_number: appState.loanData?.loan_number, servicer_id: appState.loanData?.servicer_id }
                dispatch(uploadFilesToDbAsync(params));
            }
        });
    }

    const renderCategories = (category: any, response: any, i: any) => {
        const isUploaded = isFileUploaded(category.file, response.option.option);
        return <div className="add_doc_block">
            <div className="add_doc_head">
                <span className={`add_doc_status ${isUploaded ? "added" : ""}`}></span>
                <h3>{category.file}</h3>
            </div>
            <div className="add_doc_body">
                {getuploadedDocList(category.file, response.option)}
                <ul className="add_doc_buttons">
                    <li>
                        <input className="display_hidden" id={"uploadFile" + response.option.option + category.file + i} type="file" name="files" accept=".pdf, .jpg, .jpeg, .png, .doc, docx" onClick={(event: any) => event.target.value = null} onChange={(e) => onChangeHandler(e, response.option, category.file, category.shortName)} />
                        <label htmlFor={"uploadFile" + response.option.option + category.file + i} className="btn btn-primary text-center" >Browse</label>
                    </li>
                    <li>
                        <a className={`btn blue-outline-btn text-center upload_btn ${isFileUploaded(category.file, response.option.option, true) ? "disabled" : ""}`} onClick={() => handleUploadClick(category.file, response.option.option)} >Upload Document</a>
                    </li>
                </ul>
            </div>
        </div>
    }
    const getFileNames = (response: any) => {
        if (response.option.supportedDocs) {
            if (response.option.supportedDocs.or) {
                return response.option.supportedDocs.or.map((category, i) => {
                    const render1 = renderCategories(category, response, i);
                    if (category.and && category.and.length > 0) {
                        const render2 = category.and.map((additionalCategory, j) => {
                            return <Fragment key={response.option.option + additionalCategory.file + j} >
                                {renderCategories(additionalCategory, response, i)}
                                {category.and.length - 1 !== j ? <p className="document_condition">AND</p> : null}

                            </Fragment>
                        });
                        return <Fragment key={response.option.option + category.file + i}>
                            {render1}
                            <p className="document_condition">AND</p>
                            {render2}
                        </Fragment>
                    }
                    else {
                        return <Fragment key={response.option.option + category.file + i}>
                            {render1}
                            {response.option.supportedDocs.or.length - 1 !== i ? <p className="document_condition">OR</p> : null}
                        </Fragment>
                    }
                });
            }
            else if (response.option.supportedDocs.and) {
                return response.option.supportedDocs.and.map((category, i) => {
                    const render1 = renderCategories(category, response, i);
                    if (category.or && category.or.length > 0) {
                        const render2 = category.or.map((additionalCategory, j) => {
                            return <Fragment key={response.option.option + additionalCategory.file + j}>
                                {renderCategories(additionalCategory, response, i)}
                                {category.or.length - 1 !== j ? <p className="document_condition">OR</p> : null}

                            </Fragment>
                        });
                        return <Fragment key={response.option.option + category.file + i}>
                            {render1}
                            <p className="document_condition">OR</p>
                            {render2}
                        </Fragment>
                    }
                    else {
                        return <Fragment key={response.option.option + category.file + i}>
                            {render1}
                            {response.option.supportedDocs.or.length - 1 !== i ? <p className="document_condition">AND</p> : null}
                        </Fragment>
                    }
                });
            }
            else {
                return response.option.supportedDocs.map((category, i) => {
                    const render1 = renderCategories(category, response, i);
                    return <Fragment key={response.option.option + category.file + i}>
                        {render1}
                        {response.option.supportedDocs.length - 1 !== i ? <p className="document_condition">OR</p> : null}
                    </Fragment>
                })
            }
        }
    }

    const getUploadedFileList = () => {
        return fileList.map((item, i) => {
            const optionName = item.option.fileUpload ? item.option.fileUpload : item.option;
            return <>
                <div className="c_acc_card">
                    <div className="c_acc_card_header">
                        <a className="c_acc_link" type="button" data-toggle="collapse" data-target={`#col${i}`} aria-expanded="true" aria-controls={`#col${i}`}>
                            {optionName}
                        </a>
                    </div>
                    <div id={`col${i}`} className="collapse">
                        <div className="c_acc_card_body">
                            {getFileNames(item)}
                        </div>
                    </div>
                </div>
            </>
        })
    }

    const removeDocument = (option: string, categoryName: string, fileName: string) => {
        let fileKey = "";
        let documentsUploaded = questionState.documentsUploaded.map(x => {
            if (x.option === option) {
                let category = x.category.map(y => {
                    if (y.categoryName === categoryName) {
                        fileKey = y.files.find(k => k.fileName === fileName)!.key;
                        let files = y.files.filter(file => file.fileName !== fileName);
                        y = { ...y, files: files };
                    }
                    return y;
                });
                x = { ...x, category: category };
            }
            return x;
        });
        dispatch(addDocument(documentsUploaded));

        const data = { key: fileKey };
        dispatch(deleteDocumentsAsync(data)).then(response => {
            if (response.payload) {
                const filesToUpload = documentsUploaded.find(x => x.option === option)!.category.find(y => y.categoryName === categoryName)!.files;
                const uploadData = { categoryName: categoryName, option: option, files: filesToUpload };
                const params = { uploadData: uploadData, user_name: appState.currentUser, loan_number: appState.loanData?.loan_number, servicer_id: appState.loanData?.servicer_id }
                dispatch(uploadFilesToDbAsync(params));
            }
        })
    }

    const decideDisclosure = () => {
        let disclosureMessage = "";
        if (questionState.suggestions) {
            if (questionState.suggestions.suggestions) {
                disclosureMessage = disclosures.DocSurveyComplete;
            }
            else {
                disclosureMessage = questionState.suggestions.disclosure;
            }
        }
        return disclosureMessage;
    }

    const handleBtnClick = (flag) => {
        dispatch(setIsWorkoutExit(false));
        let disclosureMessage = "";
        if (flag) {
            dispatch(setContinueButtonStatus(true));
            if (questionState.borrowersCount === 1 || questionState.isSurveyCompleted) {
                disclosureMessage = disclosures.DocPendingSurveyComplete;
            }
            else {
                let surveyCompleted: boolean = true;
                questionState.allBorrowerSurveyStatus.forEach(x => {
                    if (x.user_name !== appState.currentUser && x.submission_status !== SurveyStatus.Completed && x.submission_status !== SurveyStatus.DocPending) {
                        surveyCompleted = false;
                    }
                });
                disclosureMessage = surveyCompleted ? disclosures.DocPendingSurveyComplete : disclosures.DocPendingSurveyIncomplete;
            }
        } else {
            dispatch(setContinueButtonStatus(false));
            if (questionState.borrowersCount === 1) {
                disclosureMessage = decideDisclosure();
            }
            else {
                let surveyCompleted: boolean = true;
                questionState.allBorrowerSurveyStatus.forEach(x => {
                    if (x.user_name !== appState.currentUser && x.submission_status !== SurveyStatus.Completed && x.submission_status !== SurveyStatus.DocPending) {
                        surveyCompleted = false;
                    }
                });
                if (surveyCompleted) {
                    disclosureMessage = decideDisclosure();
                }
                else {
                    disclosureMessage = disclosures.DocCompleteSurveyIncomplete;
                }
            }
        }
        if (disclosureMessage) {
            dispatch(setDisclosure(disclosureMessage));
        }
        history("/suggestions");
    }

    return <>
        <div className="main_wrapper">
            <div className="container">
                <div className="content_section">
                    <div className="question_sect">
                        <span className="survey_category">Upload Document</span>
                        {questionState.borrowersCount === 1
                            ? <h1>
                                Thank you for submitting your request for assistance!
                                <br />
                                <br />
                                Your request has been submitted; however, we do need the additional documents listed below to complete the review.  Kindly upload these as soon as possible.  You can return at any time to attach these files. A representative will contact you within 24 hours of receipt.
                                <br />
                                <br />
                            </h1>
                            : <h1>
                                Thank you for submitting your request for assistance!
                                <br />
                                <br />
                                In order for us to complete the review of your assistance request, the co-borrower must complete an income and asset verification. Additionally, the documents listed below must be submitted to support your application.  Kindly upload these as soon as possible.
                                <br />
                                <br />
                            </h1>
                        }
                    </div>
                    <div className="">
                        <div className="custom_accordion">
                            {getUploadedFileList()}
                        </div>
                    </div>
                    <div className="tabs_footer bordernone_mobi">
                        <ul className="tabs_buttons">
                            {
                                isDocumentMissing() ?
                                    <li><a onClick={() => handleBtnClick(true)} className="btn blue-outline-btn text-center">Come Back Later</a></li>
                                    :
                                    <li><a onClick={() => handleBtnClick(false)} className="btn blue-outline-btn text-center">Continue</a></li>
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </>
}