/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Status } from "../../constants/status";
import { SurveyStatus } from "../../constants/surveyStatus";
import { readAppState } from "../../redux/reducers/appReducer";
import { getMessageAsync } from "../../redux/reducers/messageReducer";
import { clearUserResponse, getIntentsAsync, getUploadedFilesAsync, resetUserResponses, setContinueButtonStatus, setDisclosure, updateSurveyStatusAsync } from '../../redux/reducers/questionReducer';
import { readQuestionState } from "../../redux/reducers/questionReducer";
import { Loader } from "../Loader";
import { PopoverComponent } from "./PopoverComponent";
import { Link } from "react-router-dom";
import { disclosures } from "../../constants/disclosureMessages";
import { createUserSubmissionAsync } from "../../services/questionServicers/userResponseService";
import { postApi } from "../../api/base";

const Welcome = () => {
    const dispatch = useAppDispatch();
    const history = useNavigate();
    const servicerName = "Livegage";
    const questionState = useAppSelector(readQuestionState);
    const appState = useAppSelector(readAppState);
    const userName = appState.userData?.first_name + " " + appState.userData?.last_name;
    const status = questionState.status;
    const userResponses = questionState.userResponses;
    const lastModifiedDate = questionState.lastModifiedDate ? getFormattedDate(questionState.lastModifiedDate) : "";
    const isExistingUser = userResponses.length > 0 && !appState.loanData?.is_bankrupt;
    const userCompletedSurvey = false;
    const [showDetail, setShowDetail] = useState(false);
    const user = appState.currentUser;
    const [isLoading, updateLoadingStatus] = useState(true);
console.log("jenkins pipeline")
    function getFormattedDate(date: string) {
        var tempDate = new Date(date);
        var month = tempDate.getMonth() + 1;
        var day = tempDate.getDate();
        var year = tempDate.getFullYear();
        return month + "/" + day + "/" + year;
    }
    console.log("data: ",userResponses.length," : ",appState.loanData )
    const urlForCovid = "https://www.consumerfinance.gov/coronavirus/mortgage-and-housing-assistance/cares-act-mortgage-forbearance-what-you-need-know/";
    const loanType = appState.loanData ? appState.loanData.loan_type : "";
    const msgdetails = { 'userId': user }

    useEffect(() => {
        if (isLoading && appState.userData) {
            Promise.all([dispatch(getIntentsAsync({ loanData: appState.loanData, userData: appState.userData })),
            dispatch(getUploadedFilesAsync({ user_name: appState ? appState.userData!.user_name : "", loan_number: appState.loanData!.loan_number, servicer_id: appState.loanData!.servicer_id })),
            dispatch(getMessageAsync(msgdetails))
            ]).then(data => {
                updateLoadingStatus(!isLoading);
            })
        }        

    }, [appState.userData]);

    const updateSurveyStatus = () => {
        dispatch(updateSurveyStatusAsync({email: appState.CurrentUserEmail, loanDetails: { loan_number: appState.loanData?.loan_number, user_id: appState.currentUser, servicer_id: Number(appState.loanData?.servicer_id) }, userSurveyStatus: SurveyStatus.Initiated, isCurrentUserPrimary: questionState.isCurrentUserPrimary }));
    }

    let existingUserWelcomeText: any = `Your request for assistance has been started. We are ready to help you and we’ve saved your information from the last time you were here. \n\nLet’s get back to it! Or, if you’d like to just start over, you can do that too! \n\nPlease check your notifications for additional updates.`;

    const renderWelcomeContent = () => {

        let newUserWelcomeText: any = `${servicerName} understands the importance of homeownership.  If you are experiencing a financial hardship and having trouble making your monthly mortgage payments, we want to help. \n\nTo find the most personalized options available to you, we need some information about your current home and financial situation. \n\nThis process should only take a few minutes, so let’s get started!`;

        const existingUserGreeting = `As of ${getFormattedDate(new Date().toLocaleDateString())} the status of your assistance request is below.  Please check your notifications for further updates.\n\n`;
        //const newUserGreeting = `As of ${getFormattedDate(new Date().toLocaleDateString())} the status of your assistance request is below.  Please check your notifications for further updates.\n\n`;
        switch (questionState.loanStatus) {
            case SurveyStatus.BankruptcyExit:
                existingUserWelcomeText = disclosures.BankruptcyMessage;
                newUserWelcomeText = disclosures.BankruptcyMessage;
                break;
            case SurveyStatus.Initiated:
                if (questionState.userSurveyStatus === SurveyStatus.FinancialScreenExit) {
                    existingUserWelcomeText = disclosures.FinancialScreenExit;
                }
                else if (questionState.userSurveyStatus === SurveyStatus.PaymentScreenExit) {
                    existingUserWelcomeText = disclosures.PaymentScreenExit;
                }
                else if (questionState.userSurveyStatus === SurveyStatus.NotStarted && questionState.primaryBorrowerStatus === SurveyStatus.Initiated) {
                    newUserWelcomeText = disclosures.PrimarySurveyInitiated;
                }
                break;
            case SurveyStatus.PendingReview:
                if (questionState.userSurveyStatus === SurveyStatus.DocPending) {
                    existingUserWelcomeText = <>{"In order to complete the review of your assistance request, we need some additional information from you.  Kindly submit the additional documents we need to complete your request as soon as possible.  Click"} <span className="clickableSpan"><Link to="/documents">here</Link></span> {"to submit your documents."}</>;
                }
                else if (questionState.userSurveyStatus === SurveyStatus.AssistanceRequested) {
                    existingUserWelcomeText = disclosures.AssistanceRequested;
                }
                else if (questionState.userSurveyStatus === SurveyStatus.FbExtn) {
                    existingUserWelcomeText = disclosures.FbExtensionMessage;
                }
                else if (questionState.userSurveyStatus === SurveyStatus.FbSetup) {
                    existingUserWelcomeText = disclosures.FbSetupMessage;
                }
                else if (!questionState.suggestions && questionState.userSurveyStatus === SurveyStatus.Completed) {
                    existingUserWelcomeText = disclosures.NotQualifiedDisclosure;
                }
                else if (questionState.suggestions && questionState.suggestions.suggestions.workoutOption) {
                    if (questionState.userSurveyStatus === SurveyStatus.Completed) {
                        existingUserWelcomeText = disclosures.PreQualifiedMessage;
                    }
                }

                if (questionState.primaryBorrowerStatus === SurveyStatus.FbExtn) {
                    existingUserWelcomeText = disclosures.FbExtensionMessage;
                    newUserWelcomeText = disclosures.FbExtensionMessage;
                }
                else if (questionState.primaryBorrowerStatus === SurveyStatus.FbSetup) {
                    existingUserWelcomeText = disclosures.FbSetupMessage;
                    newUserWelcomeText = disclosures.FbSetupMessage;
                }
                else if (questionState.primaryBorrowerStatus === SurveyStatus.AssistanceRequested) {
                    existingUserWelcomeText = disclosures.AssistanceRequested;
                    newUserWelcomeText = disclosures.AssistanceRequested;
                }
                else if (questionState.userSurveyStatus === SurveyStatus.NotStarted &&
                    (questionState.primaryBorrowerStatus === SurveyStatus.DocPending || questionState.primaryBorrowerStatus === SurveyStatus.Completed)) {
                    existingUserWelcomeText = disclosures.PreQualifiedMessage;
                    newUserWelcomeText = existingUserGreeting + disclosures.PreQualifiedMessage;
                }
                break;
            case SurveyStatus.BorrowerPending:
                if (questionState.userSurveyStatus === SurveyStatus.Completed) {
                    existingUserWelcomeText = disclosures.AdditionalBorrowerPendingMessage;
                }
                else if (questionState.userSurveyStatus === SurveyStatus.DocPending) {
                    existingUserWelcomeText = <>{"In order to complete the review of your assistance request, we need some additional information from you.  Kindly submit the additional documents we need to complete your request as soon as possible.  Click"} <span className="clickableSpan"><Link to="/documents">here</Link></span> {"to submit your documents."}</>;
                }
                else if (questionState.userSurveyStatus === SurveyStatus.NotStarted) {
                    newUserWelcomeText = disclosures.CoborrWelcomeMessage;
                }
                break;
            case SurveyStatus.ServicerFollowUpRequired:
                if (questionState.userSurveyStatus === SurveyStatus.Initiated) {
                    existingUserWelcomeText = disclosures.WorkoutOptionDeclined;
                }
                break;
            case SurveyStatus.ClosedCustomerCancelledRequest:
            case SurveyStatus.ClosedCustomerDeclined:
            case SurveyStatus.ClosedCustomerDidNotQualify:
            case SurveyStatus.ClosedOther:
            case SurveyStatus.ClosedWorkoutApprovedAndAccepted:
                existingUserWelcomeText = disclosures.RequestClosed;
                newUserWelcomeText = disclosures.RequestClosed;
                break;

        }
console.log("isExistingUser: ",isExistingUser," : ",existingUserGreeting)
        return (<>
            {!isExistingUser
                ? <><p style={{ whiteSpace: 'break-spaces' }}>{newUserWelcomeText}</p><br /></>
                : <><p style={{ whiteSpace: 'break-spaces' }}>{existingUserGreeting}{existingUserWelcomeText}</p><br /></>
            }

            {
                isExistingUser &&
                (questionState.userSurveyStatus === SurveyStatus.Initiated || questionState.userSurveyStatus === SurveyStatus.NotStarted ||
                    questionState.userSurveyStatus === SurveyStatus.FinancialScreenExit || questionState.userSurveyStatus === SurveyStatus.PaymentScreenExit) &&
                questionState.primaryBorrowerStatus !== SurveyStatus.BankruptcyExit &&
                questionState.primaryBorrowerStatus !== SurveyStatus.FbExtn &&
                questionState.primaryBorrowerStatus !== SurveyStatus.FbSetup &&
                questionState.loanStatus !== SurveyStatus.BankruptcyExit &&
                questionState.primaryBorrowerStatus !== SurveyStatus.AssistanceRequested &&

                <ul className="two_option_btn">
                    <li><a className="btn btn-primary text-center" id='continue-button' onClick={() => handleClick(true)}>Continue</a></li>
                    <li><a className="btn blue-outline-btn text-center" onClick={() => { updateSurveyStatus(); startOver() }} id='start-over-button'>Start Over</a></li>
                </ul>
            }
            {
                !isExistingUser && questionState.loanStatus !== SurveyStatus.BankruptcyExit && questionState.loanStatus !== SurveyStatus.PendingReview &&
                questionState.primaryBorrowerStatus !== SurveyStatus.BankruptcyExit &&
                questionState.primaryBorrowerStatus !== SurveyStatus.FbExtn &&
                questionState.primaryBorrowerStatus !== SurveyStatus.FbSetup &&
                questionState.primaryBorrowerStatus !== SurveyStatus.AssistanceRequested &&

                <ul className="two_option_btn" id='hoa-button'>
                    <li><a className="btn btn-primary text-center" id='hoa-start-button' onClick={() => { !appState.loanData?.is_bankrupt && updateSurveyStatus(); handleClick(true) }}>Let's Get Started</a></li>
                </ul>
            }
            <br /><div className="divider1"></div>
        </>);
    }

    const renderForSurveyCompletingUser = () => {
        return (<>
            <p style={{ whiteSpace: 'break-spaces' }}>
                As of {lastModifiedDate} the status of your assistance request is below.  Please check your notifications for further updates.
            </p>
            <p style={{ whiteSpace: 'break-spaces' }}>Your request has been submitted for additional review and a customer service representative will contact you within 24 hours of your request.</p>
            <br />
        </>);
    }

    const startOver = () => {
        dispatch(resetUserResponses());
        dispatch(clearUserResponse({ user_id: appState.currentUser, loan_number: appState.loanData?.loan_number, servicer_id: appState.loanData?.servicer_id }))
        history("/questions");
    }

    const handleClick = async (flag: boolean) => {
        if (flag) {
            updateLoadingStatus(true);
            if ((appState.loanData && appState.loanData?.is_bankrupt)) {
                const data = {
                    loan_number: appState.loanData?.loan_number,
                    user_name: appState.currentUser,
                    servicer_id: appState.loanData?.servicer_id,
                    is_primary_borrower: questionState.isCurrentUserPrimary,
                    submission_status: SurveyStatus.BankruptcyExit,
                    created_date: new Date().toUTCString(),
                    created_by: appState.currentUser,
                    modified_by: appState.currentUser,
                    modified_date: new Date().toUTCString()
                };
                const userSubmissionResponse = await createUserSubmissionAsync(data);
                console.log("userSubmissionResponse: ",userSubmissionResponse)
                if (userSubmissionResponse) {
                    dispatch(updateSurveyStatusAsync({email: appState.CurrentUserEmail, loanDetails: { loan_number: appState.loanData?.loan_number, user_id: appState.currentUser, servicer_id: Number(appState.loanData?.servicer_id) }, userSurveyStatus: SurveyStatus.BankruptcyExit, isCurrentUserPrimary: questionState.isCurrentUserPrimary }));
                    const disclosureMessage = "I see that your account is currently in a Bankruptcy status.  Thank you for inquiring about Homeowner Assistance options.  Your request will be forwarded to your Account Representative who will contact you within the next 24 hours to offer further assistance. \\n\\nIf you would like to speak directly to a Customer Service Representative, please contact us at 1.888.777.6666 or visit the Contact Us link on the main menu for additional options.";
                    dispatch(setContinueButtonStatus(false));
                    dispatch(setDisclosure(disclosureMessage));
                    history("/suggestions");
                    updateLoadingStatus(false);
                    return;
                }
            }
            if ((questionState.showContinueButton || questionState.finalDisclosure) && isExistingUser) {
                if (questionState.isWorkoutExit) {
                    dispatch(setDisclosure(""));
                }
                history("/suggestions");
                return;
            }
        }
        else {
            const disclosureMessage = "Thank you for inquiring about Homeowner's Assistance options. If you would like to explore options in the future, please refer to the the Homeowner's Assistance  page for assistance. \\n\\nIf you would like to speak directly to a Customer Service Representative, please contact us at 1.888.777.6666 or visit the Contact Us link on the main menu for additional options.";
            dispatch(setDisclosure(disclosureMessage));
            history("/suggestions");
            return;
        }

        history("/questions");
    }

    const cards = [
        {
            id: "Reinstatement",
            header: "Reinstatement",
            message: "A Reinstatement is the process of restoring your mortgage by paying the total amount due in one lump sum. This creates a “clean slate” and brings your loan current."
        },
        {
            id: "RepaymentPlan",
            header: "Repayment Plan",
            message: "With a Repayment Plan, you spread out your past due amount over a specified time to bring your mortgage current.  The past due amounts are added to your current monthly payment."
        },
        {
            id: "Forbearance",
            header: "Forbearance",
            message: "Forbearance allows you to pause or reduce your payment for a limited period of time.  These payments will need to be paid in the future."
        },
        {
            id: "LoanModification",
            header: "Loan Modification",
            message: "A Loan Modification is a change to the original terms of your mortgage loan, designed to help you bring your loan current.  Modifications may involve extending the number of years you have to repay the loan, reducing your interest rate, forgiving a portion of your principal balance, or a deferral of past due payments."
        },
        {
            id: "Deferral",
            header: "Deferral",
            message: "Deferral takes past-due payments and moves them to end of your loan term, bringing your loan to a current status."
        },
        {
            id: "TrialPaymentPlan",
            header: "Trial Payment Plan",
            message: "A Trial Payment Plan is a payment plan set up for a minimum of 3 months, during which you make specified payments to demonstrate your ability and willingness to pay a new amount. This amount can be higher than the original monthly payment amount."
        },
        {
            id: "ShortRefinance",
            header: "Short Refinance",
            message: "A Short Refinance is when your servicer or lender agrees to pay off your existing mortgage and replace it with a new loan with a reduced balance, forgiving a portion of outstanding principal balance."
        },
        {
            id: "ShortSale",
            header: "Short Sale",
            message: "A Short Sale allows you to sell your home for less than the balance remaining on your mortgage, with all proceeds going to pay off your loan.  The remaining balance of the loan may be forgiven.  The sale price for the home must be acceptable to the owner of your loan."
        },
        {
            id: "DeedinLieu",
            header: "Deed in Lieu",
            message: "A Deed in Lieu means that you and your lender reach a mutual understanding that you cannot make your loan payments.  You voluntarily and amicably hand your property over to your lender and in exchange, the lender releases you from your obligations under the mortgage.  This option has a less negative impact on your credit than a foreclosure."
        },
        {
            id: "ConsenttoJudgement",
            header: "Consent to a Judgment",
            message: "By giving Consent to a Judgment, you agree to allow a foreclosure action to move forward in an accelerated and unopposed manner.  This option may be available if you are unable to sell your home and do not qualify for a Deed in Lieu."
        },
        {
            id: "Settlement",
            header: "Settlement",
            message: "A Settlement is a mutual agreement between you and your servicer on behalf of the loan owner.  In this  the owner of your loan agrees to accept less than the full amount of your loan balance to completely satisfy your debt. You will retain full ownership of your home."
        },
        {
            id: "PartialClaim",
            header: "Partial Claim",
            message: "If you are eligible for the Home Affordable Modification Program (HAMP), your monthly mortgage payments can be reduced through the use of a Partial Claim.  A partial claim defers the repayment of mortgage principal through a zero-interest junior lien that is not due until your first mortgage is paid off.   This option assists you in bringing your mortgage current."
        }
    ];
    return <>
        {(status === Status.Loading || isLoading) && <Loader />}
        {
            status === Status.Idle && !isLoading &&
            <div className="main_wrapper">
                <div className="container">
                    <div className="content_section">

                        <div className="question_sect">
                            <span className="survey_category">{isExistingUser || userCompletedSurvey ? `Welcome  ${userName}!` : `Welcome ${userName}!`}</span>
                        </div>
                        <div className="ques_options_sect">

                            {userCompletedSurvey ? renderForSurveyCompletingUser() : renderWelcomeContent()}

                            <p>If you are having trouble making payments due to the ongoing coronavirus pandemic, here is some information on <b>forbearance</b>, and a <b>loan modification</b> which may be options for you:</p>
                            <a href={urlForCovid} target="_blank" >Mortgage Forbearance: What You Need to Know</a>


                            <div className="divider1"></div>
                            <p>There are other options that may be available to you if your hardship is not related to the coronavirus pandemic.
                                <a className="test-dummy" onClick={() => setShowDetail(!showDetail)}> Click on the links below for more information </a><i style={{ cursor: "pointer", fontSize: 20 }} className={`primary-icons fas fa-arrow-circle-${showDetail ? `up` : `down`}`} onClick={() => setShowDetail(!showDetail)} /></p>

                            {showDetail && <div className="row">
                                {cards.map(c => {
                                    return <div key={c.id} className="col-md-4"><PopoverComponent id={c.id} header={c.header} message={c.message} /></div>
                                })
                                }
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        }
    </>
}
export default memo(Welcome);
