import { lazy, Suspense, useEffect, useRef } from 'react';
import Cookies from 'js-cookie';
import { BrowserRouter as Router, Route, Routes,Navigate } from 'react-router-dom';
//import './App.scss';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { readAppState, setEmail, setUserDetailFetchedFalse } from './redux/reducers/appReducer';
import Footer from './components/HomeComponent/Footer';
import NavMenu from './components/NavMenuComponent/NavMenu';
//import QuestionContainer from './components/QuestionComponent/QuestionContainer';
import Welcome from './components/HomeComponent/Welcome';
//import SuggestionComponent from './components/SuggestionComponent/SuggestionComponent';
import Login from './components/LoginComponent/Login';
import { themeContext } from './constants/theme';
import { ProtectedRoute } from './components/ProtectedRoute';
import { UploadedFiles } from './components/FileUploadComponent/Files';
import { updateTheme } from './helpers/theme';
import { Loader } from './components/Loader';
import SendMessage from './components/MessageCenter/SendMessage';
import MessageCenter from './components/MessageCenter/MessageCenter';
import Registration from './components/RegistrationComponent/Registration';
import ConfirmRegistration from './components/RegistrationComponent/ConfirmRegistration';
import ForgotPassword from './components/LoginComponent/ForgotPassword';
import ForgotPasswordVerification from './components/LoginComponent/ForgotPasswordVerification';
import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
import LBPWelcome from './components/HomeComponent/LBPWelcome';
import axios from "axios";
Amplify.configure(awsExports);
console.log("link: ",process.env.REACT_APP_API_URL)
//import * as globalInterceptor from "./helpers/globalInterceptor";

// import {setupInterceptorsTo} from "./helpers/interceptor";
// import axios from "axios";
// setupInterceptorsTo(axios)
const QuestionContainer = lazy(() => import('./components/QuestionComponent/QuestionContainer'));
const SuggestionComponent = lazy(() => import('./components/SuggestionComponent/SuggestionComponent'));


const App = () => {
  const appState = useAppSelector(readAppState);
  let token = useRef(undefined as any);
  let theme = useRef(undefined as any);
  const dispatch = useAppDispatch();
  useEffect(() => {
    token.current = Cookies.get('token');
    theme.current = Cookies.get('theme');
    if (token.current) {
      sessionStorage.setItem('jwtToken', token.current);
    }
    else {
      token.current = sessionStorage.getItem('jwtToken');
      if (sessionStorage.getItem('theme')) {
        theme.current = JSON.parse(sessionStorage.getItem('theme') || "");
      } else {
        theme.current = appState.theme;
      }
    }

    if (theme.current) {
      updateTheme(theme.current);
    }

    Cookies.remove('token');
  }, [appState.theme]);

  // const getPath = (location) => {
  //   const urlParams = new URLSearchParams(location.search);
  //   const lbpToken = urlParams.get('lbptoken');
  //   if (lbpToken) {
  //     sessionStorage.setItem('lbpToken', lbpToken);
  //   }

  //   return (<> {
  //     appState.isLoggedIn || token.current || lbpToken ? (lbpToken ? <LBPWelcome lbpToken={lbpToken}/> : <Welcome />) :  <Navigate to="/login" />
  //   }</>);
  // }

  const getPath = () => {
    console.log('inside get path....',)
    const query = window.location.search;
    const urlParams = new URLSearchParams(query);
    const lbpToken = urlParams.get('lbptoken');
    const accessToken = urlParams.get('accesstoken');
    const email = urlParams.get('email');
    dispatch(setEmail(email));
   
    console.log('inside get path....',urlParams,lbpToken,email)
    if (lbpToken && accessToken ) {
         sessionStorage.setItem('accessToken', accessToken);
          sessionStorage.setItem('lbpToken', lbpToken);
          dispatch(setUserDetailFetchedFalse(false))
        }
    
        return (<> {
          appState.isLoggedIn || token.current || lbpToken ? (lbpToken ? <LBPWelcome lbpToken={lbpToken}/> : <Welcome />) :  <Navigate to="/login" />
        }</>);
  }

  
  return (
    <Router>
    <themeContext.Provider value={appState.theme}>
      <Suspense fallback={<Loader />} >
        <Routes>
          <Route path="/login" element={<Login/>} />
		      <Route path="/register" element={<Registration />}/>
          <Route path="/confirmRegistration" element={<ConfirmRegistration />}/>
          <Route path="/forgotpassword" element={<ForgotPassword />}/>
          <Route path="/forgotpasswordverification" element={<ForgotPasswordVerification />} />
		      <Route path='/' element={<ProtectedRoute />}>
          <Route path="/questions" element={<QuestionContainer/>} />
          <Route path="/suggestions" element={<SuggestionComponent/>} />
          <Route path="/documents"  element={<UploadedFiles />}/>
          {/* <Route path="/add-documents" element={ <UploadedFiles />}/> */}
          <Route path="/compose-message" element={ <SendMessage />}/>
          <Route path="/message" element={<MessageCenter/>} />
          <Route path="/" element={getPath()} />
		      </Route>
        </Routes>
      </Suspense>
    </themeContext.Provider>
    </Router >
  );
}

export default App;
