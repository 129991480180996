import { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { readAppState } from "../../redux/reducers/appReducer";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { composeMessageAsync, replyToMessageAsync, uploadDocumentsMessageCenterAsync } from "../../redux/reducers/messageReducer";
import DatePicker from "react-date-picker";
import { useLocation } from "react-router-dom";
import { Loader } from "../Loader";


const SendMessage = () => {
    const appState = useAppSelector(readAppState);
    const dispatch = useAppDispatch();
    const history = useNavigate();
    const user = appState.currentUser
    const [date, setDate] = useState(new Date());
    const [reason, setReason] = useState('');
    const [message, setMessage] = useState('');
    const [category, setMsgCategory] = useState('My Account');
    const [messageId, setMsgId] = useState(0);
    const [adminId, setadminId] = useState('');
    const [isReplyFlag, setReplyFlag] = useState(false);
    const [uploadFile, setAttachmentFile] = useState<any[]>([]);
    const [loading, setLoader] = useState(false);
    const location = useLocation();
    const [errors, setError] = useState(false);



    const msgTypes = [

        {
            "label": "My Account",
            "value": "My Account",

        },
        {
            "label": "My Assistance Options",
            "value": "My Assistance Options"
        },
        {
            "label": "Technical Issue",
            "value": "Technical Issue"
        },

        {
            "label": "Document  Issues",
            "value": "Document  Issues"
        },
        {
            "label": "Status Update",
            "value": "Status Update"
        },
        {
            "label": "General Questions",
            "value": "General Questions"
        }
    ];

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (location.state && location.state.isReplyFlag) {
                setMsgCategory(location.state.messageType);
                setReason(location.state.messageReason)
                setMsgId(location.state.messageId);
                setReplyFlag(location.state.isReplyFlag);
                setadminId(location.state.adminId);
            }
        }
        return () => {
            isMounted = false;
        };
    }, []);

    const renderOptions = () => {

        return (
            msgTypes.map((msg) => {
                return <option key={msg.value} value={msg.value}>{msg.label}</option>;
            })
        );
    }

    const showLoader = () => {
        if (reason.trim().length != 0) {
            setLoader(true);
        }

    }
    const hideLoader = () => {
        setLoader(false);

    }

    const handleFileClick = (fileAccessUrl: any) => {
        window.open(fileAccessUrl);

    }

    const onSubmit = () => {
        validateForm();
        if (reason.trim().length != 0) {
            if (isReplyFlag) {
                if (uploadFile.length > 0) {
                    dispatch(uploadDocumentsMessageCenterAsync({ uploadFile, loanId: appState.loanData?.loan_number, servicer: appState.loanData?.servicer_id })).then(response => {
                        if (response.payload) {

                            let files = response.payload.files as any;
                            const replydetails = {
                                message_type: category,
                                send_date: date,
                                message_reason: reason,
                                message_text: message,
                                user_name: user,
                                'UploadFile': files,
                                message_id: messageId,
                                admin_id: adminId,
                                is_user: true,
                                is_admin: false,
                                read_flag: false
                            }

                            dispatch(replyToMessageAsync(replydetails)).then(res => {
                                history("/message", {state:{ sendFlag: true }});
                                hideLoader();
                            })
                        }

                    });
                }
                else {
                    const replydetails = {
                        message_type: category,
                        send_date: date,
                        message_reason: reason,
                        message_text: message,
                        user_name: user,
                        'UploadFile': [],
                        message_id: messageId,
                        admin_id: adminId,
                        is_user: true,
                        is_admin: false,
                        read_flag: false
                    }


                    dispatch(replyToMessageAsync(replydetails)).then(res => {
                        history("/message", {state:{ sendFlag: true }});
                        hideLoader();
                    })

                }
            }
            else {
                if (uploadFile.length > 0) {
                    dispatch(uploadDocumentsMessageCenterAsync({ uploadFile, loanId: appState.loanData?.loan_number, servicer: appState.loanData?.servicer_id })).then(response => {
                        if (response.payload) {
                            let files = response.payload.files;
                            const msgdetails = {
                                message_type: category,
                                send_date: date,
                                message_reason: reason,
                                message_text: message,
                                user_name: user,
                                servicer_id: appState.loanData?.servicer_id,
                                loan_id: appState.loanData?.loan_number,
                                'UploadFile': files,
                                admin_id: "admin 1"
                            }

                            dispatch(composeMessageAsync(msgdetails)).then(res => {
                                history("/message", {state:{ sendFlag: true }})
                                hideLoader();

                            })
                        }
                    });

                }
                else {

                    const msgdetails = {
                        message_type: category,
                        send_date: date,
                        message_reason: reason,
                        message_text: message,
                        user_name: user,
                        servicer_id: appState.loanData?.servicer_id,
                        loan_id: appState.loanData?.loan_number,
                        'UploadFile': [],
                        admin_id: "admin 1"

                    }

                    dispatch(composeMessageAsync(msgdetails)).then(res => {
                        history("/message", {state:{ sendFlag: true }})
                        hideLoader();

                    })

                }
            }
        }
    }

    const validateForm = () => {
        if (reason.trim() === "") {
            setError(true);
        }
    }


    const handleClick = () => {
        history("/message");

    }

    const handleReasonChange = (e) => {
        setReason(e.target.value);
        if (e.target.value.length != 0) {
            setError(false);
        }


    }

    const attachmentFile = (e) => {
        let fileUploaded: any = undefined;
        let array: any = [];
        if (e.target.files) {
            for (var fileObject in e.target.files) {
                if (typeof e.target.files[fileObject] === 'object') {
                    const file = (e.target.files[fileObject]);
                    const fileBlob = new Blob([file], { type: file.type });
                    fileUploaded = { accessUrl: URL.createObjectURL(fileBlob), fileName: file.name, fileType: file.type };
                    array.push(fileUploaded);
                }

            }
            setAttachmentFile([...array, ...uploadFile]);
        }
        e.target.value = '';
    }

    const removeAttachment = (fileName) => {
        let file = uploadFile.filter(file => file.fileName !== fileName)
        setAttachmentFile(file);
    }

    return (

        <div className="main_wrapper message_page">
            {loading ? (<Loader />) : null}
            <div className="container">

                <h2 className="page_title d-none d-md-block d-lg-block d-xl-block">Send a Secure Message</h2>

                <div className="content_section">

                    <h3 className="page_title2 mb20 d-lg-none d-md-none">Send a Secure Message</h3>

                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-floating group_field">
                                <select disabled={isReplyFlag} className="form-select" id="floatingSelect" value={category} aria-label="Floating label select example" onChange={e => setMsgCategory(e.target.value)} >
                                    {renderOptions()}
                                </select>
                                <label htmlFor="floatingSelect">What can we help you with?</label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-floating ct_form_field">
                                <DatePicker calendarIcon={null} clearIcon={null} disabled={true} className="form-control" value={date} format={'MM-dd-y'} onChange={date => setDate(date)} />
                                <label htmlFor="floatingInput">Date</label>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-floating ct_form_field">
                                <input required type="text" name="reason1" className="form-control" id="floatingInput" placeholder="Reason for message" value={reason} onChange={handleReasonChange} disabled={isReplyFlag} />

                                <label htmlFor="floatingInput">Reason For Message</label>
                                {errors &&
                                    (
                                        <p style={{ color: "red" }}>Please fill  reason for message </p>
                                    )}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-floating ct_form_field">
                                <textarea style={{ minHeight: '120px', display: "inline", padding: "0px", marginTop: "2rem" }} className="form-control" id="floatingTextarea" rows={2} placeholder="Your message" onChange={e => setMessage(e.target.value)}></textarea>
                                <label htmlFor="floatingTextarea">Your Message</label>
                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div >
                            <input id="file-upload" type="file" name="files" accept=".pdf, .jpg, .jpeg, .png, .doc, docx" style={{ display: "none" }} multiple onChange={e => attachmentFile(e)} />
                            <label htmlFor="file-upload" className="primary-icons" ><span style={{ width: '30px', textAlign: 'center', borderRadius: '50PX', fontSize: '22px', boxShadow: '0px 3px 9px rgba(0, 0, 0, .2),', alignItems: 'center' }} className="far fa-paperclip d-inline-block primary-icons"></span>Attach File</label>
                        </div>
                        <div className="col-md-12">
                            {uploadFile && uploadFile.length > 0 &&
                                <>
                                    {uploadFile.map((data, i) => {
                                        return (<li style={{ listStyle: 'none' }} key={`uploadfile${i}`}>

                                            <a style={{ textDecorationLine: 'none', wordWrap: 'break-word', whiteSpace: 'pre-wrap' }} className="added_doc_name" onClick={() => { handleFileClick(data.accessUrl) }}>{data.fileName}</a>
                                            <a className="add_doc_delete" onClick={() => { removeAttachment(data.fileName) }}><span className="fas fa-trash-alt"></span></a>
                                        </li>

                                        )
                                    })}
                                </>}
                        </div>
                    </div>
                    <div className="tabs_footer bordernone_mobi">

                        <ul className="tabs_buttons tabs_column_btn">
                            <li ><a href="#" className="btn btn-primary text-center" onClick={() => handleClick()}>Cancel</a></li>
                            <li onClick={() => { onSubmit(); showLoader() }}><a href="#" className="btn blue-outline-btn text-center" >Send</a></li>
                        </ul>

                    </div>


                </div>


            </div>
        </div>
    )
}

export default memo(SendMessage);