import { memo, useState } from "react";
import {Auth} from "aws-amplify";
import { useNavigate } from "react-router";

const ForgotPasswordVerification = ()=>{
    const [email,setEmail]=useState("");
    const [verificationcode,setVerificationCode]=useState("");
    const [newpassword,setNewPassword]=useState("");
    const history = useNavigate();


   const  passwordVerificationHandler = async event => {
        event.preventDefault();
    
    
        // AWS Cognito integration here
    
        try {
          await Auth.forgotPasswordSubmit(
            email,
            verificationcode,
            newpassword
          );
        } catch (error) {
          console.log(error);
        }
      };

    return(
        <section className="section auth">
        <div className="container">
          <h1>Set new password</h1>
          <p>
            Please enter the verification code sent to your email address below,
            your email address and a new password.
          </p>
         
          <form onSubmit={passwordVerificationHandler}>
            <div className="field">
              <p className="control">
                <input
                  type="text"
                  className="input"
                  id="verificationcode"
                  aria-describedby="verificationCodeHelp"
                  placeholder="Enter verification code"
                  value={verificationcode}
                  onChange={(e)=>setVerificationCode(e.target.value)}
                />
              </p>
            </div>
            <div className="field">
              <p className="control has-icons-left">
                <input 
                  className="input" 
                  type="text"
                  id="email"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e)=>{setEmail(e.target.value)}}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-envelope"></i>
                </span>
              </p>
            </div>
            <div className="field">
              <p className="control has-icons-left">
                <input
                  type="password"
                  className="input"
                  id="newpassword"
                  placeholder="New password"
                  value={newpassword}
                  onChange={(e)=>setNewPassword(e.target.value)}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-lock"></i>
                </span>
              </p>
            </div>
            <div className="field">
              <p className="control">
                <button className="button is-success">
                  Submit
                </button>
              </p>
            </div>
          </form>
        </div>
      </section>

    )

}

export default memo(ForgotPasswordVerification)