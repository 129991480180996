/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://o5wmmqmklbblfl74oc3hynp2ou.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-zxnnp5arbfganjd7z2x5bkj6fi",
    "aws_cognito_identity_pool_id": "us-east-1:dd429c5d-139e-4400-9c5e-20c65091f76a",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_ctaozxy8b",
    "aws_user_pools_web_client_id": "4dlai10t3b57ek69sd5l5ml396",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "hoa-s3-bucket",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
