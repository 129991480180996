export const FinancialTabs = {
    Income: "Income",
    Expenses : "Expenses",
    Assets : "Assets",
    Summary : "Summary"
}

export const FinancialSections = {
    Income: "income",
    Expenses : "expenses",
    Assets : "assets",
    Summary : "summary",
    Employment : "employment"
}

export const ServicerProvidedExpenses = [
    "First Mortgage",
    "Home Owner's Insurance",
    "Property Taxes",
    "HOA/Condo Fees/Property Maintenance"
];
export const FinancialScreen ="Financial Information";

export const FinicityScriptUrl = "https://connect2.finicity.com/assets/sdk/finicity-connect.min.js";