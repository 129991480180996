import React from "react"

export interface Theme {
    logo: string,
    primary_color: string,
    primary_color_hover: string,
    secondary_color: string,
    body_color: string,
    font_family: string,
    bottom_icons_color: string,
    btn_border_color: string,
    btn_background_color: string,
    btn_bg_gradient_value1: string,
    btn_bg_gradient_value2: string,
    btn_bg_gradient_value3: string,
    favicon: string;
    bg_img: string;
}

export const liveGageTheme: Theme = {
    logo: "assets/img/logo.svg",
    primary_color: "#00B6DD",
    primary_color_hover: "#00B6DD",
    secondary_color: "#2275BC",
    body_color: "#F8F9FA",
    font_family: "Segoe UI",
    bottom_icons_color: "#666666",
    btn_border_color: "#00B6DD",
    btn_background_color: "#333",
    btn_bg_gradient_value1: "#00b6dd",
    btn_bg_gradient_value2: "#00b6dd",
    btn_bg_gradient_value3: "#00afb1",
    favicon: "assets/img/HOMEAID-Favicon.png",
    bg_img: "assets/img/bg_logo.svg"
}

export const themeContext = React.createContext({});